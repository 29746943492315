import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileService, { IFile, IFileInput, ISaveFileInput } from '../services/fileService';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../constants/dates';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface IFilesState {
  files : IFile[];
  contractFiles : IFile[];
  downloadedFile : IFile;
}

const initialState = {
  files : [],
  contractFiles : [],
  downloadedFile : {
    id : 0,
    category : '',
    content : '',
    contractID : 0,
    created : '',
    description : '',
    length : 0,
    name : ''
  }
} as IFilesState;

export const saveUserFile = createAsyncThunk('files/addFile', async (props : ISaveFileInput, thunkAPI) => {
  const { event, category, contractID, description } = props;
  const today = moment().format(DATE_FORMAT_YYYYMMDD);
  const { name, size } = event.target.files[0];
  const reader = new FileReader();

  reader.onload = () : void => {
    const fileBas64 = (reader?.result as string) || '';
    const dto = {
      contractID : contractID,
      name,
      length : size,
      category,
      description,
      content : fileBas64,
      created : today
    } as IFileInput;

    FileService.saveFile(dto).then(() => {
      thunkAPI.dispatch(getAllContractFiles({ contractID }));
      successToastNotify(String(i18n.t('toast:addFile')));
    });
  };
  reader.onerror = (error) : void => {
    alert(`Błąd przy wysyłaniu pliku\n${error.toString()}`);
  };
  reader.readAsDataURL(event.target.files[0]);
});

export const getAllFiles = createAsyncThunk('files/getAllFiles', async () => {
  return FileService.fetchAllFiles();
});

export const getAllContractFiles = createAsyncThunk(
  'files/getAllContractFiles',
  async (variables : { contractID : number }, thunkAPI) => {
    const result = await FileService.fetchAllFiles();
    return { files : result.files, contractID : variables.contractID };
  }
);

export const getFileContentByIDSavedByUser = createAsyncThunk(
  'files/getAllFiles',
  async (fileID : number) => {
    const result = await FileService.fetchFileContentByFileID(fileID);

    if (result && result.file) {
      FileService.downloadFileSavedByUser({
        content : result.file.content,
        filename : result.file.name
      });
    }
  }
);
export const removeFileByID = createAsyncThunk('files/removeFileByID', async (fileID : number) => {
  return FileService.deleteFileByID(fileID);
});

export const importPlaces = createAsyncThunk('files/importPlaces', async ({ investmentID, content, isTypeService } : {investmentID : number; content : string; isTypeService : boolean}) => {
  return FileService.importPlaces(investmentID, content, isTypeService);
});

const filesSlice = createSlice({
  name : 'files',
  initialState,
  reducers : {
    clearDownloadFile (state, _ : PayloadAction<void>) : void {
      state.downloadedFile = initialState.downloadedFile;
    }
  },
  extraReducers : {
    [getAllFiles.fulfilled.toString()] : (state, action : PayloadAction<any>) : void => {
      if (action.payload && action.payload.files) {
        state.files = action.payload.files;
      }
    },
    [getAllContractFiles.fulfilled.toString()] : (state, action : PayloadAction<any>) : void => {
      const files = action.payload.files as IFile[];
      const contractID = action.payload.contractID as number;
      state.contractFiles = files.filter(
        (file : IFile) => file.category === 'contractFile' && file.contractID === contractID
      );
      state.files = action.payload.files;
    },
    [removeFileByID.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:removeFile')));
    }
  }
});

export const { clearDownloadFile } = filesSlice.actions;
export default filesSlice.reducer;
