import * as React from 'react';
import { Button } from 'react-bootstrap';
import InputDate from '../commons/Inputs/InputDate';
import moment from 'moment';
import 'moment/locale/pl';
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import InputCheckbox from '../commons/Inputs/InputCheckbox';
import { downloadXlsxReport, fetchDSPCustomerSourceContractReport } from '../../ducks/reports';
import { fetchTraderInitials } from '../../ducks/adminIT';
import { useTranslation } from 'react-i18next';

const CustomerSourceContractReport : React.FC = () => {
    const { t } = useTranslation();
    const investmentList = useSelector((state : RootState) => state.globalInvestment.investmentsList);
    const traderInitialsList = useSelector((state : RootState) => state.adminIT.tradersInitials);
    const isLoading = useSelector((state : RootState) => state.combinedReports.DSPCustomerSourceContractReport.isLoading);
    const dispatch : AppDispatch = useDispatch();

    const [fromDate, setFromDate] = React.useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [toDate, setToDate] = React.useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [investmentIDs, setInvestmentIDs] = React.useState<number[]>([]);
    const [traderInitials, setTraderInitials] = React.useState<string[]>([]);


    const updateFromDate = React.useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setFromDate(formatedDate);
    }, []);

    const updateToDate = React.useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setToDate(formatedDate);
    }, []);

    const updateSelectedInvestments = React.useCallback((id : number) => {
        const investmentIsSelected = investmentIDs.includes(id);
        if (investmentIsSelected) {
           return setInvestmentIDs((prevState : number[]) => prevState.filter(inv => inv !== id));
        }
        setInvestmentIDs((prevState : number[]) => [...prevState, id]);
    }, [investmentIDs]);

    const updateTraderInitials = React.useCallback((traderInitial : string) => {
        const traderIsSelected = traderInitials.includes(traderInitial);
        if (traderIsSelected) {
           return setTraderInitials((prevState : string[]) => prevState.filter(inv => inv !== traderInitial));
        }
        setTraderInitials((prevState : string[]) => [...prevState, traderInitial]);
    }, [traderInitials]);

    const generateCustomerSourceContractReport = React.useCallback(() => {
        // TODO Add traderInitials
        dispatch(fetchDSPCustomerSourceContractReport({ investmentIDs, fromDate, toDate }))
        .then((response : any) => {
            if (response.payload?.DSPCustomerSourceContractReport) {
                return dispatch(downloadXlsxReport({ content : response.payload.DSPCustomerSourceContractReport, filename : 'Zainteresowani-źródło-informacji-zawarte-umowy.xlsx' }));
            }
            alert('Błąd podczas pobierania raportu');
        });
    }, [investmentIDs, toDate, fromDate, dispatch ]);

    React.useEffect(() => {
        dispatch(fetchTraderInitials());
    }, [dispatch]);


    return (
        <div className="container">
            <div>
                <Button disabled={!investmentIDs.length || isLoading} onClick={generateCustomerSourceContractReport}>
                    {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
                </Button>
            </div>
            <p>{t('commons:actions.select-dates')}</p>
            <div className="inputs-wrapper">
                <InputDate id="1" onDayChange={updateFromDate} customWidth={300} text={t('commons:labels.from')} />
                <InputDate id="2" onDayChange={updateToDate} customWidth={300} text={t('commons:labels.to')} />
            </div>
            <div className="column">
                <p>{t('commons:actions.select-investment')}</p>
                <div className="checkbox-wrapper">
                    {investmentList?.map(investment => (
                        <InputCheckbox
                            id={investment.name}
                            label={investment.name}
                            customWidth={150}
                            key={investment.id}
                            onClick={() : void => updateSelectedInvestments(investment.id)} />
                    ))}
                </div>
                <p className="mt-4">{t('commons:actions.select-trader')}</p>
                <div className="checkbox-wrapper">
                    {traderInitialsList?.map(trader => (
                        <InputCheckbox
                            id={trader?.username}
                            label={trader?.username}
                            customWidth={150}
                            key={trader?.username}
                            onClick={() : void => updateTraderInitials(trader?.username)} />
                        ))}
                </div>
            </div>
    </div>
);
};

export default CustomerSourceContractReport;
