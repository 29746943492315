import { IUtilityRoomInput } from '../graphql/utilityRooms';
import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export const getAllUtilityRooms = () : Promise<any> => {
  const FETCH_ALL_UTILITY_ROOMS_QUERY = gql`
    query {
      utilityRooms {
        id
        isTypeService
        number
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          name
          buildingID
          isTypeService
        }
        placeID
        place {
          placeCode
          isTypeService
        }
      }
    }
  `;

  return GraphqlService.send(FETCH_ALL_UTILITY_ROOMS_QUERY, {});
};

export const getRoomsByInvestmentName = (investmentName : string) : Promise<any> => {

  const FETCH_UTILITY_ROOMS_BY_INVESTMENTS_NAMES_QUERY = gql`
    query($investmentsNames: [String!]!) {
      utilityRoomsByInvestmentsNamesThroughGarage(investmentsNames: $investmentsNames) {
        id
        number
        isTypeService
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          name
          buildingID
          isTypeService
        }
        placeID
        place {
          placeCode
          id
          isTypeService
          activeContracts {
            id
          }
        }
        contractID
      }
    }
  `;
  return GraphqlService.send(FETCH_UTILITY_ROOMS_BY_INVESTMENTS_NAMES_QUERY, { investmentsNames : [investmentName] });
};


export const getRoomsByInvestmentStageId = (stageID : number) : Promise<any> => {
  const FETCH_UTILITY_ROOMS_BY_INVESTMENTS_STAGE_ID_QUERY = gql`
    query($stagesIDs: [Float!]!) {
      utilityRoomsByStages(stagesIDs: $stagesIDs) {
        id
        number
        isTypeService
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          isTypeService
          name
          buildingID
        }
        placeID
        place {
          placeCode
          isTypeService
          id
          activeContracts {
            id
          }
        }
        contractID
      }
    }
  `;
  return GraphqlService.send(FETCH_UTILITY_ROOMS_BY_INVESTMENTS_STAGE_ID_QUERY, { stagesIDs : [stageID] });
};

export const getUtilityRoomsLinkedToPlace = (placeID : number) : any => {
  const FETCH_UTILITY_ROOMS_LINKED_TO_PLACE_QUERY = gql`
    query($stagesIDs: [Float!]!) {
      utilityRoomsByStages(stagesIDs: $stagesIDs) {
        id
        number
        isTypeService
        designedInnerUtilRoomArea
        asBuildUsableUtilRoomArea
        asBuildInnerUtilRoomArea
        comment
        building {
          id
          name
          isTypeService
        }
        garageID
        garage {
          id
          isTypeService
          name
          buildingID
        }
        placeID
        contractID
      }
    }
  `;
  return GraphqlService.send(FETCH_UTILITY_ROOMS_LINKED_TO_PLACE_QUERY, {});
};

export const addNewRoom = ({ utilityRoom } : { utilityRoom : IUtilityRoomInput }) : Promise<any> => {
  const ADD_UTILITY_ROOM_QUERY = gql`
  mutation aur($utilityRoom : UtilityRoomInput!) {
    saveUtilityRoom(utilityRoom : $utilityRoom) {
      id
    }
  }
`;
  return GraphqlService.send(ADD_UTILITY_ROOM_QUERY, { utilityRoom });
};

export const updateRoom = ({ utilityRoom, utilityRoomID } : { utilityRoom : IUtilityRoomInput; utilityRoomID : number }) : Promise<any> => {

  const UPDATE_UTILITY_ROOM_QUERY = gql`
    mutation sp($utilityRoom : UtilityRoomInput!, $utilityRoomID:Float!) {
      updateUtilityRoom(utilityRoom : $utilityRoom, utilityRoomID:$utilityRoomID) {
        id
      }
    }
  `;
  return GraphqlService.send(UPDATE_UTILITY_ROOM_QUERY, { utilityRoom, utilityRoomID });
};

export const removeRoom = (utilityRoomID : number) : Promise<any> => {
  const DELETE_UTILITY_ROOM_QUERY = gql`
    mutation deleteUtilityRoom($utilityRoomID : Float!) {
      deleteUtilityRoom(utilityRoomID : $utilityRoomID)
    }
  `;
  return GraphqlService.send(DELETE_UTILITY_ROOM_QUERY, { utilityRoomID });
};


export default {
  getAllUtilityRooms,
  getRoomsByInvestmentName,
  getRoomsByInvestmentStageId,
  getUtilityRoomsLinkedToPlace,
  addNewRoom,
  removeRoom,
  updateRoom
};
