import { gql } from 'graphql-request';
import { IDMAReports } from '../ducks/reports';
import GraphqlService from './graphqlService';


export interface IDKOSerialCorrespondenceReport {
  DKOSerialCorrespondenceReport : string;
};

export interface IDNIForNotarialOfficeReport {
  DNIForNotarialOfficeReport : string;
};

export interface IDownloadFileProps {
  filename : string;
  content : string;
}

export interface IDSPCustomerResignationReport {
  tradersInitials : string[];
  resignationReason : string[];
  investmentIDs : number[];
  toDate : string;
  fromDate : string;
}

export interface IDSPCustomersByBrokersContractsReport {
  investmentIDs : number[];
  toDate : string;
  fromDate : string;
  brokerNames : string[];
}

export interface IDSPPlacesListReport {
  investmentsIDs : number[];
  stagesIDs : number[];
};

export interface IDSPSummaryReport {
  stagesIDs : number[];
  investmentsIDs : number[];
  endDate : string;
  startDate : string;
}

export interface IDSPDailyReport {
  endDate : string;
  startDate : string;
}

export interface IDSPCustomerConcludedContractsReport {
  tradersInitials : string[];
  toDate : string;
  fromDate : string;
  investmentIDs : number[];
}

export interface IDKSBusinessPlacesReport {
  valorization : boolean;
  emailInvoice : boolean;
  customerType : string;
}

const fetchDARArrangementReport = (contractID : number) : Promise<any> => {
  const FETCH_REPORT = gql`
    query($contractID : Float!) {
      DARArrangementReport(contractID : $contractID)
    }
  `;

  return GraphqlService.send(FETCH_REPORT, { contractID });
};

const fetchDKOExecutionStatusReport = (placeIDs : number[]) : Promise<any> => {
  const FETCH_REPORT = gql`
    query($placeIDs : [Float!]!) {
      DKOExecutionStatusReport(placeIDs : $placeIDs)
    }
  `;

  return GraphqlService.send(FETCH_REPORT, { placeIDs });
};

const fetchDKOSerialCorrespondenceReport = ( placeIDs : number[] ) : Promise<any> => {
  const FETCH_DKO_SERIAL_CORRESPONDENCE_REPORT = gql`
    query dkoscr($placeIDs: [Float!]!) {
      DKOSerialCorrespondenceReport(placeIDs: $placeIDs)
    }
  `;

  return GraphqlService.send(FETCH_DKO_SERIAL_CORRESPONDENCE_REPORT, { placeIDs });
};

const fetchDNIForNotarialOfficeReport = ( placeIDs : number[] ) : Promise<any> => {
  const FETCH_DNI_FOR_NOTARIAL_OFFICE_REPORT = gql`
    query dnifnor($placeIDs: [Float!]!) {
      DNIForNotarialOfficeReport(placeIDs: $placeIDs)
    }
  `;

  return GraphqlService.send(FETCH_DNI_FOR_NOTARIAL_OFFICE_REPORT, { placeIDs });
};

const fetchDNIAdministrationReport = (placeIDs : number[]) : Promise<any> => {
  const FETCH_REPORT = gql`
    query($placeIDs: [Float!]!) {
      DNIForAdministrationReport(placeIDs: $placeIDs)
    }
  `;

  return GraphqlService.send(FETCH_REPORT, { placeIDs });
};

const fetchDSPCancelledContractsReport = () : Promise<any> => {
  const FETCH_REPORT = gql`
    query {
      DSPCancelledContractsReport
    }
  `;
  return GraphqlService.send(FETCH_REPORT, {});
};

const fetchDSPCustomerSourceContractReport = ({ investmentIDs, toDate, fromDate } : { investmentIDs : number[]; toDate : string; fromDate : string }) : Promise<any> => {
  const FETCH_REPORT = gql`
    query($investmentIDs : [Float!]!, $toDate: String!, $fromDate: String!) {
      DSPCustomerSourceContractReport(investmentIDs:$investmentIDs, toDate:$toDate, fromDate:$fromDate)
    }
  `;
  return GraphqlService.send(FETCH_REPORT, { investmentIDs, toDate, fromDate });
};

const fetchDSPCustomerByTradersReport = ({ traderInitials, toDate, fromDate } : { traderInitials : string[]; toDate : string; fromDate : string }) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $toDate: String!
      $fromDate: String!
      $traderInitials: [String!]!
    ) {
      DSPCustomerByTradersReport(
        toDate: $toDate
        fromDate: $fromDate
        tradersInitials: $traderInitials
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, { traderInitials, toDate, fromDate });
};

const fetchDNIPickupScheduleReport = ( placeIDs : number[] ) : Promise<any> => {
  const FETCH_REPORT = gql`
  query($placeIDs : [Float!]!) {
    DNIPickupScheduleReport(placeIDs : $placeIDs)
  }
  `;
  return GraphqlService.send(FETCH_REPORT, { placeIDs });
};

const fetchDNIShareTableReport = ( placeIDs : number[] ) : Promise<any> => {
  const FETCH_REPORT = gql`
  query($placeIDs : [Float!]!) {
    DNIShareTableReport(placeIDs : $placeIDs)
  }
  `;
  return GraphqlService.send(FETCH_REPORT, { placeIDs });
};

const fetchDKOAreasReport = ( placeIDs : number[] ) : Promise<any> => {
  const FETCH_DKO_AREAS_REPORT = gql`
    query($placeIDs : [Float!]!) {
      DKOAreasReport(placeIDs : $placeIDs)
    }
  `;
  return GraphqlService.send(FETCH_DKO_AREAS_REPORT, { placeIDs });
};

const fetchDNIActsReport = (placeIDs : number[]) : Promise<any> => {
  const FETCH_DNI_ACTS_REPORT = gql`
    query($placeIDs : [Float!]!) {
      DNIActScheduleReport(placeIDs : $placeIDs)
    }
  `;
  return GraphqlService.send(FETCH_DNI_ACTS_REPORT, { placeIDs });
};

const fetchDSPCustomersByBrokersContractsReport = (parameters : IDSPCustomersByBrokersContractsReport) : Promise<any> => {
  const FETCH_DSP_CUSTOMERS_BY_BROKERS_CONTRACTS_REPORT = gql`
    query(
      $investmentIDs: [Float!],
      $toDate: String!,
      $fromDate: String!,
      $brokerNames: [String!]!
    ) {
      DSPCustomersByBrokersContractsReport(
        investmentIDs: $investmentIDs,
        toDate: $toDate,
        fromDate: $fromDate,
        brokerNames: $brokerNames
      )
    }
  `;

  return GraphqlService.send(FETCH_DSP_CUSTOMERS_BY_BROKERS_CONTRACTS_REPORT, parameters);
};

const fetchDSPCustomersByBrokersReport = ({ toDate, fromDate, brokerNames, investmentIDs } : { toDate : string; fromDate : string; brokerNames : string[]; investmentIDs : number[] }) : Promise<any> => {
    const FETCH_REPORT = gql`
      query(
        $toDate: String!
        $fromDate: String!
        $brokerNames: [String!]!
        $investmentIDs: [Float!]!
      ) {
        DSPCustomersByBrokersReport(
          toDate: $toDate
          fromDate: $fromDate
          brokerNames: $brokerNames
          investmentIDs: $investmentIDs
        )
      }
    `;
    return GraphqlService.send(FETCH_REPORT, { toDate, fromDate, brokerNames, investmentIDs });
};

const fetchDSPBlueListReport = () : Promise<any> => {
  const FETCH_DSP_BLUE_LIST_REPORT = gql`
    query {
      DSPBlueListReport
    }
  `;

  return GraphqlService.send(FETCH_DSP_BLUE_LIST_REPORT, {});
};

const fetchDSPPlacesListReport = (parameters : IDSPPlacesListReport) : Promise<any> => {
  const FETCH_DSP_PLACES_LIST_REPORT = gql`
    query(
      $stagesIDs: [Float!]
      $investmentsIDs: [Float!]!
    ) {
      DSPPlacesListReport(
        stagesIDs: $stagesIDs
        investmentsIDs: $investmentsIDs
      )
    }
  `;

  return GraphqlService.send(FETCH_DSP_PLACES_LIST_REPORT, parameters);
};

const fetchDSPDailyInterestedPartiesReport = () : Promise<any> => {
  const FETCH_DSP_DAILY_INTERESTED_PARTIES_REPORT = gql`
    query {
      DSPDailyInterestedPartiesReport
    }
  `;

  return GraphqlService.send(FETCH_DSP_DAILY_INTERESTED_PARTIES_REPORT, {});
};

const fetchDSPSummaryReport = (parameters : IDSPSummaryReport) : Promise<any> => {
  const FETCH_DSP_SUMMARY_REPORT = gql`
    query(
      $stagesIDs: [Float!]
      $investmentsIDs: [Float!]!
      $endDate: String!
      $startDate: String!
    ) {
      DSPSummaryReport(
        stagesIDs: $stagesIDs
        investmentsIDs: $investmentsIDs
        endDate: $endDate
        startDate: $startDate
      )
    }
  `;

  return GraphqlService.send(FETCH_DSP_SUMMARY_REPORT, parameters);
};

const fetchDSPInterestedCustomersDailyReport = (parameters : IDSPDailyReport) : Promise<any> => {
  const FETCH_DSP_SUMMARY_REPORT = gql`
    query($endDate: String!, $startDate: String!) {
      DSPInterestedPartiesReport(
        endDate: $endDate
        startDate: $startDate
      )
    }
  `;

  return GraphqlService.send(FETCH_DSP_SUMMARY_REPORT, parameters);
};

const fetchDSPCustomerResignationReport = (parameters : IDSPCustomerResignationReport) : Promise<any> => {
  const FETCH_DSP_CUSTOMER_RESIGNATION_REPORT = gql`
    query(
      $tradersInitials: [String!]
      $resignationReason: [String!]
      $investmentIDs: [Float!]!
      $toDate: String!
      $fromDate: String!
    ) {
      DSPCustomerResignationReport(
        tradersInitials: $tradersInitials
        resignationReason: $resignationReason
        investmentIDs: $investmentIDs
        toDate: $toDate
        fromDate: $fromDate
      )
    }
  `;

  return GraphqlService.send(FETCH_DSP_CUSTOMER_RESIGNATION_REPORT, parameters);
};

const fetchDSPCustomerConcludedContractsReport = (parameters : IDSPCustomerConcludedContractsReport) : Promise<any> => {
  const FETCH_DSP_CUSTOMER_CONCLUDED_CONTRACTS_REPORT = gql`
    query(
      $tradersInitials: [String!]
      $toDate: String!
      $fromDate: String!
      $investmentIDs: [Float!]!
    ) {
      DSPCustomerConcludedContractsReport(
        tradersInitials: $tradersInitials
        toDate: $toDate
        fromDate: $fromDate
        investmentIDs: $investmentIDs
      )
    }
  `;

  return GraphqlService.send(FETCH_DSP_CUSTOMER_CONCLUDED_CONTRACTS_REPORT, parameters);
};

const fetchDMAMarketingCustomerReport = (variables : IDMAReports) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String!
    ) {
      DMAMarketingCustomerReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDMAStatisticCustomerReport = (variables : IDMAReports) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String
    ) {
      DMAStatisticCustomerReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDMAMarketingContractCustomerReport = (variables : IDMAReports) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String!
    ) {
      DMAMarketingContractCustomerReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDMAStatisticContractCustomerReport = (variables : IDMAReports) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
    ) {
      DMAStatisticContractCustomerReport(
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDMAMarketingContractCustomerCompaniesReport = (variables : IDMAReports) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
      $consentType: String!
    ) {
      DMAMarketingContractCustomerCompaniesReport(
        consentType: $consentType
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDMAStatisticContractCustomerCompaniesReport = (variables : IDMAReports) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $sourceInfo: [String!]
      $tradersInitials: [String!]
      $endActDate: String
      $startActDate: String
      $endAcceptanceDate: String
      $startAcceptanceDate: String
      $endContractDealDate: String
      $startContractDealDate: String
    ) {
      DMAStatisticContractCustomerCompaniesReport(
        sourceInfo: $sourceInfo
        tradersInitials: $tradersInitials
        endActDate: $endActDate
        startActDate: $startActDate
        endAcceptanceDate: $endAcceptanceDate
        startAcceptanceDate: $startAcceptanceDate
        endContractDealDate: $endContractDealDate
        startContractDealDate: $startContractDealDate
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDKSBusinessPlacesReport = (variables : IDKSBusinessPlacesReport) : Promise<any> => {
  const FETCH_REPORT = gql`
    query(
      $valorization: Boolean!
      $emailInvoice: Boolean!
      $customerType: String!
    ) {
      DKSBusinessPlacesReport(
        valorization: $valorization
        emailInvoice: $emailInvoice
        customerType: $customerType
      )
    }
  `;
  return GraphqlService.send(FETCH_REPORT, variables);
};

const fetchDARDateAnnexesReport = (placeID : number) : Promise<any> => {
  const FETCH_REPORT = gql`
    query($placeID : Float!) {
      DARDateAnnexesReport(placeID : $placeID)
    }
  `;
  return GraphqlService.send(FETCH_REPORT, { placeID });
};

const fetchDARArrangementListReport = (investmentIDs : number[]) : Promise<any> => {
  const FETCH_REPORT = gql`
    query($investmentIDs : [Float!]!) {
      DARArrangementListReport (investmentIDs : $investmentIDs)
    }
  `;
  return GraphqlService.send(FETCH_REPORT, { investmentIDs });
};


const mutations = {
  fetchDARArrangementReport,
  fetchDKOAreasReport,
  fetchDKOExecutionStatusReport,
  fetchDKOSerialCorrespondenceReport,
  fetchDNIActsReport,
  fetchDNIForNotarialOfficeReport,
  fetchDNIPickupScheduleReport,
  fetchDNIShareTableReport,
  fetchDNIAdministrationReport,
  fetchDSPCancelledContractsReport,
  fetchDSPCustomerSourceContractReport,
  fetchDSPCustomerByTradersReport,
  fetchDSPCustomersByBrokersReport,
  fetchDSPCustomersByBrokersContractsReport,
  fetchDSPBlueListReport,
  fetchDSPPlacesListReport,
  fetchDSPSummaryReport,
  fetchDSPInterestedCustomersDailyReport,
  fetchDSPCustomerResignationReport,
  fetchDSPCustomerConcludedContractsReport,
  fetchDSPDailyInterestedPartiesReport,
  fetchDMAMarketingCustomerReport,
  fetchDMAStatisticCustomerReport,
  fetchDMAMarketingContractCustomerReport,
  fetchDMAStatisticContractCustomerReport,
  fetchDMAMarketingContractCustomerCompaniesReport,
  fetchDMAStatisticContractCustomerCompaniesReport,
  fetchDKSBusinessPlacesReport,
  fetchDARDateAnnexesReport,
  fetchDARArrangementListReport
};

export default mutations;
