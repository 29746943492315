import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export const getInvestmentsWithStagesOnly = () : Promise<any> => {
    const FETCH_ALL_INVESTMENTS_WITH_STAGES_QUERY = gql`
        query {
        investments {
            id
            name
            stages {
              id
              name
              street
              investmentID
            }
            isArchive
          }
        }
    `;
    return GraphqlService.send(FETCH_ALL_INVESTMENTS_WITH_STAGES_QUERY, {});
};

export const getInvestmentsWithStagesAndBuildings = () : Promise<any> => {
  const FETCH_ALL_INVESTMENTS_WITH_BUILDINGS_QUERY = gql`
      query {
      investments {
          id
          name
          stages {
            id
            name
            street
            investmentID
            buildings {
              id
              name
              isTypeService
              description
              stageID
            }
          }
          isArchive
        }
      }
  `;
  return GraphqlService.send(FETCH_ALL_INVESTMENTS_WITH_BUILDINGS_QUERY, {});
};

export const addInvestment = ({ investment } : any) : Promise<any> => {
    const ADD_NEW_INVESTMENTS_QUERY = gql`
    mutation ai($investment: InvestmentInput!) {
      saveInvestment(investment:$investment) {
        id
      }
    }`;
    return GraphqlService.send(ADD_NEW_INVESTMENTS_QUERY, { investment });
};

export const updateInvestment = ({ investment, investmentID } : any) : Promise<any> => {
  const UPDATE_INVESTMENT_QUERY = gql`
    mutation ui($investment: InvestmentInput!, $investmentID : Float!) {
      updateInvestment(investment:$investment, investmentID : $investmentID) {
        id
      }
  }`;
  return GraphqlService.send(UPDATE_INVESTMENT_QUERY, { investment, investmentID });
};

export const removeInvestment = ({ investmentID } : any) : Promise<any> => {
  const DELETE_INVESTMENT_QUERY = gql`
    mutation di($investmentID : Float!) {
      deleteInvestment(investmentID : $investmentID)
  }`;
  return GraphqlService.send(DELETE_INVESTMENT_QUERY, { investmentID });
};

export const addInvestmentStage = ({ stage } : any) : Promise<any> => {
  const ADD_STAGE_QUERY = gql`
    mutation as($stage: StageInput!) {
      saveStage(stage:$stage) {
        id
      }
  }`;

  return GraphqlService.send(ADD_STAGE_QUERY, { stage });
};

export const updateInvestmentStage = ({ stage, stageID } : any) : Promise<any> => {
  const UPDATE_STAGE_QUERY = gql`
  mutation us($stage: StageInput!, $stageID : Float!) {
    updateStage(stage:$stage, stageID : $stageID) {
      id
    }
  }`;

  return GraphqlService.send(UPDATE_STAGE_QUERY, { stage, stageID });
};

export const removeInvestmentStage = ({ stageID } : any) : Promise<any> => {
  const DELETE_STAGE_QUERY = gql`
  mutation ds($stageID : Float!) {
    deleteStage(stageID : $stageID)
  }`;

  return GraphqlService.send(DELETE_STAGE_QUERY, { stageID });
};

const mutations = {
  addInvestmentStage,
  getInvestmentsWithStagesAndBuildings,
  getInvestmentsWithStagesOnly,
  addInvestment,
  updateInvestment,
  removeInvestment,
  updateInvestmentStage,
  removeInvestmentStage
};

export default mutations;
