export const resignationReasons : string[] = [
    ' ',
    'Brak zdolności kredytowej',
    'Brak finansowania własnego',
    'Lepiej dopasowana oferta do potrzeb Klienta',
    'Niższa cena',
    'Nieakceptowalny poziom cenowy',
    'Niesatysfakcjonujący upust',
    'Wzrost cen w trakcie rozmów ',
    'Zmiana sytuacji życiowej klienta',
    'Brak możliwości wprowadzenia zmian',
    'Brak możliwości połączenia',
    'Koszt zmian aranżacyjnych',
    'Brak wykończenia pod klucz',
    'Brak lokalu spełniającego wymagania Klienta',
    'Za szybkie trminy płątności rat ',
    'Za późny termin odbioru lokalu ',
    'Za późny termin umowy ostatecznej',
    'Miejsca postojowe',
    'Zapisy umowne',
    'Uciążliwość prac budowlanych w sąsiedztwie',
    'Opinie o inwestycji lub inwestorze'
];