import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { setProfile, setToken, setTokenParsed } from '../../ducks/globalUser';
import { idpEventHandler, idpInitConfig, idpTokensHandler } from '../../services/idp';

import Toast from '../commons/Toast/Toast';
import AppRouter from './AppRouter';
import AppVersion from './AppVersion';

const {
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_CLIENTID
} = process.env;

export const keycloak = Keycloak({
  url : REACT_APP_KEYCLOAK_URL,
  realm : REACT_APP_KEYCLOAK_REALM || 'investkomfort',
  clientId : REACT_APP_KEYCLOAK_CLIENTID || ''
});

const KeycloakConnector : FunctionComponent = () => {

  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();

  const isUserAuthenticated = keycloak?.authenticated;

  useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak.loadUserProfile()
        .then((response : any) => {
          dispatch(setProfile(response));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak?.authenticated]);

  useEffect(() => {
    const tokenParsed = keycloak?.tokenParsed;
    const token = keycloak?.token;

    if (initialized && token && tokenParsed) {
      dispatch(setTokenParsed(tokenParsed));
      dispatch(setToken(token));
    }
  }, [dispatch, initialized, isUserAuthenticated, keycloak]);

  const appRouterBody = useMemo(
    () => (
      <>
        <Toast />
        <AppRouter />
        <AppVersion />
      </>
    ), []
  );

  return keycloak?.authenticated ? appRouterBody : null;
};

const App : FunctionComponent = () => {
  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={idpInitConfig}
      onEvent={idpEventHandler}
      onTokens={idpTokensHandler}
    >
      <KeycloakConnector />
    </KeycloakProvider>
  );
};

export default App;
