import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import ParkingPlaceService from '../services/parkingPlaceService';
import { RootState } from './index';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';


export interface IParkingPlace {
  id ?: number;
  name : string;
  type : string;
  comment ?: string | undefined;
  isExternal : boolean;
  garageID ?: number | null;
  placeID ?: number | null;
  contractID ?: number | null;
  isTypeService : boolean;
  building ?: {
    id : number;
    name : string;
  };
  garage ?: {
    id : number;
    name : string;
  };
}

interface IParkingPlacesStore {
  parkingPlaces : IParkingPlace[];
  allParkingPlaces : IParkingPlace[];
  parkingPlacesLinkedToPlace : IParkingPlace[];
  parkingPlacesLinkedToContract : IParkingPlace[];
  unassignedParkingPlaces : IParkingPlace[];
}

const initialState : IParkingPlacesStore = {
  parkingPlaces : [],
  allParkingPlaces : [],
  parkingPlacesLinkedToPlace : [],
  parkingPlacesLinkedToContract : [],
  unassignedParkingPlaces : []
};

export const getUnassignedParkingPlacesMinusAdded =  createSelector(
  (state : RootState) => state.parkingPlaces,
  (parkingPlaces) => {
    return parkingPlaces.unassignedParkingPlaces.filter(unassigned => !unassigned.contractID);
  }
);

export const getAllParkingPlaces = createAsyncThunk(
  'parkingPlaces/getAllParkingPlaces',
  async () => {
    return ParkingPlaceService.getAllParkingPlaces();
  }
);

export const getParkingPlacesByInvestmentName = createAsyncThunk('parkingPlaces/getParkingPlacesByInvestmentName', async (investmentName : string) => {
  return ParkingPlaceService.getParkingPlacesByInvestmentName(investmentName);
});

export const getParkingPlacesByInvestmentStageId = createAsyncThunk('parkingPlaces/getParkingPlacesByInvestmentStageId', async (stageID : number) => {
  return ParkingPlaceService.getParkingPlacesByInvestmentStageId(stageID);
});

export const addNewParkingPlace = createAsyncThunk('parkingPlaces/addNewParkingPlace', async (variables : {parkingPlace : IParkingPlace}) => {
  return ParkingPlaceService.addNewParkingPlace(variables);
});

export const updateParkingPlace = createAsyncThunk('parkingPlaces/updateParkingPlace', async (variables : {parkingPlace : IParkingPlace; parkingPlaceID : number}) => {
  return ParkingPlaceService.updateParkingPlace(variables);
});

export const removeParkingPlace = createAsyncThunk('parkingPlaces/removeParkingPlace', async (parkingPlaceID : number) => {
  return ParkingPlaceService.removeParkingPlace(parkingPlaceID);
});

export const getParkingPlacesLinkedToContract = createAsyncThunk(
  'parkingPlaces/getParkingPlacesLinkedToContract',
  async (variables : { investmentName : string; contractID : number }) => {
    return {
      parkingPlaces : await ParkingPlaceService.getParkingPlacesByInvestmentName(variables.investmentName) as IParkingPlace[],
      contractID : variables.contractID
    };
  }
);

export const getParkingPlacesLinkedToPlace = createAsyncThunk(
  'parkingPlaces/getParkingPlacesLinkedToPlace',
  async (variables : { investmentName : string; placeID : number }) => {
    return {
      parkingPlaces : await ParkingPlaceService.getParkingPlacesByInvestmentName(variables.investmentName) as IParkingPlace[],
      placeID : variables.placeID
    };
  }
);

export const getUnassignedParkingPlaces = createAsyncThunk(
  'parkingPlaces/getUnassignedParkingPlaces',
  async (variables : { investmentName : string}) => {
    return {
      parkingPlaces : await ParkingPlaceService.getParkingPlacesByInvestmentName(variables.investmentName) as IParkingPlace[]
    };
  }
);

const parkingPlacesSlice = createSlice({
  name : 'parkingPlaces',
  initialState,
  reducers : {
    addParkingPlaceToContract (state, action : PayloadAction<IParkingPlace>) : void {
      state.parkingPlacesLinkedToContract.push(action.payload);
    },
    removeParkingPlaceFromContract (state, action : PayloadAction<number>) : void {
      const id = action.payload;
      const foundIndex = state.parkingPlacesLinkedToContract.findIndex(room => room.id === id);
      if (foundIndex !== -1){
        state.parkingPlacesLinkedToContract.splice(foundIndex);
      }
    },
    clearLinkedParkingPlaces (state) : void {
      state.parkingPlacesLinkedToContract = [];
      state.parkingPlacesLinkedToPlace = [];
    }
  },
  extraReducers : {
    [getAllParkingPlaces.fulfilled.toString()] : (state, action) : void => {
      state.allParkingPlaces = action.payload.parkingPlaces;
    },
    [getParkingPlacesByInvestmentName.fulfilled.toString()] : (state, action) : void => {
      state.parkingPlaces = action.payload.parkingPlacesByInvestmentsNamesThroughGarage;
    },
    [getParkingPlacesByInvestmentName.rejected.toString()] : (state, action) : void => {
      state.parkingPlaces = [];
    },
    [getParkingPlacesLinkedToContract.fulfilled.toString()] : (state, action) : void => {
      const contractID = action.payload.contractID;
      const rawParkingPlaces = action.payload.parkingPlaces.parkingPlacesByInvestmentsNamesThroughGarage as IParkingPlace[];
      const filteredParkingPlaces = rawParkingPlaces.filter((room : IParkingPlace) => room.contractID === contractID);
      state.parkingPlacesLinkedToContract = filteredParkingPlaces;
    },
    [getParkingPlacesLinkedToContract.rejected.toString()] : (state, _) : void => {
      state.parkingPlacesLinkedToContract = [];
    },
    [getParkingPlacesLinkedToPlace.fulfilled.toString()] : (state, action) : void => {
      const placeID = action.payload.placeID;
      const rawParkingPlaces = action.payload.parkingPlaces.parkingPlacesByInvestmentsNamesThroughGarage as IParkingPlace[];
      const filteredParkingPlaces = rawParkingPlaces.filter((room : IParkingPlace) => room.placeID === placeID);
      state.parkingPlacesLinkedToPlace = filteredParkingPlaces;
      //if parkingPlacesLinkedToPlace is not empty there should not be any parkingPlaces linked to Contract! only 'either - or'
      if (filteredParkingPlaces.length > 0) {
        state.parkingPlacesLinkedToContract = [];
      }
    },
    [getParkingPlacesLinkedToPlace.rejected.toString()] : (state, _) : void => {
      state.parkingPlacesLinkedToPlace = [];
    },
    [getUnassignedParkingPlaces.fulfilled.toString()] : (state, action) : void => {
      const possibleParkingPlaces = action.payload.parkingPlaces.parkingPlacesByInvestmentsNamesThroughGarage ?? [];
      const rawParkingPlaces = possibleParkingPlaces.filter(
        (room : IParkingPlace) => room.contractID == null
      ) as IParkingPlace[];

      const filteredParkingPlaces = rawParkingPlaces.filter((room : IParkingPlace) => room.placeID == null); // Business logic - unassigned places dont' have a place
      state.unassignedParkingPlaces = filteredParkingPlaces;
    },
    [getParkingPlacesByInvestmentStageId.fulfilled.toString()] : (state, action) : void => {
      state.parkingPlaces = action.payload.parkingPlaceByStages;
    },
    [getParkingPlacesByInvestmentStageId.rejected.toString()] : (state, action) : void => {
      state.parkingPlaces = [];
    },
    [addNewParkingPlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addParkingPlace')));
    },
    [removeParkingPlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deleteParkingPlace')));
    },
    [updateParkingPlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editParkingPlace')));
    }
  }
});

export const { addParkingPlaceToContract, removeParkingPlaceFromContract, clearLinkedParkingPlaces } = parkingPlacesSlice.actions;

export default parkingPlacesSlice.reducer;
