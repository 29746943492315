import * as React from 'react';
import { Row } from 'react-bootstrap';
import { useTableContext } from './TableContext';

const TableFooter : React.FC = ({ children }) => {
const { selectedFlatRows } = useTableContext();
 return (
  <div>
   {selectedFlatRows.length === 1 && (
      <Row className='d-flex justify-content-center my-2 w-100'>
       {children}
      </Row>)}
  </div>
 );
};

export default TableFooter;