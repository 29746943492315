import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';
import { useDispatch } from 'react-redux';
import { FC, useCallback, useState } from 'react';

export interface TableSearchWithPaginationProps {
  query : string;
  setQuery : (value : string) => void;
  refreshPage : (value : boolean) => void;
}

const TableSearchWithPagination : FC<TableSearchWithPaginationProps> = ({
  query,
  setQuery,
  refreshPage
} : TableSearchWithPaginationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(query);
  const onChange = useAsyncDebounce((value) => {
    dispatch(setQuery(value));
    dispatch(refreshPage(true));
  }, 800);

  const onChangeHandler = useCallback((e : React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  }, [onChange]);

  return (
    <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
      <span>
        <input
          className="search-input"
          value={value || ''}
          onChange={onChangeHandler}
          placeholder={`${t('commons:labels.type-searched-keyword')}...`}
        />
      </span>
    </Col>
  );
};

export default TableSearchWithPagination;
