import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BuildingService, { IBuilding, IBuildingInput } from '../services/buildingService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface IBuildingState {
    allBuildings : IBuilding[];
    buildingsByInvestmentsNames : IBuilding[];
}

const initialState : IBuildingState = {
    allBuildings : [],
    buildingsByInvestmentsNames : []
};

export const getAllBuildings = createAsyncThunk(
    'buildings/getAllBuildings',
    async () => {
        return BuildingService.getAllBuilidings();
    }
);

export const getBuildingsByInvestmentNames = createAsyncThunk(
    'garages/getBuildingsByInvestmentNames',
    async (investmentsNames : string[]) => {
        return BuildingService.getBuildingsByInvestmentsNames(investmentsNames);
    }
);

export const addNewBuilding = createAsyncThunk(
    'buildings/addNewBuilding',
    async (building : IBuildingInput) => {
        return BuildingService.addBuilding(building);
    }
);

export const deleteBuilding = createAsyncThunk(
    'buildings/deleteBuilding',
    async (buildingID : number) => {
        return BuildingService.deleteBuilding(buildingID);
    }
);

export const updateBuilding = createAsyncThunk(
    'buildings/updateBuilding',
    async (variables : {buildingID : number; building : IBuildingInput}) => {
        return BuildingService.updateBuilding(variables);
    }
);

const buildingsSlice = createSlice({
    name : 'buildings',
    initialState,
    reducers : {},
    extraReducers : {
        [getAllBuildings.fulfilled.toString()] : (state, action) : void => {
            state.allBuildings = action.payload.buildings;
        },
        [getBuildingsByInvestmentNames.fulfilled.toString()] : (state, action) : void => {
            state.buildingsByInvestmentsNames = action.payload.buildingsByInvestmentsNames;
        },
        [addNewBuilding.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:addBuilding')));
            const newBuilding = action.payload.saveBuilding;
            state.buildingsByInvestmentsNames = [...state.buildingsByInvestmentsNames, newBuilding];
        },
        [deleteBuilding.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:deleteBuilding')));
            const deletedBuildingID = action.meta.arg;
            const filteredBuildings = state.buildingsByInvestmentsNames.filter(building => building.id !== deletedBuildingID);
            state.buildingsByInvestmentsNames = filteredBuildings;
        },
        [updateBuilding.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:editBuilding')));
        }
    }
});

export default buildingsSlice.reducer;
