import * as React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import './Loader.scss';

type AppLoaderProps = {
  timeout ?: number;
}

const AppLoader : React.FC<AppLoaderProps> = ({ timeout = 750 }) => (
  <div className="d-flex justify-content-center px-3 py-3">
    <Loader
      type="Circles"
      color="#06bff3"
      height={60}
      width={60}
      secondaryColor="#ffffff"
      timeout={timeout}
   />
  </div>
);


export default AppLoader;
