import React, { FunctionComponent } from 'react';
import { Modal } from 'react-bootstrap';

interface IReduxModalProps {
  body : JSX.Element | string;
  customWidth ?: 50 | 75 | 100;
  cancelAction : () => void;
  externalValidated ? : boolean;
  isDisabled ?: boolean;
  submitAction ? : () => void;
  onHide : () => void;
  onSubmit ?: any;
  show : boolean;
  title : string;
  submitText ? : string;
  size ?: 'sm' | 'lg' | 'xl' | undefined;
}

export const ReduxModal : FunctionComponent <IReduxModalProps> = (props : IReduxModalProps) => {
  const { body, customWidth, isDisabled, onHide, show, title, size } = props;
  return (
    <Modal
      size={size}
      dialogClassName={customWidth ? `modal-${customWidth}w` : 'modal-25w'}
      show={isDisabled ? true : show}
      onHide={isDisabled ? undefined : onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

export default ReduxModal;
