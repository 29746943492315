import * as React from 'react';

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import { downloadXlsxReport, fetchDARArrangementListReport } from '../../ducks/reports';
import { IInvestment } from '../../graphql/investments';
import SimpleInputCheckbox from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox';
import './Reports.scss';


const DARArrangementListReport : React.FC = () => {
 const { t } = useTranslation();
 const { investmentsList } = useSelector((state : RootState) => state.globalInvestment);
 const isLoading = useSelector((state : RootState) => state.combinedReports.DARArrangementListReport.isLoading);
 const [investmentIDs, setInvestmentIDs] = React.useState<number[]>([]);
 const dispatch : AppDispatch = useDispatch();

 const updateSelectedInvestments = React.useCallback((id : number) => {
  const investmentIsSelected = investmentIDs.includes(id);
  if (investmentIsSelected) {
     return setInvestmentIDs((prevState : number[]) => prevState.filter(inv => inv !== id));
  }
  setInvestmentIDs((prevState : number[]) => [...prevState, id]);
}, [investmentIDs]);

 const onSubmit = React.useCallback((e : React.FormEvent) => {
  e.preventDefault();
  dispatch(fetchDARArrangementListReport(investmentIDs))
  .then((response : any) => {
    if (response.payload?.DARArrangementListReport) {
      return dispatch(downloadXlsxReport({
        content : response.payload.DARArrangementListReport,
        filename : 'Lista.xlsx'
      }));
    }
    alert('Błąd podczas pobierania raportu');
  });
 }, [dispatch, investmentIDs]);

 return (
      <form onSubmit={onSubmit}>
        <div className="container">
          <div className="checkbox-wrapper">
            {investmentsList.map((investment : IInvestment, index : number) => (
              <SimpleInputCheckbox 
                label={`${index + 1}. ${investment.name}`}
                key={`${index + 1}. ${investment.name}`}
                additionalOnChange={() : void => updateSelectedInvestments(investment.id)}
                customLabelWidth={15}
              />))
            }
          </div>
          <Button type='submit' disabled={!investmentIDs.length || isLoading}>
            {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
          </Button>
       </div>
      </form>
 );
};

export default DARArrangementListReport;