import * as React from 'react';
import { Row } from 'react-bootstrap';

const TableTitle : React.FC = ({ children }) => {
 return (
  <Row className="h6-table-title-long">
   {children}
  </Row>
 );
};

export default TableTitle;
