import { gql } from 'graphql-request';
import { IContract } from '../graphql/contracts/contracts';
import GraphqlService from './graphqlService';

export interface ICorrespondenceFile {
  id : number;
  name : string;
  content : string;
  correspondenceID : number;
}

export interface ICorrespondenceFileInput {
  name : string;
  content : string;
  correspondenceID : number;
}

export interface ICorrespondenceTableRow  {
  id : number;
  selectedRow : ICorrespondence;
}

export interface ICorrespondence {
  id : number;
  changeDate : string;
  changeType : string;
  correspondenceDate : string;
  comments : string;
  tradersList : string;
  correspondenceFile ?: ICorrespondenceFile;
  contractID : number;
  contract ?: IContract;
}

export interface ICorrespondenceQuery {
  getContract : {
    correspondences : ICorrespondence[];
  };
};

export interface ICorrespondenceInput {
  changeDate : string;
  changeType : string;
  correspondenceDate : string;
  comments : string;
  tradersList : string;
  contractID : number;
};

export interface IChanges {
  author : string;
  opID : string;
  date : string;
}


const getAllCorrespondences = () : Promise<any> => {
  const FETCH_ALL_CORRESPONDENCES_QUERY = gql`
    query {
      correspondences {
        id
        correspondenceFile {
          name
          id
        }
        changeType
        changeDate
        correspondenceDate
        comments
        tradersList
      }
    }
  `;
  return GraphqlService.send(FETCH_ALL_CORRESPONDENCES_QUERY, {});
};

const getAllCorrespondencesById = (contractID : number) : Promise<any> => {
  const FETCH_CORRESPONDENCES_BY_CONTRACT_ID = gql`
  query ($contractID: Float!) {
    getContract(contractID: $contractID) {
      correspondences {
        id
        correspondenceFile {
          name
          id
        }
        changeType
        changeDate
        correspondenceDate
        comments
        tradersList
      }
    }
  }
`;
  return GraphqlService.send(FETCH_CORRESPONDENCES_BY_CONTRACT_ID, { contractID });
};

const saveConrespondence = (correspondence : ICorrespondenceInput) : Promise<any> => {
  const SAVE_CORRESPONDENCE = gql`
  mutation ac($correspondence: CorrespondenceInput!) {
    saveCorrespondence(correspondence: $correspondence) {
      id
      correspondenceFile {
        name
        id
      }
      changeType
      changeDate
      correspondenceDate
      comments
      tradersList
    }
  }
`;
return GraphqlService.send(SAVE_CORRESPONDENCE, { correspondence });
};

const saveCorrespondenceFile = (correspondenceFile : ICorrespondenceFileInput) : Promise<any> => {
  const SAVE_CORRESPONDENCE_FILE = gql`
    mutation acf($correspondenceFile: CorrespondenceFileInput!) {
      saveCorrespondenceFile(correspondenceFile: $correspondenceFile) {
        id
      	name
      	correspondenceID
      }
    }
  `;
  return GraphqlService.send(SAVE_CORRESPONDENCE_FILE, { correspondenceFile });
};

const updateCorrespondence = ({ correspondence, correspondenceID } : {correspondence : ICorrespondenceInput; correspondenceID : number} ) : Promise<any> => {
  const UPDATE_CORRESPONDENCE = gql`
    mutation ac(
      $correspondence: CorrespondenceInput!
      $correspondenceID: Float!
    ) {
      updateCorrespondence(
        correspondence: $correspondence
        correspondenceID: $correspondenceID
      ) {
        id
        correspondenceFile {
          name
          id
        }
        changeType
        changeDate
        correspondenceDate
        comments
        tradersList
      }
    }
  `;
  return GraphqlService.send(UPDATE_CORRESPONDENCE, { correspondenceID, correspondence });
};

const updateCorrespondenceFile = ({ correspondenceFile, correspondenceFileID } : { correspondenceFile : ICorrespondenceFileInput; correspondenceFileID : number } ) : Promise<any> => {
  const UPDATE_CORRESPONDENCE_FILE = gql`
    mutation acf(
      $correspondenceFile: CorrespondenceFileInput!
      $correspondenceFileID: Float!
    ) {
      updateCorrespondenceFile(
        correspondenceFile: $correspondenceFile
        correspondenceFileID: $correspondenceFileID
      ) {
        id
      }
    }
  `;
  return GraphqlService.send(UPDATE_CORRESPONDENCE_FILE, { correspondenceFile, correspondenceFileID });
};

const deleteCorrespondence = ( correspondenceID : number ) : Promise<any> => {
  const DELETE_CORRESPONDENCE = gql`
    mutation ac($correspondenceID: Float!) {
      deleteCorrespondence(correspondenceID: $correspondenceID)
    }
  `;
  return GraphqlService.send(DELETE_CORRESPONDENCE, { correspondenceID });
};

const deleteCorrespondenceFile = ( correspondenceFileID : number ) : Promise<any> => {
  const DELETE_CORRESPONDENCE_FILE = gql`
    mutation acf($correspondenceFileID: Float!) {
      deleteCorrespondenceFile(correspondenceFileID: $correspondenceFileID)
    }
  `;
    return GraphqlService.send(DELETE_CORRESPONDENCE_FILE, { correspondenceFileID });
};

const fetchCorrespondenceChanges = (contractID : number) : Promise<any> => {
  const CORRESPONDENCE_CHANGES = gql`
    query($contractID : Float!) {
      getCorrespondencesChangesByContract(contractID:$contractID) {
        id
        opID
        author
        date
      }
    }
  `;
  return GraphqlService.send(CORRESPONDENCE_CHANGES, { contractID });
};

export const getCorrespondenceFile = (fileID : number) : Promise<any> => {
  const GET_CORRESPONDENCE_FILE = gql`
    query gcf($correspondenceFileID: Float!) {
      getCorrespondenceFile(correspondenceFileID: $correspondenceFileID) {
        id
        content
      }
    }
  `;

  return GraphqlService.send(GET_CORRESPONDENCE_FILE, { correspondenceFileID : fileID });
};

export const getEventFile = (fileID : number) : Promise<any> => {
  const GET_EVENT_FILE = gql`
    query gcf($eventFileID: Float!) {
      getEventFile(eventFileID: $eventFileID) {
        id
        content
      }
    }
  `;

  return GraphqlService.send(GET_EVENT_FILE, { eventFileID : fileID });
};

const mutations = {
  saveConrespondence,
  getAllCorrespondences,
  getAllCorrespondencesById,
  saveCorrespondenceFile,
  updateCorrespondence,
  updateCorrespondenceFile,
  deleteCorrespondence,
  deleteCorrespondenceFile,
  fetchCorrespondenceChanges,
  getCorrespondenceFile,
  getEventFile
};

export default mutations;
