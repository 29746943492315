import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTableContext } from './TableContext';

export type SensitiveInfoButtonProps = {
 initialHiddenData ?: string[];
 sensitiveColumns : string[];
}

const SensitiveInfoButton : React.FC<SensitiveInfoButtonProps> = ({ initialHiddenData, sensitiveColumns }) => {
 const { t } = useTranslation();
 const [isSensitiveHidden, setIsSensitiveHiiden] = React.useState(false);
 const { setHiddenColumns } = useTableContext();
 const colsToHide = initialHiddenData ?? [''];

 const handleHideSensitiveClick = React.useCallback(() : void => {
  if (isSensitiveHidden) {
    setHiddenColumns(colsToHide);
    setIsSensitiveHiiden(false);
  } else {
    setHiddenColumns(colsToHide.concat(sensitiveColumns));
    setIsSensitiveHiiden(true);
  }
}, [isSensitiveHidden, colsToHide, sensitiveColumns, setHiddenColumns]);
 
 return (
  <Col className="d-flex justify-content-end mx-1 px-0" lg="auto" md="auto" sm="auto">
  <Button className="primary" onClick={handleHideSensitiveClick}>
    {isSensitiveHidden ? t('commons:actions.show-sensitive-data') : t('commons:actions.hide-sensitive-data')}
  </Button>
 </Col>
 );
};

export default SensitiveInfoButton;