import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PlacesServices, {
  IAdminPlace,
  IGroundPlanPlaceInput,
  IPlaceInput,
  IUpdateGroundPlan,
  PlacesByInvestmentsNamesForAdminPanel
} from '../services/placesService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';
import { IPlaceContractsQuery } from '../graphql/currentPlaceContracts/currentPlaceContracts';
import { IInterestedCustomer } from '../graphql/customers';
import { RootState } from '.';
import ContractCustomerService from '../services/contractCustomerService';
import { IContractCustomerInput } from '../graphql/contractCustomers';
import { defaultMarketingConsents } from './marketingConsents';

interface IPlacesState {
  adminPlaces : IAdminPlace[];
  selectedPlace : Partial<IPlaceContractsQuery> | undefined;
  interestedCustomers : IInterestedCustomer[];
  selectedInterestedCustomerID : number | undefined;
  isLoading : boolean;
  paginatedAdminPlaces : {
    places : IAdminPlace[];
    total : number;
  };
}

const initialState : IPlacesState = {
  adminPlaces : [],
  interestedCustomers : [],
  selectedPlace : undefined,
  selectedInterestedCustomerID : undefined,
  isLoading : false,
  paginatedAdminPlaces : {
    places : [],
    total : 0
  }
};

export const getPlacesByInvestmentsNamesForAdminPanel = createAsyncThunk(
  'places/getPlacesByInvestmentsNamesForAdminPanel', async (investmentNames : string[]) => {
    return PlacesServices.getPlacesByInvestmentsNamesForAdminPanel(investmentNames);
  });

export const getPlacesByInvestmentsNamesForInterestedCustomers = createAsyncThunk(
  'places/getPlacesByInvestmentsNamesForInterestedCustomers', async (investmentNames : string[]) => {
    return PlacesServices.getPlacesByInvestmentsNamesForInterestedCustomers(investmentNames);
});



export const getPaginatedPlacesByInvestmentsNamesForAdminPanel = createAsyncThunk(
  'places/getPaginatedPlacesByInvestmentsNamesForAdminPanel', async (variables : PlacesByInvestmentsNamesForAdminPanel) => {
    return PlacesServices.getPaginatedPlacesByInvestmentsNamesForAdminPanel(variables);
  });

export const getPlacesByInvestmentStageId = createAsyncThunk('places/getPlacesByInvestmentStageId',
  async (stageID : number) => {
    return PlacesServices.getPlacesByInvestmentStageId(stageID);
  });

export const addNewPlace = createAsyncThunk(
  'places/addPlace',
  async (place : IPlaceInput) => {
    return PlacesServices.addPlace(place);
  }
);

export const updatePlace = createAsyncThunk(
  'places/updatePlace',
  async (variables : { place : IPlaceInput; placeID : number }) => {
    return PlacesServices.updatePlace(variables);
  }
);

export const deletePlace = createAsyncThunk(
  'places/deletePlaces',
  async (placeID : number) => {
    return PlacesServices.deletePlace(placeID);
  }
);

export const addGroundPlan = createAsyncThunk(
  'places/addGroundPlan',
  async (file : IGroundPlanPlaceInput) => {
    return PlacesServices.saveGroundPlanIntoPlace(file);
  }
);

export const updateGroundPlan = createAsyncThunk(
  'places/updateGroundPlan',
  async (parameters : IUpdateGroundPlan) => {
    return PlacesServices.editGroundPlane(parameters);
  }
);

export const deleteGroundPlan = createAsyncThunk(
  'places/deleteGroundPlan',
  async (fileID : number) => {
    return PlacesServices.deleteGroundPlan(fileID);
  }
);

export const fetchPlaceByPlaceID = createAsyncThunk(
  'places/fetchPlaceByPlaceID',
  async (placeID : number) => {
    return PlacesServices.fetchPlaceByPlaceID(placeID);
  }
);

export const fetchCancelledContractPlaceByPlaceID = createAsyncThunk(
  'places/fetchCancelledContractPlaceByPlaceID',
  async (placeID : number) => {
    return PlacesServices.fetchCancelledContractPlaceByPlaceID(placeID);
  }
);

export const updatePlaceRelatedToContract = createAsyncThunk(
  'places/updatePlaceRelatedToContract',
  async (variables : any) => {
    return PlacesServices.updatePlaceRelatedToContract(variables);
  }
);


export const updateContractCustomerWithImportedInterestedCustomer = createAsyncThunk('places/updateContract', async (customer : IContractCustomerInput, thunkAPI) => {
  const activeContracts = (thunkAPI.getState() as RootState).places.selectedPlace?.activeContracts;
  const selectedInterestedCustomer = (thunkAPI.getState() as RootState).interestedCustomers.selectedInterestedCustomer;

  if (selectedInterestedCustomer && activeContracts) {
    const contractCustomersList = [...activeContracts[0].contractCustomers];

    if (selectedInterestedCustomer) {
      const idExists = contractCustomersList?.filter(c => c.id > 0).find(c => c.email === selectedInterestedCustomer.email &&
        c.firstName === selectedInterestedCustomer.firstName &&
        c.lastName === selectedInterestedCustomer.lastName);
      const c = selectedInterestedCustomer;

      if (idExists) {
        alert('Wybrany klient zainteresowany został już dodany do umowy');
        return;
      }

      const newContractCustomer = {
        uuid : customer.uuid,
        parentUuid : customer.parentUuid,
        customerID : c.id ?? undefined,
        customerCode : customer.customerCode ?? '',
        customerType : c.customerType ?? '',
        firstName : c.firstName ?? '',
        lastName : c.lastName ?? '',
        secondName : c.secondName ?? '',
        maidenName : c.maidenName ?? '',
        parentsNames : c.parentsNames ?? '',
        pesel : c.pesel ?? '',
        taxNumber : c.taxNumber ?? '',
        sex : c.sex ?? '',
        phone1 : c.phone1 ?? '',
        phone2 : c.phone2 ?? '',
        email : c.email ?? '',
        passportNumberAndSeries : c.passportNumberAndSeries ?? '',
        IDNumberAndSeries : c.IDNumberAndSeries ?? '',
        citizenship : c.citizenship ?? '',
        isBusiness : Boolean(c.isBusiness) || false,
        regon : c.regon ?? '',
        businessAddressName : c.businessAddressName ?? '',
        businessAddressSurname : c.businessAddressSurname ?? '',
        businessAddressBusinessName : c.businessAddressBusinessName ?? '',
        businessAddressCountry : c.businessAddressCountry ?? '',
        businessAddressCity : c.businessAddressCity ?? '',
        businessAddressStreet : c.businessAddressStreet ?? '',
        businessAddressNumber : c.businessAddressNumber ?? '',
        businessAddressPostalCode : c.businessAddressPostalCode ?? '',
        businessAddressVoivodeship : c.businessAddressVoivodeship ?? '',
        businessAddressIsForeign : c.businessAddressIsForeign ?? false,
        residenceName : c.residenceName ?? '',
        residenceSurname : c.residenceSurname ?? '',
        residenceBusinessName : c.residenceBusinessName ?? '',
        residenceCountry : c.residenceCountry ?? '',
        residenceCity : c.residenceCity ?? '',
        residenceStreet : c.residenceStreet ?? '',
        residenceNumber : c.residenceNumber ?? '',
        residencePostalCode : c.residencePostalCode ?? '',
        residenceVoivodeship : c.residenceVoivodeship ?? '',
        residenceIsForeign : c.residenceIsForeign ?? false,
        correspondenceName : c.correspondenceName ?? '',
        correspondenceSurname : c.correspondenceSurname ?? '',
        correspondenceBusinessName : c.correspondenceBusinessName ?? '',
        correspondenceCountry : c.correspondenceCountry ?? '',
        correspondenceCity : c.correspondenceCity ?? '',
        correspondenceStreet : c.correspondenceStreet ?? '',
        correspondenceNumber : c.correspondenceNumber ?? '',
        correspondencePostalCode : c.correspondencePostalCode ?? '',
        correspondenceVoivodeship : c.correspondenceVoivodeship ?? '',
        correspondenceIsForeign : c.correspondenceIsForeign ?? false,
        maritalStatus : c.maritalStatus ?? '',
        contractID : customer.contractID ?? undefined,
        customerInvestmentCode : customer.customerInvestmentCode ?? '',
        isToughCustomer : c.isToughCustomer ?? false,
        isBlueList : c.isBlueList ?? false,
        isSpouseOfMainCustomer : customer.isSpouseOfMainCustomer ?? false,
        isAgentOfMainCustomer : customer.isAgentOfMainCustomer ?? false,
        isAgentOfCustomerSpouse : customer.isAgentOfCustomerSpouse ?? false,
        purchasePurpose : customer.purchasePurpose ?? '',
        businessType : c.businessType ?? '',
        businessName : c.businessName ?? '',
        sourceInfo : c.sourceInfo ?? '',
        traderInitials : c.traderInitials ?? '',
        isBroker : c.isBroker ?? false,
        brokerName : c.brokerName ?? ''
      };
      return ContractCustomerService.updateContractCustomer({
        contractCustomer : newContractCustomer,
        contractCustomerID : customer.id,
        marketingConsent : customer.marketingConsents ? customer.marketingConsents[0] : defaultMarketingConsents
      });
    }
  }
});

const placesSlice = createSlice({
  name : 'places',
  initialState,
  reducers : {
    setSelectedInterestedCustomerID (state, action : PayloadAction<number>) : void {
      state.selectedInterestedCustomerID = action.payload;
    },
    clearSelectedPlaceState (state) : void {
      state.selectedPlace = undefined;
    },
    clearAdminPlaces (state) : void {
      state.adminPlaces = [];
    }
  },
  extraReducers : {
    [addNewPlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addPlace')));
    },
    [getPaginatedPlacesByInvestmentsNamesForAdminPanel.fulfilled.toString()] : (state, action) : void => {
      state.paginatedAdminPlaces.places = action.payload.paginatedPlacesByInvestmentsNames.places;
      state.paginatedAdminPlaces.total = action.payload.paginatedPlacesByInvestmentsNames.total;
    },
    [getPlacesByInvestmentsNamesForInterestedCustomers.fulfilled.toString()] : (state, action) : void => {
      state.adminPlaces = action.payload.placesByInvestmentsNames;
    },
    [getPlacesByInvestmentsNamesForAdminPanel.pending.toString()] : (state) : void => {
      state.isLoading = true;
    },
    [getPlacesByInvestmentsNamesForAdminPanel.fulfilled.toString()] : (state, action) : void => {
      state.adminPlaces = action.payload.placesByInvestmentsNames;
      state.isLoading = false;
    },
    [getPlacesByInvestmentsNamesForAdminPanel.rejected.toString()] : (state) : void => {
      state.adminPlaces = [];
      state.isLoading = true;
    },
    [getPlacesByInvestmentStageId.fulfilled.toString()] : (state, action) : void => {
      state.adminPlaces = action.payload.placesByInvestmentsNames;
    },
    [getPlacesByInvestmentStageId.rejected.toString()] : (state) : void => {
      state.adminPlaces = [];
    },
    [fetchPlaceByPlaceID.fulfilled.toString()] : (state, action) : void => {
      state.selectedPlace = action.payload.place;
    },
    [fetchCancelledContractPlaceByPlaceID.fulfilled.toString()] : (state, action) : void => {
      state.selectedPlace = action.payload.place;
    },
    [deleteGroundPlan.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deleteGroundPlan')));
    },
    [updateGroundPlan.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editGroundPlan')));
    },
    [addNewPlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addPlace')));
    },
    [deletePlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deletePlace')));
    },
    [updatePlace.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editPlace')));
    },
    [addGroundPlan.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addGroundPlan')));
    },
    [deleteGroundPlan.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deleteGroundPlan')));
    },
    [updatePlaceRelatedToContract.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editData')));
    }
  }
});

export const { setSelectedInterestedCustomerID, clearSelectedPlaceState, clearAdminPlaces } = placesSlice.actions;

export default placesSlice.reducer;
