import React, { FC, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'moment/locale/pl';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import InputCheckbox from '../commons/Inputs/InputCheckbox';
import {
  downloadXlsxReport,
  fetchDSPPlacesListReport
} from '../../ducks/reports';
import { useTranslation } from 'react-i18next';

import { IInvestment } from '../../graphql/investments';


const DSPPlacesListReport : FC = () => {

  const dispatch : AppDispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector((state : RootState) => state.combinedReports.DSPPlacesListReport.isLoading);
  const { investmentsList } = useSelector((state : RootState) => state.globalInvestment);
  const [chosenInvestments, setChosenInvestments] = useState<IInvestment[]>([
    {
      id : -1,
      name : '',
      stages : [],
      isArchive : false
    }
  ]);
  const [investmentsIDs, setInvestmentsIDs] = useState<number[]>([]);
  const [stagesIDs, setStagesIDs] = useState<number[]>([]);

  const updateSelectedInvestments = useCallback((investment : IInvestment) => {
    const investmentIsSelected = investmentsIDs.includes(investment.id);
      if (investmentIsSelected) {
        return ([
          setChosenInvestments((prevState : IInvestment[]) => prevState.filter(inv => inv !== investment)),
          setInvestmentsIDs((prevState : number[]) => prevState.filter(inv => inv !== investment.id))
        ]);
      }
      setChosenInvestments((prevState : IInvestment[]) => [...prevState, investment]);
      setInvestmentsIDs((prevState : number[]) => [...prevState, investment.id]);
  }, [investmentsIDs]);

  const updateStages = useCallback((stage : number) => {
    setStagesIDs((prevState : number[]) => [...prevState, stage]);
  }, []);

  const generateDSPPlacesListReport = useCallback(() => {
    dispatch(fetchDSPPlacesListReport({ investmentsIDs, stagesIDs }))
      .then((response : any) => {
        if (response.payload?.DSPPlacesListReport) {
          return dispatch(downloadXlsxReport({
            content : response.payload.DSPPlacesListReport,
            filename : 'Lista-mieszkań.xlsx'
          }));
        }
        alert('Błąd podczas pobierania raportu');
      });
    }, [dispatch, investmentsIDs, stagesIDs ]);

  const handleClick = useCallback(() : void => {
    generateDSPPlacesListReport();
  }, [generateDSPPlacesListReport]);

  const [areAllInvestmentsSelected, setAreAllInvestmentsSelected] = useState(false);
  const handleAllInvestmentsClick = useCallback(() => {
    if (chosenInvestments.length >= investmentsList.length) {
      setChosenInvestments([]);
      setInvestmentsIDs([]);
      setAreAllInvestmentsSelected(false);
    } else {
      setChosenInvestments(investmentsList);
      setInvestmentsIDs(investmentsList.map(investment => investment.id));
      setAreAllInvestmentsSelected(true);
    }
  }, [chosenInvestments, investmentsList]);

  const handleAllStagesClick = useCallback((investment : IInvestment) => {
    const { stages } = investment;
    let copyOfStagesIDs : number[] = stagesIDs;
    stages.map(stage => stage.id).forEach((stageID) => {
      if (copyOfStagesIDs.includes(stageID)) {
        copyOfStagesIDs = copyOfStagesIDs.filter(oldStageID => oldStageID !== stageID);
      } else {
        copyOfStagesIDs = [...copyOfStagesIDs, stageID];
      }
    });
    setStagesIDs(copyOfStagesIDs);
  }, [stagesIDs]);

    return (
      <div className="container">
        <div className='pb-4 px-2'>
          <Button disabled={!investmentsIDs.length || isLoading} onClick={handleClick}>
            {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
          </Button>
        </div>
        <div className="column">
          <p className='px-3 pb-1'>{t('commons:actions.select-investment')}</p>
          <div className="d-flex row justify-content-start align-items-center mt-2">
            <div className='col-3'>
              <InputCheckbox
                id='all-investments'
                label='Wszystkie'
                customWidth={175}
                onClick={handleAllInvestmentsClick}
                defaultChecked={areAllInvestmentsSelected}
              />
            </div>
          {investmentsList && investmentsList.map((investment : IInvestment) => (
            <div className='col-3'>
              <InputCheckbox
                id={String(investment.id)}
                label={investment.name}
                key={investment.id}
                customWidth={175}
                onClick={() : void[]|undefined => updateSelectedInvestments(investment)}
                disabled={areAllInvestmentsSelected}
                defaultChecked={chosenInvestments.includes(investment)}
              />
            </div>
          ))}
          </div>
          <p className='px-3 pt-5 pb-2'>{t('commons:labels.select-stage')}</p>
          {chosenInvestments?.map((investment, index) => (
            investment.id > 0
              ? <div key={index}>
                  <p className='font-weight-bold px-3' key={`${investment.name}-${index}`}>{investment.name}</p>
                  <div className="d-flex row justify-content-start align-items-center mt-2">
                    <div className="col-3 pb-4">
                      <InputCheckbox
                        id={`all-${investment.name}-stages`}
                        label='Wszystkie'
                        customWidth={175}
                        onClick={() : void => handleAllStagesClick(investment)}
                      />
                    </div>
                    {investment.stages.length > 0 && investment.stages.map((stage, index) => (
                      <div className='col-3 pb-4'>
                        <InputCheckbox
                          id={stage.name}
                          label={stage.name}
                          customWidth={175}
                          key={`${stage.name}-${index + 1}`}
                          onClick={() : void => updateStages(stage?.id)}
                          defaultChecked={stagesIDs.includes(stage?.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              : null
          ))}
        </div>
      </div>
    );
};

export default DSPPlacesListReport;
