import { PayloadAction, createAsyncThunk, createSlice, createSelector as cs } from '@reduxjs/toolkit';
import ReminderService, { IAddNote, IAddNoteForOtherUser, IPaginatedRemindersParameters, IUpdateNote, IUserNote } from '../services/reminderService';
import FileService from '../services/fileService';
import { IContactInCustomersReminder, ICustomersReminder } from '../services/reminderService';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../constants/dates';
import { RootState } from '.';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface IPdfFile {
    content : string;
};

interface IReminderState {
    customersReminder : ICustomersReminder[];
    totalCustomerReminders : number;
    allNotes : IUserNote[];
    todayNotes : IUserNote[];
    notesByCustomerID : IUserNote[];
    areNotesDownloaded : boolean;
    reminderFile : IPdfFile | null;
    error : string | null;
}

const initialState : IReminderState = {
    customersReminder : [],
    totalCustomerReminders : 0,
    allNotes : [],
    todayNotes : [],
    notesByCustomerID : [],
    areNotesDownloaded : false,
    reminderFile : null,
    error : null
};

export const fetchPaginatedCustomersReminder = createAsyncThunk(
    'reminder/fetchPaginatedCustomersReminder',
    async (parameters : IPaginatedRemindersParameters) => {
        return ReminderService.getCustomersRemindersPaginated(parameters);
    }
);

export const fetchCustomersByDailyReminders = createAsyncThunk(
    'reminder/fetchCustomersByDailyReminders',
    async () => ReminderService.getCustomersByDailyCustomerReminder()
);

export const fetchNotesByCustomerID = createAsyncThunk(
    'reminder/fetchNotesByCustomerID',
    async (customerID : number) => {
      return ReminderService.getNotesByCustomerID(customerID);
    }
);

export const fetchNotesByUserID = createAsyncThunk(
  'reminder/fetchNotes',
  async (userID : string) => {
    return ReminderService.getNotesByUserID(userID);
  }
);

export const fetchReminderPDFFile = createAsyncThunk(
    'reminder/fetchReminderFile',
    async (userToken : string, thunkAPI) => {
        const response = await ReminderService.getReminderPDFFile(userToken);
        if (response?.reminder) {
            const today = moment().format(DATE_FORMAT_YYYYMMDD);
            const filename = `${today}-DziennePrzypomnienie.pdf`;
            FileService.downloadFile({ filename, content : response.reminder.content });
        }
        await thunkAPI.dispatch(setReminderFile(response));
        return response;
    }
);

export const addUserNote = createAsyncThunk(
    'reminder/addUserNote',
    async (note : IAddNote) => {
        return ReminderService.addNote(note);
    }
);

export const addNoteForOtherUser = createAsyncThunk(
    'reminder/addNoteForOtherUser',
    async (note : IAddNoteForOtherUser) => {
        return ReminderService.addNoteForOtherUser(note);
    }
);

export const updateUserNote = createAsyncThunk(
    'reminder/updateUserNote',
    async (parameters : IUpdateNote) => {
        return ReminderService.updateNote(parameters);
    }
);

export const deleteUserNote = createAsyncThunk(
    'reminder/deleteUserNote',
    async (noteID : number) => {
        return ReminderService.deleteNote(noteID);
    }
);

const reminderSlice = createSlice({
    name : 'reminder',
    initialState,
    reducers : {
        setReminderFile (state, action : PayloadAction<IPdfFile>) : void {
            state.reminderFile = action.payload;
        }
    },
    extraReducers : {
        [fetchPaginatedCustomersReminder.fulfilled.toString()] : (state, action) : void => {
            state.totalCustomerReminders = action.payload.paginateCustomersForReminder?.total ?? 0;
            state.customersReminder = action.payload.paginateCustomersForReminder?.customers ?? [];
        },
        [fetchCustomersByDailyReminders.fulfilled.toString()] : (state, action) : void => {
            state.customersReminder = action.payload.customersByDailyCustomerReminder;
        },
        [fetchNotesByCustomerID.fulfilled.toString()] : (state, action) : void => {
            state.notesByCustomerID = action.payload.notesByCustomerID;
        },
        [fetchNotesByUserID.fulfilled.toString()] : (state, action) : void => {
            state.allNotes = action.payload?.notesByUserID;
            const today = moment().format(DATE_FORMAT_YYYYMMDD);
            const todayNotes = action.payload.notesByUserID?.filter((note : IUserNote) => note?.reminderDate === today);
            state.todayNotes = todayNotes;
            state.areNotesDownloaded = true;
        },
        [addUserNote.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:addNote')));
        },
        [deleteUserNote.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:deleteNote')));
        },
        [updateUserNote.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:editNote')));
        }
    }
});

const customersReminder = (state : RootState) : ICustomersReminder[]  => state.reminder.customersReminder;
const username = (state : RootState) : string | undefined => state.globalUser.profile?.username;

export const checkReminderTraderInitials = cs(customersReminder, username, (reminders, username) => {
    const today = moment().format(DATE_FORMAT_YYYYMMDD);
    return () : ICustomersReminder[] => {

    const data = reminders.map((reminder) => {
      const contacts = reminder.contacts.filter((contact : IContactInCustomersReminder) => {
        return contact.traderInitials === username && contact.nextContactDate === today;
      });
      return { ...reminder, contacts };
    });
    return data.filter((item) => {
      return item.contacts?.length !== 0;
    });
  };
});

export const {
    setReminderFile
} = reminderSlice.actions;

export default reminderSlice.reducer;
