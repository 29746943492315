import React, { FunctionComponent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import IKInputGroup from './IKInputGroup';

import { IInputProps } from './InputTypes';


const InputCheckbox : FunctionComponent <IInputProps> = (
  props : IInputProps
) => {

  const { customWidth, defaultChecked, disabled, id, label, onClick } = props;

  return (
    <IKInputGroup id={id}>
      <InputGroup.Prepend>
        <InputGroup.Text style={{ width : customWidth ? `${customWidth}px` : '150px' }}>
          {/* readOnly is ignored, added regarding checked-defaultChecked issues. Instead of 'readOnly' use 'disabled' only. */}
          <Form.Check className="small d-flex align-items-center" checked={defaultChecked} disabled={disabled}
            id={id} label={label} onClick={onClick} readOnly type="checkbox" />
        </InputGroup.Text>
      </InputGroup.Prepend>
    </IKInputGroup>
  );
};

export default InputCheckbox;
