import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import moment from 'moment';

import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import { Button, Container } from 'react-bootstrap';
import InputDate from '../commons/Inputs/InputDate';
import SimpleInputCheckbox from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox';
import SimpleInputList from '../commons/Inputs/SimpleInputs/SimpleInputList';
import { IInvestment } from '../../graphql/investments';
import { IDSPCustomerConcludedContractsReport } from '../../services/reportsService';
import { downloadXlsxReport, fetchDSPCustomerConcludedContractsReport } from '../../ducks/reports';

const DSPCustomerConcludedContractsReport : FC = () => {
    const { t } = useTranslation();
    const dispatch : AppDispatch = useDispatch();
    const isLoading = useSelector((state : RootState) => state.combinedReports.DSPCustomerConcludedContractsReport.isLoading);
    const { investmentsList } = useSelector((state : RootState) => state.globalInvestment);
    const { usernamesList } = useSelector((state : RootState) => state.globalUser);

    const [fromDate, setFromDate] = useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [toDate, setToDate] = useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [chosenInvestments, setChosenInvestments] = useState<IInvestment[]>([]);
    const [chosenTraderInitials, setChosenTraderInitials] = useState<string[]>([]);

    const updateFromDate = useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setFromDate(formatedDate);
    }, []);

    const updateToDate = useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setToDate(formatedDate);
    }, []);

    const handleInvestmentClick = useCallback((clickedInvestment) => {
        const index = chosenInvestments.findIndex((investment : IInvestment) => investment.id === clickedInvestment.id);

        if (index === -1) {
            setChosenInvestments([...chosenInvestments, clickedInvestment]);
        } else {
            const copyOfInvestments = [...chosenInvestments];
            copyOfInvestments.splice(index, 1);
            setChosenInvestments(copyOfInvestments);
        }
    }, [chosenInvestments]);

    const updateTraderList = useCallback((e : any) : void => {
        const newTrader = e.target.value;
        if (!chosenTraderInitials.includes(newTrader)) {
            setChosenTraderInitials([...chosenTraderInitials, newTrader]);
        }
    }, [chosenTraderInitials]);

    const generateReport = useCallback(() => {
        const investmentIDs = chosenInvestments.map(investment => investment.id);
        const variables : IDSPCustomerConcludedContractsReport = {
            investmentIDs,
            fromDate,
            toDate,
            tradersInitials : chosenTraderInitials
        };

        dispatch(fetchDSPCustomerConcludedContractsReport(variables)).then((response : any) => {
            if (response.payload?.DSPCustomerConcludedContractsReport) {
                const parameters = {
                    content : response.payload.DSPCustomerConcludedContractsReport,
                    filename : 'Zainteresowani zawarte umowy.xlsx'
                };

                return dispatch(downloadXlsxReport(parameters));
            };
            alert('Błąd podczas pobierania raportu');
        });
    }, [chosenInvestments, chosenTraderInitials, dispatch, fromDate, toDate]);

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center">
            <div>
                <Button disabled={!chosenInvestments.length || isLoading} onClick={generateReport}>
                    {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
                </Button>
            </div>
            <p className="icr-header mt-4">{t('commons:actions.select-dates')}</p>
              <div className="d-flex justify-content-center align-items-center">
                  <InputDate id="1" onDayChange={updateFromDate} customWidth={300} text="Od daty" />
                  <InputDate id="2" onDayChange={updateToDate} customWidth={300} text="Do daty" />
              </div>
            <p className="icr-header mt-4">{t('commons:labels.choose-investment')}</p>
            <div className="d-flex row justify-content-start align-items-center mt-2">
                {
                    investmentsList.map((investment : IInvestment, index : number) => (
                      <div className='col-3'>
                        <SimpleInputCheckbox
                            label={`${index + 1}. ${investment.name}`}
                            key={`${index + 1}. ${investment.name}`}
                            additionalOnChange={() : void => handleInvestmentClick(investment)}
                            customLabelWidth={15}
                        />
                      </div>
                    ))
                }
            </div>
            <p className="icr-header mt-4">{t('commons:labels.choose-trader-initials')}</p>
            <div className="d-flex row justify-content-start align-items-center my-4">
              <SimpleInputList
                additionalOnChange={updateTraderList}
                label={String(t('commons:labels.trader-initials'))}
                options={[' ', ...usernamesList]}
              />
            </div>
        </Container>
    );
};

export default DSPCustomerConcludedContractsReport;
