import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IChanges, ICorrespondenceFileInput,  ICorrespondenceInput, ICorrespondenceQuery  } from '../services/correspondenceService';
import CorrespondenceService from '../services/correspondenceService';
import { RootState } from '.';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';
import FileService from '../services/fileService';


interface ICorrenspondencesState {
    correspondence : ICorrespondenceQuery;
    history : IChanges[];
}

const initialState : ICorrenspondencesState = {
    correspondence : { getContract : { correspondences : [] } },
    history : []
};

export const getAllCorrespondences = createAsyncThunk('correspondence/getAllCorrespondences', async () => {
    return CorrespondenceService.getAllCorrespondences();
});

export const getAllCorrespondencesById = createAsyncThunk('correspondence/getAllCorrespondencesById', async (id : number) => {
    return CorrespondenceService.getAllCorrespondencesById(id);
});

export const saveCorrespondence = createAsyncThunk('correspondence/saveConrespondence', async (correspondence : ICorrespondenceInput) => {
    return CorrespondenceService.saveConrespondence(correspondence);
});

export const saveCorrespondenceFile = createAsyncThunk('correspondence/saveCorrespondenceFile', async (correspondenceFile : ICorrespondenceFileInput) => {
    return CorrespondenceService.saveCorrespondenceFile(correspondenceFile);
});

export const updateCorrespondence = createAsyncThunk('correspondence/updateCorrespondence', async ({ correspondence, correspondenceID } : {correspondence : ICorrespondenceInput; correspondenceID : number}) => {
    return CorrespondenceService.updateCorrespondence({ correspondence, correspondenceID });
});

export const deleteCorrespondence = createAsyncThunk('correspondence/deleteCorrespondence', async (correspondenceID : number) => {
    return CorrespondenceService.deleteCorrespondence(correspondenceID);
});

export const updateCorrespondenceFile = createAsyncThunk('correspondence/updateCorrespondenceFile', async ({ correspondenceFile, correspondenceFileID } : { correspondenceFile : ICorrespondenceFileInput; correspondenceFileID : number }) => {
    return CorrespondenceService.updateCorrespondenceFile({ correspondenceFile, correspondenceFileID });
});

export const fetchCorrespondenceChanges = createAsyncThunk('events/fetchCorrespondenceChanges', async (arg, thunkAPI) => {
    const contractID = (thunkAPI.getState() as RootState).globalInvestment.chosenPlacesContract?.activeContracts[0]?.id as number;
    return CorrespondenceService.fetchCorrespondenceChanges(contractID);
});

export const getCorrespondenceFile = createAsyncThunk(
  'correspondence/getCorrespondenceFile',
  async (variables : {fileID : number; fileName : string}) => {
    const result = await CorrespondenceService.getCorrespondenceFile(variables.fileID);

    if (result && result.getCorrespondenceFile.content){
      FileService.downloadFileSavedByUser({ filename : variables.fileName, content : result.getCorrespondenceFile.content });
    }
  }
);

export const getEventFile = createAsyncThunk(
  'events/getEventFile',
  async (variables : {fileID : number; fileName : string}) => {
    const result = await CorrespondenceService.getEventFile(variables.fileID);

    if (result && result.getEventFile.content){
      FileService.downloadFileSavedByUser({ filename : variables.fileName, content : result.getEventFile.content });
    }
  }
);




const correspondencesSlice = createSlice({
    name : 'correspondences',
    initialState,
    reducers : {},
    extraReducers : {
        [getAllCorrespondencesById.fulfilled.toString()] : (state, action) : void => {
            state.correspondence = action.payload;
        },
        [saveCorrespondence.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:saveCorrespondence')));
            state.correspondence.getContract.correspondences = [...state.correspondence.getContract.correspondences, action.payload.saveCorrespondence];
        },
        [updateCorrespondence.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:editCorrespondence')));
            const updatedCorrespondence = action.payload.updateCorrespondence;
            const editedCorrespondenceIndex = state.correspondence.getContract.correspondences.findIndex(cor => cor.id === updatedCorrespondence.id);
            const index = editedCorrespondenceIndex >= 0 ? editedCorrespondenceIndex : 0;
            state.correspondence.getContract.correspondences[index] = updatedCorrespondence;
        },
        [deleteCorrespondence.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:deleteCorrespondence')));
            const deletedCorrespondenceID = action.meta.arg;
            const filteredCorespondences = state.correspondence.getContract.correspondences.filter(cor => cor.id !== deletedCorrespondenceID);
            state.correspondence.getContract.correspondences = filteredCorespondences;
        },
        [updateCorrespondenceFile.fulfilled.toString()] : (state, action) : void => {
            const updateCorrespondenceFile = action.payload.updateCorrespondenceFile;
            const editedCorrespondenceIndex = state.correspondence.getContract.correspondences.findIndex(cor => cor.id === updateCorrespondenceFile.id);
            const index = editedCorrespondenceIndex >= 0 ? editedCorrespondenceIndex : 0;
            state.correspondence.getContract.correspondences[index].correspondenceFile = updateCorrespondenceFile;
        },
        [saveCorrespondenceFile.fulfilled.toString()] : (state, action) : void => {
            const correspondenceFile = action.payload.saveCorrespondenceFile;
            const savedCorrespondenceIndex = state.correspondence.getContract.correspondences.findIndex(cor => cor.id === correspondenceFile.correspondenceID);
            state.correspondence.getContract.correspondences[savedCorrespondenceIndex].correspondenceFile = correspondenceFile;
        },
        [fetchCorrespondenceChanges.fulfilled.toString()] : (state, action) : void => {
            state.history = action.payload.getCorrespondencesChangesByContract;
        }
    }
});

export default correspondencesSlice.reducer;
