import { IPlaceContractsQuery } from './../graphql/currentPlaceContracts/currentPlaceContracts';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IInvestment, IStage } from '../graphql/investments';
import { IContract } from '../graphql/contracts/contracts';
import InvestmentService from '../services/investmentService';
import PlacesService, { PlacesPaginationProps } from '../services/placesService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface IGlobalInvestment {
  investmentsList : IInvestment[];
  chosenInvestment : IInvestment;
  chosenStage : IStage;
  amountOfPlacesContracts : number;
  placesContracts : IContract[];
  chosenPlacesContract : IPlaceContractsQuery | null;
  selectedRows : number[];
  isArchiveMode : boolean;
  isLoading : boolean;
}

const initialState : IGlobalInvestment = {
  chosenInvestment : { id : -1, name : 'Wybierz inwestycję z listy', stages : [], isArchive : true },
  investmentsList : [],
  placesContracts : [],
  amountOfPlacesContracts : 0,
  chosenStage : { id : -1, name : 'Wszystkie', street : '', investmentID : -1, buildings : [] },
  chosenPlacesContract : null,
  isArchiveMode : false,
  selectedRows : [],
  isLoading : false
};

export const fetchInvestmentsWithStagesAndBuildings = createAsyncThunk('investments/fetchInvestmentsWithStagesAndBuildings', async () => {
  return InvestmentService.getInvestmentsWithStagesAndBuildings();
});

export const fetchInvestmentsWithStagesOnly = createAsyncThunk('investments/fetchInvestmentsWithStagesOnly', async () => {
  return InvestmentService.getInvestmentsWithStagesOnly();
});

export const fetchPlacesContractsByInvestmentNameForMainTable = createAsyncThunk(
  'places/fetchPlacesContractsByInvestmentNameForMainTable', async (props : PlacesPaginationProps) => {
    return PlacesService.getPlacesByInvestmentNameForMainTable(props);
  }
);

export const addInvestment = createAsyncThunk('investments/addInvestment', async (variables : { investment : { name : string }}, thunkAPI) => {
  const response = await InvestmentService.addInvestment(variables);
  await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly());
  return response;
});

export const updateInvestment = createAsyncThunk('investments/updateInvestment', async (variables : { investment : { name : string }}, thunkAPI) => {
  const response = await InvestmentService.updateInvestment(variables);
  await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly());
  return response;
});

export const removeInvestment = createAsyncThunk('investments/removeInvestment', async (variables : { investmentID : number}, thunkAPI) => {
  const response = await InvestmentService.removeInvestment(variables);
  await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly());
  return response;
});

export const addInvestmentStage = createAsyncThunk('investments/addInvestmentStage', async (variables : { stage : { name : string; street : string; investmentID : number }}, thunkAPI) => {
  const response = await InvestmentService.addInvestmentStage(variables);
  await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly());
  return response;
});

export const updateInvestmentStage = createAsyncThunk('investments/updateInvestmentStage', async (variables : { stage : { name : string; street : string; investmentID : number }; stageID : number}, thunkAPI) => {
  const response = await InvestmentService.updateInvestmentStage(variables);
  await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly());
  return response;
});

export const removeInvestmentStage = createAsyncThunk('investments/removeInvestmentStage', async (variables : { stageID : number}, thunkAPI) => {
  const response = await InvestmentService.removeInvestmentStage(variables);
  await thunkAPI.dispatch(fetchInvestmentsWithStagesOnly());
  return response;
});

const globalInvestmentSlice = createSlice({
  name : 'globalInvestment',
  initialState,
  reducers : {
    setGlobalChosenInvestment (state, action) : void {
      if (state.chosenInvestment.id !== action.payload.id) {
        state.chosenInvestment =  action.payload;
        state.chosenStage = initialState.chosenStage;
      }
    },
    setInvestmentsList (state, action) : void {
      state.investmentsList = action.payload;
    },
    setChosenPlacesContract (state, action) : void {
      state.chosenPlacesContract = action.payload;
    },
    setGlobalChosenStage (state, action) : void {
      state.chosenStage = action.payload;
    },
    switchArchiveMode (state) : void {
      if (state.isArchiveMode && state.chosenInvestment.isArchive) {
        const firstNotArchivedInvestment = state.investmentsList.find(investment => !investment.isArchive) || state.investmentsList[0];
        state.chosenInvestment = firstNotArchivedInvestment;
      }
      state.isArchiveMode = !state.isArchiveMode;
    },
    setSelectedRows (state, action) : void {
      const selectedRowsIds = action.payload.map((row : any) => { return row.original.id; });
      state.selectedRows = selectedRowsIds;
    },
    clearPlacesContracts (state) : void {
      state.placesContracts = [];
    }
  },
  extraReducers : {
    [fetchInvestmentsWithStagesAndBuildings.fulfilled.toString()] : (state, action) : void => {
      const investments = action.payload.investments;
      const invSortedByName : IInvestment[] = investments.slice().sort((a : IInvestment, b : IInvestment) => (a.name > b.name) ? 1 : -1);
      invSortedByName.push({ id : 0, name : 'Umowy anulowane', stages : [], isArchive : false });
      const fetchedInvestmentListContainsPersistChosenInvestment = invSortedByName.find((investment : IInvestment) => investment.id === state.chosenInvestment.id && investment.name === state.chosenInvestment.name);
      const fetchedInvestmentListStagesContainsPersistChosenStage = fetchedInvestmentListContainsPersistChosenInvestment ? state.chosenStage.id === -1 ? true : fetchedInvestmentListContainsPersistChosenInvestment.stages.find(stage => stage.id === state.chosenStage.id && stage.name === state.chosenStage.name) : false;
      const fetchedInvestmentNotContainsPersistentChosenData = state.chosenInvestment.id === -1 || !fetchedInvestmentListContainsPersistChosenInvestment || !fetchedInvestmentListStagesContainsPersistChosenStage;
      if (fetchedInvestmentNotContainsPersistentChosenData) {
        state.chosenInvestment = invSortedByName.length > 0 ? invSortedByName[0] : initialState.chosenInvestment;
        state.chosenStage = initialState.chosenStage;
      } else if (!fetchedInvestmentNotContainsPersistentChosenData && fetchedInvestmentListContainsPersistChosenInvestment) {
        state.chosenInvestment = fetchedInvestmentListContainsPersistChosenInvestment;
      }
      state.investmentsList = invSortedByName;
    },
    [fetchInvestmentsWithStagesOnly.fulfilled.toString()] : (state, action) : void => {
      const investments = action.payload.investments;
      const invSortedByName : IInvestment[] = investments.slice().sort((a : IInvestment, b : IInvestment) => (a.name > b.name) ? 1 : -1);
      invSortedByName.push({ id : 0, name : 'Umowy anulowane', stages : [], isArchive : false });
      const fetchedInvestmentListContainsPersistChosenInvestment = invSortedByName.find((investment : IInvestment) => investment.id === state.chosenInvestment.id && investment.name === state.chosenInvestment.name);
      const fetchedInvestmentListStagesContainsPersistChosenStage = fetchedInvestmentListContainsPersistChosenInvestment ? state.chosenStage.id === -1 ? true : fetchedInvestmentListContainsPersistChosenInvestment.stages.find(stage => stage.id === state.chosenStage.id && stage.name === state.chosenStage.name) : false;
      const fetchedInvestmentNotContainsPersistentChosenData = state.chosenInvestment.id === -1 || !fetchedInvestmentListContainsPersistChosenInvestment || !fetchedInvestmentListStagesContainsPersistChosenStage;
      if (fetchedInvestmentNotContainsPersistentChosenData) {
        state.chosenInvestment = invSortedByName.length > 0 ? invSortedByName[0] : initialState.chosenInvestment;
        state.chosenStage = initialState.chosenStage;
      } else if (!fetchedInvestmentNotContainsPersistentChosenData && fetchedInvestmentListContainsPersistChosenInvestment) {
        state.chosenInvestment = fetchedInvestmentListContainsPersistChosenInvestment;
      }
      state.investmentsList = invSortedByName;
    },
    [fetchPlacesContractsByInvestmentNameForMainTable.fulfilled.toString()] : (state, action) : void => {
      state.placesContracts = action.payload.paginatedPlacesByInvestmentsNames.places;
      state.amountOfPlacesContracts = action.payload.paginatedPlacesByInvestmentsNames.total;
      state.isLoading = false;
    },
    [fetchPlacesContractsByInvestmentNameForMainTable.pending.toString()] : (state) : void => {
      state.isLoading = true;
    },
    [fetchPlacesContractsByInvestmentNameForMainTable.rejected.toString()] : (state) : void => {
      state.isLoading = false;
    },
    [addInvestment.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addInvestment')));
    },
    [addInvestmentStage.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addStage')));
    },
    [removeInvestment.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deleteInvestment')));
    },
    [removeInvestmentStage.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deleteStage')));
    },
    [updateInvestment.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editInvestment')));
    },
    [updateInvestmentStage.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editStage')));
    }
  }
});

export const {
  clearPlacesContracts,
  setGlobalChosenInvestment,
  setInvestmentsList,
  setGlobalChosenStage,
  setChosenPlacesContract,
  switchArchiveMode,
  setSelectedRows
} = globalInvestmentSlice.actions;

export default globalInvestmentSlice.reducer;

