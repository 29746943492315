import React, { FC } from 'react';
import './Reports.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';
import { fetchDNIForNotarialOfficeReport } from '../../ducks/reports';
import Report from './Report';

const NotarialOfficeReport  : FC = () => {
  const { DNIForNotarialOfficeReport } = useSelector((state : RootState) => state.reports);

  return (
    <Report reportData={DNIForNotarialOfficeReport}
      reportName='Raport-dla-kancelarii.xlsx'
      reduxAction={fetchDNIForNotarialOfficeReport}
    />
  );

};

export default NotarialOfficeReport ;
