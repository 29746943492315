import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';
import { IMarketingConsentInput } from '../graphql/marketingConsents';

export interface IUpdateMarketingConsent {
  marketingConsent : Partial<IMarketingConsentInput>;
  marketingConsentID : number;
}

export const updateMarketingConsent = ({
  marketingConsent,
  marketingConsentID
} : Partial<IUpdateMarketingConsent>) : Promise<any> => {
  const UPDATE_MARKETING_CONSENT = gql`
    mutation umc($marketingConsent: MarketingConsentInput!, $marketingConsentID: Float!) {
      updateMarketingConsent(
        marketingConsent: $marketingConsent
        marketingConsentID: $marketingConsentID
      ) {
        id
      }
    }
  `;

  return GraphqlService.send(UPDATE_MARKETING_CONSENT, { marketingConsent, marketingConsentID });
};

export default {
  updateMarketingConsent
};
