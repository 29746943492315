import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';
import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './HeaderSearch.scss';

export interface IHeaderSearch {
  onChange ? : (phrase : string) => void;
  onSubmit : (phrase : string) => void;
}

const HeaderSearch : FunctionComponent <IHeaderSearch> = (
  props : IHeaderSearch
) => {
  const { onChange, onSubmit } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [_phrase, setPhrase] = useState('');

  const delayedChange = useRef(debounce((phrase : string) : void => onChange && onChange(phrase), 500));

  const handleChange = (
    event : ChangeEvent <HTMLInputElement>
  ) : void => {
    setPhrase(event.target.value);
  };

  const handleSubmit = (
    event : FormEvent <HTMLButtonElement | HTMLFormElement>
  ) : void => {
    event.preventDefault();
    delayedChange.current.cancel();
    setPhrase(_phrase);
    onSubmit(_phrase);
    if (_phrase) {
      history.push('/search');
    }
  };

  useEffect(() => delayedChange.current(_phrase), [_phrase]);

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Form.Control
          className="searchPadding"
          placeholder={`${t('commons:labels.type-searched-keyword')}...`}
          value={_phrase}
          onChange={handleChange}
        />
        <InputGroup.Append>
          <Button className="searchButton" type="submit">
            <FontAwesomeIcon icon={faSearch} className="searchIcon" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default HeaderSearch;
