import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../commons/Logo/Logo';

const HeaderLogo : FunctionComponent = () => {
  return (
    <Link to="/">
      <Logo />
    </Link>
  );
};

export default HeaderLogo;
