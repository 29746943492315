import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const { REACT_APP_API_URL } = process.env;

const httpLink = createHttpLink({
  uri : REACT_APP_API_URL
});

const apiLink = setContext((request, { headers }) => {
  const appHeaders = {} as {
    authorization : string;
  };
  const token = localStorage.getItem('idp:token');

  if (token) {
    appHeaders.authorization = `Bearer ${token}`;
  }

  return {
    headers : {
      ...headers,
      ...appHeaders
    }
  };
});

export const apolloClient = new ApolloClient({
  link : apiLink.concat(httpLink),
  cache : new InMemoryCache(),
  defaultOptions : {
    query : {
      fetchPolicy : 'no-cache'
    },
    watchQuery : {
      fetchPolicy : 'no-cache'
    }
  }
});
