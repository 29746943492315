import * as React from 'react';
import {
  TableInstance,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable
  } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IndeterminateCheckbox from './IndeterminateCheckbox';

type useTableProps = {
 autoResetSelectedRows ?: boolean;
 columns : any[]; //TODO Types
 data : any[]; //TODO Types
 initialHiddenData ?: string[];
 isSelectAllCheckbox ?: boolean;
 noSelectRows ?: boolean;
 chosenRowIndex ?: number;
 defaultNumberOfRows ?: number;
};

const defaultColumn = {
 minWidth : 30,
 width : 30,
 maxWidth : 1500 };


export const useTableOptions = (props : useTableProps) : TableInstance<object> => {
 const { autoResetSelectedRows, columns, data, initialHiddenData, isSelectAllCheckbox, noSelectRows, chosenRowIndex,
  defaultNumberOfRows = 10   } = props;
 const tableOptions =  {
  autoResetSelectedRows : autoResetSelectedRows,
   columns,
   data,
   defaultColumn,
   initialState : {
    hiddenColumns : initialHiddenData || [''],
    selectedRowIds : { [chosenRowIndex as number] : true },
    pageSize : defaultNumberOfRows
    }
  };

 const useTableProps = useTable(
  tableOptions,
  useBlockLayout,
  useSticky,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  (hooks) => {
   !noSelectRows && hooks.visibleColumns.push(columns => [
     { id : 'selection',
       Header : ({ getToggleAllPageRowsSelectedProps }) : JSX.Element => (
        <div>
          {isSelectAllCheckbox && <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />}
        </div> ),
       Cell : ({ row }) : JSX.Element => (
         <div>
           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
         </div>),
       sticky : 'left' },
     ...columns]);});

  return useTableProps;
};
