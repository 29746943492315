import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Table.scss';

interface IBarForToggleElementsProps {
  arrowColor ? : string;
  barColor ? : string;
  customEventKey : string;
  isOpen ? : boolean;
  noOfNotesEvents ? : number;
  onClick ? : () => void;
  small ? : boolean;
  title ? : string;
}

const BarForToggleElements : FunctionComponent <IBarForToggleElementsProps> = (
  props : IBarForToggleElementsProps
) => {
  const { arrowColor = "#fff", barColor = "#707070", customEventKey, isOpen = false, noOfNotesEvents, onClick, small, title } = props;
  const { t } = useTranslation();

  useEffect(() => {
  }, [isOpen]);

  return (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={customEventKey}
      className="d-flex align-items-center hoverClickHand"
      onClick={onClick}
      style={{ border : 0, backgroundColor : barColor, color : arrowColor, fontSize : `${small ? '0.8em' : 'default'}`}}>
      <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} className="fontIcon"
        style={{ color : `${arrowColor}`, marginLeft : "0.1rem", marginRight : "0.1rem" }} />
      {isOpen ? t('commons:actions.collapse') : t('commons:actions.expand')} {title && title}
      {noOfNotesEvents && <span className="notes-counter">{noOfNotesEvents}</span>}
    </Accordion.Toggle>
  );
};

export default BarForToggleElements;
