import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export interface IContactInCustomersReminder {
    id : number;
    date : string;
    name : string;
    type : string;
    comment : string;
    traderInitials : string;
    nextContactName : string;
    nextContactComment : string;
    nextContactDate : string;
}

export interface ICustomersReminder {
    id : number;
    firstName : string;
    lastName : string;
    email : string;
    phone1 : string;
    phone2 : string;
    contacts : IContactInCustomersReminder[];
}

export interface IPaginatedRemindersParameters {
    perPage : number;
    page : number;
}

export const getCustomersRemindersPaginated = (parameters : IPaginatedRemindersParameters) : Promise<any> => {
    const PAGINATED_FETCH_CUSTOMERS_REMINDERS = gql`
        query($perPage: Float, $page: Float) {
            paginateCustomersForReminder(perPage: $perPage, page: $page) {
                total
                customers {
                    id
                    firstName
                    lastName
                    email
                    phone1
                    phone2
                    contacts {
                        id
                        date
                        name
                        type
                        comment
                        resignationReason
                        traderInitials
                        nextContactName
                        nextContactComment
                        nextContactDate
                        nextContactType
                        isSigned
                        isResignation
                    }
                }
            }
        }      
    `;

    return GraphqlService.send(PAGINATED_FETCH_CUSTOMERS_REMINDERS, parameters);
};

export const getCustomersByDailyCustomerReminder = () : Promise<any> => {
    const FETCH_CUSTOMERS_BY_DAILY_REMINDERS = gql`
        query {
            customersByDailyCustomerReminder {
                id
                firstName
                lastName
                email
                phone1
                phone2
                contacts {
                    id
                    date
                    name
                    type
                    comment
                    traderInitials
                    nextContactName
                    nextContactComment
                    nextContactDate
                    nextContactType
                }
            }
        }
    `;

    return GraphqlService.send(FETCH_CUSTOMERS_BY_DAILY_REMINDERS, {});
};

export interface IUserNote {
    id : number;
    content : string;
    publicationDate : string;
    reminderDate : string;
    myTasks : boolean;
    customerID ?: number;
}

export const getNotesByCustomerID = (customerID : number) : Promise<any> => {
  const FETCH_NOTES_BY_CUSTOMER_ID = gql`
    query getNotes($customerID: Float!) {
      notesByCustomerID(customerID: $customerID) {
        id
        content
        publicationDate
        reminderDate
        myTasks
        customerID
      }
    }
  `;

  return GraphqlService.send(FETCH_NOTES_BY_CUSTOMER_ID, { customerID });
};

export const getNotesByUserID = (userID : string) : Promise<any> => {
    const FETCH_NOTES_BY_USER_ID = gql`
        query getNotes($userID: String!) {
            notesByUserID(userID: $userID) {
                id
                content
                publicationDate
                reminderDate
                myTasks
                customerID
            }
        }
    `;

    return GraphqlService.send(FETCH_NOTES_BY_USER_ID, { userID });
};

const getReminderPDFFile = (userToken : string) : Promise<any> => {
    const FETCH_REMINDER_PDF = gql`
        query reminder($userToken : String!) {
            reminder(userToken : $userToken) {
                content
            }
        }
    `;

    return GraphqlService.send(FETCH_REMINDER_PDF, { userToken });
};

export interface IAddNote {
    content : string;
    publicationDate : string;
    reminderDate : string | null;
    myTasks : boolean;
    userID : string;
    customerID ?: number;
}

export interface IAddNoteForOtherUser extends IAddNote {
    traderInitials : string;
}

export const addNote = (note : IAddNote) : Promise<any> => {
    const ADD_NOTE = gql`
        mutation sNote($note: NoteInput!) {
            saveNote(note: $note) {
                id
                content
                publicationDate
                reminderDate
                myTasks
                userID
                customerID
            }
        }
    `;

    return GraphqlService.send(ADD_NOTE, { note });
};

export const addNoteForOtherUser = (note : IAddNoteForOtherUser) : any => {
    const ADD_NOTE_FOR_OTHER_USER = gql`
        mutation sNote($note: NoteInput!) {
            saveNote(note: $note) {
                id
                content
                publicationDate
                reminderDate
                myTasks
                userID
                customerID
                traderInitials
            }
        }
    `;

    return GraphqlService.send(ADD_NOTE_FOR_OTHER_USER, { note });
};

export interface INote extends IAddNote {
    id : number;
}

export interface IUpdateNote {
    noteID : number;
    note : IAddNote;
}

export const updateNote = (parameters : IUpdateNote) : Promise<any> => {
    const UPDATE_NOTE = gql`
        mutation updateNote($note: NoteInput!, $noteID: Float!) {
            updateNote(note: $note, noteID: $noteID) {
                id
            }
        }
    `;

    return GraphqlService.send(UPDATE_NOTE, parameters);
};

export const deleteNote = (noteID : number) : Promise<any> => {
    const DELETE_NOTE = gql`
        mutation deleteNode($noteID: Float!) {
            deleteNote(noteID: $noteID)
        }
    `;

    return GraphqlService.send(DELETE_NOTE, { noteID });
};

const mutations = {
    getCustomersByDailyCustomerReminder,
    getCustomersRemindersPaginated,
    getNotesByCustomerID,
    getNotesByUserID,
    getReminderPDFFile,
    addNote,
    addNoteForOtherUser,
    updateNote,
    deleteNote
};

export default mutations;
