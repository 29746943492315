import { KeycloakInitOptions } from 'keycloak-js';
import { intersection } from 'lodash-es';

export const hasRoles = (
  neededRoles : string[],
  userRoles : string[]
) : boolean => {
  return intersection(neededRoles, userRoles).length > 0;
};

export const idpEventHandler = (
  event : any,
  error : any
) : void => {
  console.log('idpEventHandler', event, error);
};

export const idpInitConfig : KeycloakInitOptions = {
  onLoad : 'login-required'
};

export const idpTokensHandler = (
  tokens : {
    idToken : string;
    refreshToken : string;
    token : string;
  }
) : void => {
  localStorage.setItem('idp:token', tokens.token);
};
