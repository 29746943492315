import React, { FunctionComponent, useMemo } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ToggleContainer from '../Containers/ToggleContainer';

import './Table.scss';

interface ISumArea {
  designedUsablePropertyArea : number;
  designedInnerPropertyArea : number;
  asBuildUsablePropertyArea : number;
  asBuildInnerPropertyArea : number;
  designedUsableUtilroomArea : number;
  designedInnerUtilroomArea : number;
  asBuildUsableUtilroomArea : number;
  asBuildInnerUtilroomArea : number;
}

interface IToggleSumBarProps {
  data ?: any[];
}

const sumAreas = (data ?: any[]) : ISumArea => {
    const designedUsablePropertyArea : number[] = [];
    const designedInnerPropertyArea : number[] = [];
    const asBuildUsablePropertyArea : number[] = [];
    const asBuildInnerPropertyArea : number[] = [];
    const designedInnerUtilroomArea : number[] = [];
    const designedUsableUtilroomArea : number[] = [];
    const asBuildInnerUtilroomArea : number[] = [];
    const asBuildUsableUtilroomArea : number[] = [];
    if (data && data.length) {
    data.map((item) => {
       designedUsablePropertyArea.push(item.designedUsablePropertyArea);
       designedInnerPropertyArea.push(item.designedInnerPropertyArea);
       asBuildUsablePropertyArea.push(item.asBuildUsablePropertyArea);
       asBuildInnerPropertyArea.push(item.asBuildInnerPropertyArea);
       designedInnerUtilroomArea.push(item.utilityRooms.designedInnerUtilroomArea);
       designedUsableUtilroomArea.push(item.utilityRooms.designedUsableUtilroomArea);
       asBuildInnerUtilroomArea.push(item.utilityRooms.asBuildInnerUtilroomArea);
       return asBuildUsableUtilroomArea.push(item.utilityRooms.asBuildUsableUtilroomArea);
    });
    return {
      designedUsablePropertyArea : designedUsablePropertyArea.reduce((prev : number, cur : number) => prev + cur) || 0, //PUL proj.
      designedInnerPropertyArea : designedInnerPropertyArea.reduce((prev : number, cur : number) => prev + cur) || 0 || 0, //PWL proj.
      asBuildUsablePropertyArea : asBuildUsablePropertyArea.reduce((prev : number, cur : number) => prev + cur) || 0, //PUL powyk.
      asBuildInnerPropertyArea : asBuildInnerPropertyArea.reduce((prev : number, cur : number) => prev + cur) || 0, //PWL powyk.
      designedUsableUtilroomArea : designedUsableUtilroomArea.reduce((prev : number, cur : number) => prev + cur) || 0, //PWPG proj.
      designedInnerUtilroomArea : designedInnerUtilroomArea.reduce((prev : number, cur : number) => prev + cur) || 0, //PUPG proj.
      asBuildUsableUtilroomArea : asBuildUsableUtilroomArea.reduce((prev : number, cur : number) => prev + cur) || 0, // PWPG powyk.
      asBuildInnerUtilroomArea : asBuildInnerUtilroomArea.reduce((prev : number, cur : number) => prev + cur) || 0 // PUPG powyk.
    };
  };
  return {
    designedUsablePropertyArea : 0,
    designedInnerPropertyArea : 0,
    asBuildUsablePropertyArea : 0,
    asBuildInnerPropertyArea : 0,
    designedUsableUtilroomArea : 0,
    designedInnerUtilroomArea : 0,
    asBuildUsableUtilroomArea : 0,
    asBuildInnerUtilroomArea : 0
  };
};


const ToggleSumBar : FunctionComponent<IToggleSumBarProps> = ({ data }) => {

  const { t } = useTranslation();
  const totals = useMemo(() => sumAreas(data), [data]);
  return (
    <ToggleContainer arrowColor='#000000' barColor='main-bg-color' customWidth={97.7} title={t('investment:labels.total-of-areas-in-investment')}>
      <>
      <ListGroup as={Col} lg={6} md={12} sm={12}>
        <ListGroup.Item as={Row} className="mx-0 ik2-bg-color font-weight-bold placeAreaClass">{t('investment:labels.sum-of-places-areas')}:</ListGroup.Item>
        <Row className="mx-0" >
          <ListGroup.Item as={Col} lg={3} sm={3} className="ik2-bg-color">
            Proj. (użyt.): {totals.designedUsablePropertyArea?.toLocaleString() || 0}
          </ListGroup.Item>
          <ListGroup.Item as={Col} lg={3} sm={3} className="ik2-bg-color">
            Proj. (wew.): {totals.designedInnerPropertyArea?.toLocaleString() || 0}
          </ListGroup.Item>
          <ListGroup.Item as={Col} lg={3} sm={3} className="ik2-bg-color">
            Powyko. (użyt.): {totals.asBuildUsablePropertyArea?.toLocaleString() || 0}
          </ListGroup.Item>
          <ListGroup.Item as={Col} lg={3} sm={3} className="ik2-bg-color">
            Powyko. (wew.): {totals.asBuildInnerPropertyArea?.toLocaleString() || 0}
          </ListGroup.Item>
        </Row>
      </ListGroup>
      <ListGroup as={Col} lg={5} md={12} sm={12}>
        <ListGroup.Item as={Row} className="ik2-bg-color font-weight-bold ">{t('investment:labels.sum-of-utility-rooms-areas')}:</ListGroup.Item>
        <Row>
          <ListGroup.Item as={Col} lg={4} sm={3} className="ik2-bg-color">
            Proj (wew.): {totals.designedUsableUtilroomArea?.toLocaleString() || 0}
          </ListGroup.Item>
          <ListGroup.Item as={Col} lg={4} sm={3} className="ik2-bg-color">
            Powyko. (wew.): {totals.asBuildInnerUtilroomArea?.toLocaleString() || 0}
          </ListGroup.Item>
          <ListGroup.Item as={Col} lg={4} sm={3} className="ik2-bg-color">
            Powyko. (użyt.): {totals.designedInnerUtilroomArea?.toLocaleString() || 0}
          </ListGroup.Item>
        </Row>
      </ListGroup>
      </>
    </ToggleContainer>
  );
};

export default ToggleSumBar;
