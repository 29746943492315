import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export interface IUserNameObject {
    username : string;
}

const fetchUserNamesList = () : any => {
    const FETCH_USERNAMES_LIST = gql`
        query {
            users {
                username
            }
        }
    `;

    return GraphqlService.send(FETCH_USERNAMES_LIST, {});
};

const mutations = {
    fetchUserNamesList
};

export default mutations;