import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import moment from 'moment';

import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import { Button, Container } from 'react-bootstrap';
import { IInvestment, IStage } from '../../graphql/investments';
import InputDate from '../commons/Inputs/InputDate';
import SimpleInputCheckbox from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox';
import { IDSPSummaryReport } from '../../services/reportsService';
import { downloadXlsxReport, fetchDSPSummaryReport } from '../../ducks/reports';

const DSPSummaryReport : FC = () => {
    const dispatch : AppDispatch = useDispatch();
    const { t } = useTranslation();
    const { investmentsList } = useSelector((state : RootState) => state.globalInvestment);
    const isLoading = useSelector((state : RootState) => state.combinedReports.DSPSummaryReport.isLoading);
    const [chosenInvestments, setChosenInvestments] = useState<IInvestment[]>([]);
    const [chosenStages, setChosenStages] = useState<IStage[]>([]);
    const [startDate, setStartDate] = useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [endDate, setEndDate] = useState(moment().format(DATE_FORMAT_YYYYMMDD));

    const updateStartDate = useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setStartDate(formatedDate);
    }, []);

    const updateEndDate = useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setEndDate(formatedDate);
    }, []);

    const handleInvestmentClick = useCallback((clickedInvestment) => {
        const index = chosenInvestments.findIndex((investment : IInvestment) => investment.id === clickedInvestment.id);

        if (index === -1) {
            setChosenInvestments([...chosenInvestments, clickedInvestment]);
        } else {
            const copyOfInvestments = [...chosenInvestments];
            copyOfInvestments.splice(index, 1);
            setChosenInvestments(copyOfInvestments);
        }

    }, [chosenInvestments]);

    const handleStageClick = useCallback((clickedStage) => {
        const index = chosenStages.findIndex((stage : IStage) => stage.id === clickedStage.id);

        if (index === -1) {
            setChosenStages([...chosenStages, clickedStage]);
        } else {
            const copyOfStages = [...chosenStages];
            copyOfStages.splice(index, 1);
            setChosenStages(copyOfStages);
        }

    }, [chosenStages]);

    const generateReport = useCallback(() => {
        const stagesIDs = chosenStages.map(stage => stage.id);
        const investmentsIDs = chosenInvestments.map(investment => investment.id);
        const variables : IDSPSummaryReport = {
            stagesIDs,
            investmentsIDs,
            startDate,
            endDate
        };

        dispatch(fetchDSPSummaryReport(variables)).then((response : any) => {
            if (response.payload?.DSPSummaryReport) {
                const parameters = {
                    content : response.payload.DSPSummaryReport,
                    filename : 'Raport zbiorczy DSP.xlsx'
                };

                return dispatch(downloadXlsxReport(parameters));
            }
            alert('Błąd podczas pobierania raportu');
        });
    }, [chosenStages, chosenInvestments, startDate, endDate, dispatch]);
    console.log(!chosenInvestments.length || isLoading);
    return (
        <Container className="d-flex flex-column justify-content-center align-items-center">
            <div>
                <Button disabled={!chosenInvestments.length || isLoading} onClick={generateReport}>
                    {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
                </Button>
            </div>
            <p className="icr-header mt-4">{t('commons:actions.select-dates')}</p>
            <div className="d-flex justify-content-center align-items-center">
                <InputDate id="1" onDayChange={updateStartDate} customWidth={300} text={t('commons:labels.from')} />
                <InputDate id="2" onDayChange={updateEndDate} customWidth={300} text={t('commons:labels.to')} />
            </div>
            <p className="icr-header mt-4">{t('commons:labels.choose-investment')}</p>
            <div className="d-flex row justify-content-start align-items-center mt-2">
              {investmentsList.map((investment : IInvestment, index : number) => (
                <div className='col-3'>
                  <SimpleInputCheckbox
                    label={`${index + 1}. ${investment.name}`}
                    key={`${index + 1}. ${investment.name}`}
                    additionalOnChange={() : void => handleInvestmentClick(investment)}
                    customLabelWidth={15}
                  />
                </div>
              ))}
            </div>
            {
                chosenInvestments.map((investment : IInvestment) => (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-4" key={`${investment.id} - ${investment.name}`}>
                        <p className="icr-header mt-4">{`${t('commons:labels.choose-stage-for-investment')}: ${investment.name}`}</p>
                        <div className="d-flex mt-2">
                            {
                                investment.stages.map((stage : IStage) => (
                                    <SimpleInputCheckbox
                                        label={`${stage.name}`}
                                        key={`${stage.name}`}
                                        additionalOnChange={() : void => handleStageClick(stage)}
                                        customLabelWidth={15}
                                    />
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </Container>
    );
};

export default DSPSummaryReport;
