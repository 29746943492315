import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Toast : React.FC = ( ) => (
    <ToastContainer />
);

export default Toast;

export const successToastNotify = ( title : string ) => toast.success(title, {
    position : 'top-right',
    autoClose : 5000,
    hideProgressBar : false,
    closeOnClick : true,
    pauseOnHover : true,
    draggable : true,
    progress : undefined
});

