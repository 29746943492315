import * as React from 'react';

const useCombinedRefs = (...refs : any[]) : React.MutableRefObject<any> => {
    const targetRef = React.useRef();
    React.useEffect(() => {
      refs.forEach((ref) => {
        if (!ref) {return;}
        if (typeof ref === 'function') {
          ref(targetRef.current);
        } else { ref.current = targetRef.current; }});
    }, [refs]);
    return targetRef;
  };

interface IIndeterminateCheckboxProps {
    indeterminate ?: boolean;
  }
  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IIndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref : React.Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null);
      const resolvedRef = useCombinedRefs(ref, defaultRef);
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
  
      return <input type='checkbox' ref={resolvedRef} {...rest} />;
    }
  );

export default IndeterminateCheckbox;