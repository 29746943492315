import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableContext } from './TableContext';
import { GenericPaginationProps } from '../../../services/interestedCustomerService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../ducks';
import { hasRoles } from '../../../services/idp';
import { DNKRoles } from '../../../ducks/globalUser';
import { refetchPagination } from './paginationSlice';

export type PaginationWithFetchProps = {
  defaultAmountOfRows ?: number;
  genericFetch : <T extends GenericPaginationProps>(props : T) => void;
  totalRows : number;
  additionalParameters ?: any;
};

const PaginationWithFetch : React.FC<PaginationWithFetchProps> = ({
  defaultAmountOfRows = 10,
  genericFetch,
  totalRows,
  additionalParameters = null
}) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state : RootState) => state.globalUser);
  const isDNKCustomer = hasRoles(DNKRoles, roles);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { refetch } = useSelector(
    (state : RootState) => state.pagination
  );
  const {
    state : { pageSize },
    setPageSize
  } = useTableContext();

  const numberOfPages = useMemo(() => Math.ceil(totalRows / pageSize), [
    pageSize,
    totalRows
  ]);

  const amountOfRows = useMemo(() : number[] => {
    let standardOptions = [5, 10, 20];
    if (!standardOptions?.includes(defaultAmountOfRows)) {
      standardOptions = [defaultAmountOfRows, ...standardOptions];
    }
    return standardOptions;
  }, [defaultAmountOfRows]);

  const handleChangePageSizeClick = useCallback(
    (e) : void => {
      setPageSize(Number(e.target.value));
    },
    [setPageSize]
  );

  const handleGoToFirstPageClick = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const handleGoToPreviousPageClick = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const handleLastPageClick = useCallback(() => {
    setCurrentPage(numberOfPages);
  }, [numberOfPages]);

  const handleNextPageClick = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const cancelAllRefreshes = useCallback(() => {
    dispatch(refetchPagination(false));
  }, [dispatch]);

  useEffect(() => {
    if (refetch) {
      dispatch(genericFetch({ ...additionalParameters, page : currentPage, perPage : pageSize, isDNKCustomer, isTypeService : isDNKCustomer }));
      cancelAllRefreshes();
    }
    // TODO : Figure out better solution for dependency additionalParameters?.investmentName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalParameters?.investmentName, additionalParameters?.endDate, additionalParameters?.startDate, additionalParameters?.stageID, additionalParameters?.isBlueList, additionalParameters?.keyword, cancelAllRefreshes, currentPage, dispatch, genericFetch, isDNKCustomer, numberOfPages, pageSize, refetch]);

  useEffect(() => {
    let page = currentPage;
    if (page > numberOfPages) {
      setCurrentPage(1);
      page = 1;
    }
    dispatch(
      genericFetch({
        ...additionalParameters,
        page,
        perPage : pageSize,
        isDNKCustomer,
        isTypeService : isDNKCustomer
      })
    );
    // TODO : Figure out better solution for dependency additionalParameters?.investmentName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalParameters?.investmentName, additionalParameters?.endDate, additionalParameters?.startDate, additionalParameters?.stageID, additionalParameters?.isBlueList, additionalParameters?.keyword, currentPage, dispatch, genericFetch, isDNKCustomer, numberOfPages, pageSize, refetch]);

  return (
    <div className="d-flex align-items-center justify-content-end pagination mx-4">
      <div className="mx-1">
        <button
          className="py-2"
          onClick={handleGoToFirstPageClick}
          disabled={currentPage === 1}
          style={{ backgroundColor : '#ffffff', border : '0' }}
        >
          {'«'}
        </button>
        <button
          className="py-2"
          onClick={handleGoToPreviousPageClick}
          disabled={currentPage === 1}
          style={{ backgroundColor : '#ffffff', border : '0' }}
        >
          {'◄'}
        </button>
      </div>
      <div className="mx-1">
        <select
          className="py-2 px-1 mx-2"
          onChange={handleChangePageSizeClick}
          style={{
            backgroundColor : '#ffffff',
            border : '1px solid #dedede',
            height : '40px'
          }}
          value={pageSize}
        >
          {amountOfRows.map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize} {t('commons:labels.of-rows')}
            </option>
          ))}
        </select>
        <span className="mx-2">
          {t('commons:labels.page')}
          <strong className="px-1" style={{ fontWeight : 'initial' }}>
            <span>{currentPage}</span> {t('commons:labels.from-2')}{' '}
            {numberOfPages}
          </strong>
        </span>
      </div>
      <div className="mx-1">
        <button
          className="py-2"
          disabled={currentPage === numberOfPages}
          onClick={handleNextPageClick}
          style={{ backgroundColor : '#ffffff', border : '0' }}
        >
          {'►'}
        </button>
        <button
          className="py-2"
          disabled={currentPage === numberOfPages}
          onClick={handleLastPageClick}
          style={{ backgroundColor : '#ffffff', border : '0' }}
        >
          {'»'}
        </button>
      </div>
    </div>
  );
};

export default PaginationWithFetch;
