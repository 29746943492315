import React, { FunctionComponent, useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import BarForToggleElements from '../../commons/Table/BarForToggleElements';

interface IToggleContainerProps {
  arrowColor ? : string;
  barColor ? : string;
  children : JSX.Element;
  customWidth ? : number;
  defaultShow ? : boolean;
  ignoreFocusOut ? : boolean;
  noOfNotesEvents ? : number;
  small ? : boolean;
  title ? : string;
}

export const ToggleContainer : FunctionComponent <IToggleContainerProps> = (props : IToggleContainerProps) => {
  const { arrowColor, barColor, children, customWidth, defaultShow = false, ignoreFocusOut, noOfNotesEvents, small, title } = props;

  const [isOpen, setIsOpen] = useState(defaultShow);
  const defaultActiveKey = defaultShow ? '1' : '0';

  const handleOnClick = () : void => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  /* TODO : refactor for ToggleContainer width */
  return (
    <Accordion activeKey={ignoreFocusOut ? defaultActiveKey : (isOpen ? '1' : '0')} defaultActiveKey={defaultActiveKey} >
      <BarForToggleElements arrowColor={arrowColor} barColor={barColor} customEventKey='1' isOpen={isOpen}
        noOfNotesEvents={noOfNotesEvents} onClick={handleOnClick} small={small} title={title} />
      <Accordion.Collapse eventKey='1' className='ik2-light-color-1' style={{ position : 'absolute', width : customWidth ? `${customWidth}%` : '100%', zIndex : 1000 }}>
        <Card.Body>
          {children}
        </Card.Body>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ToggleContainer;
