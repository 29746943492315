import { difference } from 'lodash-es';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { hasRoles } from '../../services/idp';

import { USER_ROLES } from '../../constants/user';

import { IPlaceContractsQuery } from '../../graphql/currentPlaceContracts/currentPlaceContracts';
import { IGarageGroundPlanFile } from '../../services/garagesService';
import ReduxModal from '../commons/Modals/ReduxModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';

interface IPotentialGarageUnit {
  id : number;
  garage : IPotentialGarage;
}

interface IPotentialGarage {
  id : number;
  name : string;
  groundPlans : IGarageGroundPlanFile[];
}

interface IAppActionsProps {
  contractValidation ? : 'cancelledContracts' | 'archivalContracts' | undefined;
  placeOnly ? : boolean;
  selectedPlace ?: IPlaceContractsQuery;
}

const AppActions : FunctionComponent <IAppActionsProps> = (props : IAppActionsProps) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state : RootState) => state.globalUser);
  const { contractValidation, placeOnly, selectedPlace } = props;
  const contractAccessRoles = difference(USER_ROLES, ['DKO', 'DPP']);
  const customerAccessRoles = difference(USER_ROLES, ['DPP']);
  const priceListAccessRoles = difference(USER_ROLES, ['DAR', 'DKO', 'DPP']);
  const validContractLink = '/valid-contract';
  const cancelledContractLink = '/cancelled-contract';
  const archivalContractLink = '/cancelled-contract';
  const hasPlaceActiveContract = selectedPlace?.activeContracts?.length;

  const isNoContract = Boolean(!(hasPlaceActiveContract));

  const downloadGroundPlan = (passedName ?: string, passedContent ?: string) : void => {
    const fileName = passedName ? passedName : selectedPlace?.groundPlan ? selectedPlace?.groundPlan.name : '';
    const fileContent = passedContent ? passedContent : selectedPlace?.groundPlan ? selectedPlace?.groundPlan.content : '';
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = fileContent;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
    toggleSelectGroundPlanModal();
  };

  const getGroundPlansOfGaragesRelatedToPlace = () : IGarageGroundPlanFile[] => {
    const potentialGaragesInActiveContracts = [selectedPlace?.activeContracts[0]?.parkingPlaces, selectedPlace?.activeContracts[0]?.utilityRooms];
    const potentialGaragesRelatedToPlace = [selectedPlace?.parkingPlaces, selectedPlace?.utilityRooms];

    const foundedGarages = potentialGaragesInActiveContracts.map((potentialPlace, index) => {
      let garages : IPotentialGarage[] = [];

      garages = (potentialPlace as any)?.map((unit : IPotentialGarageUnit) => unit.garage);
      if (garages && !garages.length) {
        garages = (potentialGaragesRelatedToPlace[index] as any).map((unit : any) => unit.garage);
      };

      return garages;
    });

    const garages = _.uniqBy(foundedGarages.flat(), 'id');
    if (garages && garages[0] != null){
      return garages.flatMap((garage : IPotentialGarage) => garage.groundPlans);
    } else {
      return [];
    }
  };

  const [isSelectGroundPlanModalVisible, setIsSelectGroundPlanModalVisible] = useState(false);
  const toggleSelectGroundPlanModal = useCallback(() => {
    setIsSelectGroundPlanModalVisible(!isSelectGroundPlanModalVisible);
  }, [setIsSelectGroundPlanModalVisible, isSelectGroundPlanModalVisible]);
  const selectGroundPlanModalBody = (
    <Form>
      {
        getGroundPlansOfGaragesRelatedToPlace().map(({ name, content, id } : IGarageGroundPlanFile) => (
          <Col lg={12} md={12} sm={12} className="d-flex justify-content-md-center">
            <Button key={id} onClick={() : void => downloadGroundPlan(name, content)}>
              {name}
            </Button>
          </Col>
        ))
      }
    </Form>
  );

  return (
    <Row className="d-flex justify-content-md-center">
      {selectedPlace && (
        <>
        <Button className="ik2-main-button-color " disabled={isNoContract}>
          <Link className={isNoContract ? 'ik2-link-inside-button' : 'ik2-main-button-color'} to={ isNoContract
              ? '/' : `${contractValidation === 'cancelledContracts'
              ? String(cancelledContractLink)
              : contractValidation === 'archivalContracts'
                ? String(archivalContractLink)
                : String(validContractLink)}/place`}>
            {t('commons:menu.place')}</Link>
        </Button>
        {hasRoles(customerAccessRoles, roles) && (
          <Button className="ik2-main-button-color" disabled={isNoContract}>
            <Link className={isNoContract ? 'ik2-link-inside-button' : 'ik2-main-button-color'} to={isNoContract
              ? '/' : `${contractValidation === 'cancelledContracts'
              ? String(cancelledContractLink)
              : String(validContractLink)}/customer`}>
              {t('commons:menu.customer')}</Link>
          </Button>)}
        {hasRoles(contractAccessRoles, roles) && (
          <Button disabled={isNoContract} className="ik2-main-button-color">
            <Link className={isNoContract ? 'ik2-link-inside-button' : 'ik2-main-button-color'} to={
              isNoContract
              ? '/'
              : contractValidation === 'cancelledContracts'
                ? `${String(cancelledContractLink)}/contract`
                : `${String(validContractLink)}/contract`

            }>
              {t('commons:menu.contract')}</Link>
          </Button>)}
        </>)}
      {!placeOnly && (
        <>
        <Button className="ik2-main-button-color " onClick={() : void => downloadGroundPlan()} disabled={selectedPlace?.groundPlan === null}>
          {t('commons:menu.plan')}
        </Button>
        <Button className="ik2-main-button-color " onClick={toggleSelectGroundPlanModal} disabled={!getGroundPlansOfGaragesRelatedToPlace().length}>
          {t('commons:menu.halls-plans')} ({getGroundPlansOfGaragesRelatedToPlace().length})
        </Button>
        {hasRoles(priceListAccessRoles, roles) && (
          <Button className="ik2-main-button-color ">
            <Link className="ik2-main-button-color" to="/">{t('commons:menu.price-list')}</Link>
          </Button>)}
        </>)}
        <ReduxModal
          body={selectGroundPlanModalBody}
          cancelAction={toggleSelectGroundPlanModal}
          onHide={toggleSelectGroundPlanModal}
          show={isSelectGroundPlanModalVisible}
          title={t('commons:actions.download-ground-plan')}
        />
    </Row>
  );
};

export default AppActions;
