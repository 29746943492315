import { GraphQLClient } from 'graphql-request';

import { keycloak } from '../components/App/App';

const { REACT_APP_API_URL } = process.env;

const client = new GraphQLClient(REACT_APP_API_URL || 'http://localhost:3001/graphql');

const GraphqlService = {
    send : (query : any, variables : any, requestHeaders ?: any) : Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const defaultHeaders = {
                'token' : keycloak.token
            };
            client.setHeaders(requestHeaders ? { ...requestHeaders, ...defaultHeaders } : defaultHeaders);
                client.request(query, variables)
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    }
};

export default GraphqlService;
