import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../ducks';
import { IGarageGroundPlanFile } from '../../../services/garagesService';
import ReduxModal from '../Modals/ReduxModal';

interface IPotentialGarageUnit {
 id : number;
 garage : IPotentialGarage;
}

interface IPotentialGarage {
 id : number;
 name : string;
 groundPlans : IGarageGroundPlanFile[];
}

const GroundPlanButton : React.FC = () => {
 const { chosenPlacesContract } = useSelector((state : RootState) => state.globalInvestment);
 const [isSelectGroundPlanModalVisible, setIsSelectGroundPlanModalVisible] = React.useState(false);
 const { t } = useTranslation();

 const toggleSelectGroundPlanModal = React.useCallback(() => {
  setIsSelectGroundPlanModalVisible(!isSelectGroundPlanModalVisible);
}, [setIsSelectGroundPlanModalVisible, isSelectGroundPlanModalVisible]);

 const downloadGroundPlan = React.useCallback((passedName ?: string, passedContent ?: string) : void => {
  const fileName = passedName ? passedName : chosenPlacesContract?.groundPlan ? chosenPlacesContract?.groundPlan.name : '';
  const fileContent = passedContent ? passedContent : chosenPlacesContract?.groundPlan ? chosenPlacesContract?.groundPlan.content : '';
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = fileContent;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();
}, [chosenPlacesContract]);

// eslint-disable-next-line react-hooks/exhaustive-deps
const garages = React.useMemo(() => chosenPlacesContract?.building?.garages ?? [], [chosenPlacesContract?.building?.garages]);
const garagesPlansNumber = garages?.map(garage => garage?.groundPlans?.length) ;
const sumGaragesPlan = garagesPlansNumber.length ? garagesPlansNumber?.reduce((prev, cur) => prev + cur) : 0;

const selectGroundPlanModalBody = React.useMemo(() => (
 <>
   {garages?.map((garage : any) => (
      <div key={garage?.id}>
        <p>Nazwa hali: {garage?.name}</p>
        {garage?.groundPlans?.map((groundPlan : any) => (
          <Col lg={12} md={12} sm={12} className="d-flex justify-content-md-center" key={`${groundPlan?.id} - ${groundPlan?.name}`}>
            <Button key={groundPlan?.id} onClick={() : void => downloadGroundPlan(groundPlan?.name, groundPlan?.content)}>
              {groundPlan?.name}
            </Button>
          </Col>
        ))}
     </div>
     ))}
 </>
), [downloadGroundPlan, garages]);

 return (
  <>
  <Button
    className="ik2-main-button-color "
    onClick={() : void => downloadGroundPlan()}
    disabled={!chosenPlacesContract?.groundPlan}>
  {t('commons:menu.plan')}
 </Button>
   <Button
    className="ik2-main-button-color"
    onClick={toggleSelectGroundPlanModal}
    disabled={sumGaragesPlan <= 0}
    >
   {t('commons:menu.halls-plans')} ({sumGaragesPlan})
   </Button>
   <ReduxModal
      body={selectGroundPlanModalBody}
      cancelAction={toggleSelectGroundPlanModal}
      onHide={toggleSelectGroundPlanModal}
      show={isSelectGroundPlanModalVisible}
      title={t('commons:actions.download-ground-plan')}
   />
  </>
 );
};

export default GroundPlanButton;
