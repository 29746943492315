import React, { FunctionComponent } from 'react';
import './Reports.scss';
import {  useSelector } from 'react-redux';
import {  RootState } from '../../ducks';
import { fetchDKOExecutionStatusReport } from '../../ducks/reports';
import Report from './Report';

const DKOExecutionStatusReport : FunctionComponent = () => {
  const { DKOExecutionStatusReport } = useSelector((state : RootState) => state.reports);

 return <Report reportName="Status-wykonania.xlsx" reportData={DKOExecutionStatusReport} reduxAction={fetchDKOExecutionStatusReport} />;
};

export default DKOExecutionStatusReport;