import React from 'react';

import { IContract } from '../../graphql/contracts/contracts';
import { IContractCustomerCompany } from '../../graphql/contractCustomerCompany';
import { IContractCustomer } from '../../graphql/contractCustomers';
import { IPlace } from '../../graphql/places';

import { IParkingPlace } from '../../graphql/parkingPlaces';

interface MainTableColumnProps {
  getActiveContract : (contracts : IContract[]) => IContract | undefined;
}

export const getMainTableColumns = ({ getActiveContract } : MainTableColumnProps) : any[] => {
  return [
    {
      Header : 'L.p.',
      accessor : 'orderNo',
      width : '50',
      sticky : 'left',
      Cell : (row : any) : any => {
        return <div>{row.data.length - row.row.index}</div>;
      }
    },
    {
      Header : 'Kod inwestycji',
      accessor : 'investment.name',
      width : '100',
      sticky : 'left'
    },
    {
      Header : 'Nr bud. lokalu',
      accessor : 'placeCode',
      width : '150',
      sticky : 'left'
    },
    {
      Header : 'Liczba pokoi',
      accessor : 'roomCount',
      width : '75'
    },
    {
      Header : 'Ulica',
      accessor : 'stage.street',
      width : '150'
    },
    {
      Header : 'Nr admin.',
      accessor : (a : {
        administrativeCode1 : string; administrativeCode2 : string;
      }) : string => (`${a.administrativeCode1} / ${a.administrativeCode2}`),
      width : '150'
    },
    {
      Header : 'PUL proj.',
      accessor : (a : { designedUsablePropertyArea : string }) : string => {
        return a.designedUsablePropertyArea.toLocaleString();
      },
      fullName : 'Pow. użyt. projekt.',
      width : '75'
    },
    {
      Header : 'PWL proj.',
      accessor : (a : { designedInnerPropertyArea : string }) : string => {
        return a.designedInnerPropertyArea.toLocaleString();
      },
      fullName : 'Pow. wew. projekt.',
      width : '75'
    },
    {
      Header : 'PUL powyk.',
      accessor : (a : { asBuildUsablePropertyArea : string }) : string => {
        return a.asBuildUsablePropertyArea.toLocaleString();
      },
      fullName : 'Pow. użyt. powyk.',
      width : '75'
    },
    {
      Header : 'PWL powyk.',
      accessor : (a : { asBuildInnerPropertyArea : string }) : string => {
        return a.asBuildInnerPropertyArea.toLocaleString();
      },
      fullName : 'Pow. wew. powyk.',
      width : '75'
    },
    {
      Header : 'Liczba nabywców',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        const contractCustomerCompanies = contract?.contractCustomerCompanies;
        const contractCustomers = contract?.contractCustomers;

        const companyOwners = contractCustomerCompanies?.filter((customer : IContractCustomerCompany) => {
          return !customer.isAgentOfMainCustomer;
        }) || [];
        const owners = contractCustomers?.filter((customer : IContractCustomer) => {
          return !customer.isAgentOfMainCustomer &&
            !customer.isAgentOfCustomerSpouse && !customer.isSpouseOfMainCustomer;
        }) || [];

        if (owners.length){
          return String(owners?.length);
        } else if (companyOwners.length) {
          return String(companyOwners?.length);
        } else {
          return '';
        }
      },
      width : '100'
    },
    {
      id : 'firstName1',
      Header : 'Imię / nazwa spółki (Klient 1.)',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        const owner = contract?.contractCustomers.filter(c => (
          !c.isSpouseOfMainCustomer && !c.isAgentOfCustomerSpouse && !c.isAgentOfMainCustomer
        ));
        const companyOwner = contract?.contractCustomerCompanies;
        const spouse = contract?.contractCustomers.filter(c => c.isSpouseOfMainCustomer);
        const ownerFirstName = owner?.[0]?.firstName ?? companyOwner?.[0]?.businessName ?? '';
        const spouseFirstName = owner?.[0]?.maritalStatus?.startsWith('2') ? spouse?.[0]?.firstName : '';
        return contract?.contractCustomerCompanies[0]?.businessName ||
          `${ownerFirstName}${spouseFirstName ? ', ' + spouseFirstName : ''}` ||
          ''
        ;
      },
      width : '150'
    },
    {
      id : 'lastName1',
      Header : 'Nazwisko (Klient 1.)',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        const owner = contract?.contractCustomers.filter(c => !c.isSpouseOfMainCustomer &&
          !c.isAgentOfCustomerSpouse &&
          !c.isAgentOfMainCustomer);
        const spouse = contract?.contractCustomers.filter(c => c.isSpouseOfMainCustomer);
        const ownerLastName = owner?.[0]?.lastName ?? '';
        const spouseLastName = owner?.[0]?.maritalStatus?.startsWith('2') ? spouse?.[0]?.lastName : '';;

        return `${ownerLastName}${spouseLastName ? ', ' + spouseLastName : ''}` || '';
      },
      width : '180'
    },
    {
      id : 'firstName2',
      Header : 'Imię / nazwa spółki (Klient 2.)',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        const owner = contract?.contractCustomers.filter(c => (
          !c.isSpouseOfMainCustomer && !c.isAgentOfCustomerSpouse && !c.isAgentOfMainCustomer
        ));
        const companyOwner = contract?.contractCustomerCompanies;
        const spouse = contract?.contractCustomers.filter(c => c.isSpouseOfMainCustomer) ?? [];
        const ownerFirstName = owner?.[1]?.firstName ?? companyOwner?.[1]?.businessName ?? '';
        const spouseFirstName = owner?.[1]?.maritalStatus?.startsWith('2') ? (spouse.length >= 2 ? spouse?.[1]?.firstName : (spouse.length === 1 ? spouse?.[0]?.firstName : '')) : '';

        return contract?.contractCustomerCompanies[1]?.businessName ||
          `${ownerFirstName}${spouseFirstName ? ', ' + spouseFirstName : ''}` ||
          ''
        ;
      },
      width : '150'
    },
    {
      id : 'lastName2',
      Header : 'Nazwisko (Klient 2.)',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        const owner = contract?.contractCustomers.filter(c => !c.isSpouseOfMainCustomer &&
          !c.isAgentOfCustomerSpouse &&
          !c.isAgentOfMainCustomer);
        const spouse = contract?.contractCustomers.filter(c => c.isSpouseOfMainCustomer) ?? [];
        const ownerLastName = owner?.[1]?.lastName ?? '';
        const spouseLastName = owner?.[1]?.maritalStatus?.startsWith('2') ? (spouse.length >= 2 ? spouse?.[1]?.lastName : (spouse.length === 1 ? spouse?.[0]?.lastName : '')) : '';

        return `${ownerLastName}${spouseLastName ? ', ' + spouseLastName : ''}` || '';
      },
      width : '180'
    },
    {
      Header : 'Nr miejsca postoj.',
      accessor : (a : IPlace) : string => {
        let parkingPlacesFromContract = [] as string[];
        let parkingPlacesFromPlace = [] as string[];
        if (a.activeContracts.length > 0 && a.activeContracts[0].parkingPlaces){
          parkingPlacesFromContract = a.activeContracts[0].parkingPlaces.map((parkingPlace : IParkingPlace) => parkingPlace.name);
        }
        if (a.parkingPlaces.length > 0){
          parkingPlacesFromPlace = a.parkingPlaces.map((parkingPlace : IParkingPlace) => parkingPlace.name);
        }
        const fromPlace = parkingPlacesFromPlace.filter(p => p != null).reduce((prev, next) => {
          if (prev){
            return prev + ',' + next;
          } else {
            return next;
          }
        }, '');
        const fromContract = parkingPlacesFromContract.filter(p => p != null).reduce((prev, next) => {
          if (prev){
            return prev + ',' + next;
          } else {
            return next;
          }
        }, '');
        return fromPlace + fromContract;
      },
        width : '100'
    },
    {
      Header : 'Nr pom. gosp.',
      accessor : (a : IPlace) : string => {
        const utilityRoomsByPlace = a.utilityRooms;
        const utilityRoomsByContract = a.activeContracts && a.activeContracts[0] ? a.activeContracts[0].utilityRooms ?? [] : [];

        const fromPlace = utilityRoomsByPlace?.length && utilityRoomsByPlace?.map((ur, i) => {
          if (i > 0){
            return  String(' ' + ur?.number);
          } else {
            return String(ur?.number ?? '');
          }
        }, '');

        const fromContract = utilityRoomsByContract?.length && utilityRoomsByContract?.map((ur, i) => {
          if (i > 0){
            return  String(' ' + ur?.number);
          } else {
            return String(ur?.number ?? '');
          }
        }, '');

        return `${fromPlace !== 0 ? fromPlace : ''}${fromContract !== 0 ? fromContract : ''}`;
      },
      width : '100'
    },
    {
      Header : 'PWPG proj.',
      accessor : (a : IPlace) : string => {
        const utilityRoomsByPlace = a.utilityRooms;
        const utilityRoomsByContract = a.activeContracts && a.activeContracts[0] ? a.activeContracts[0].utilityRooms ?? [] : [];

        if (utilityRoomsByPlace.length > 0){
          return String(utilityRoomsByPlace[0].designedInnerUtilRoomArea);
        } else if (utilityRoomsByContract.length > 0){
          return String(utilityRoomsByContract[0].designedInnerUtilRoomArea ?? '');
        } else {
          return '';
        }
      },
      fullName : 'Pow. wew. projekt. pom. gosp.',
      width : '75'
    },
    {
      Header : 'PUPG proj.',
      accessor : (a : IPlace) : string => {
        const utilityRoomsByPlace = a.utilityRooms;
        const utilityRoomsByContract = a.activeContracts && a.activeContracts[0] ? a.activeContracts[0].utilityRooms ?? [] : [];

        if (utilityRoomsByPlace.length > 0){
          return String(utilityRoomsByPlace[0].designedInnerUtilRoomArea);
        } else if (utilityRoomsByContract.length > 0){
          return String(utilityRoomsByContract[0].designedInnerUtilRoomArea ?? '');
        } else {
          return '';
        }
      },
      fullName : 'Pow. użyt. projekt. pom. gosp.',
      width : '75'
    },
    {
      Header : 'PWPG powyk.',
      accessor : (a : IPlace) : string => {
        const utilityRoomsByPlace = a.utilityRooms;
        const utilityRoomsByContract = a.activeContracts && a.activeContracts[0] ? a.activeContracts[0].utilityRooms ?? [] : [];

        if (utilityRoomsByPlace.length > 0){
          return String(utilityRoomsByPlace[0].asBuildInnerUtilRoomArea);
        } else if (utilityRoomsByContract.length > 0){
          return String(utilityRoomsByContract[0].asBuildInnerUtilRoomArea ?? '');
        } else {
          return '';
        }
      },
      fullName : 'Pow. wew. powyk. pom. gosp.',
      width : '75'
    },
    {
      Header : 'PUPG powyk.',
      accessor : (a : IPlace) : string => {
        const utilityRoomsByPlace = a.utilityRooms;
        const utilityRoomsByContract = a.activeContracts && a.activeContracts[0] ? a.activeContracts[0].utilityRooms ?? [] : [];

        if (utilityRoomsByPlace.length > 0){
          return String(utilityRoomsByPlace[0].asBuildUsableUtilRoomArea);
        } else if (utilityRoomsByContract.length > 0){
          return String(utilityRoomsByContract[0].asBuildUsableUtilRoomArea ?? '');
        } else {
          return '';
        }
      },
      fullName : 'Pow. użyt. powyk. pom. gosp.',
      width : '75'
    },
    {
      Header : 'Data zaw. umowy',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract ? contract?.contractDealDate : '';
      },
      width : '120'
    },
    {
      Header : 'Um. termin odb.',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract ? contract.contractualAcceptanceDate : '';
      },
      width : '120'
    },
    {
      Header : 'Um. termin aktu',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract ? contract.contractualActDate : '';
      },
      width : '120'
    },
    {
      Header : 'Data odbioru',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract ? contract.acceptanceDate : '';
      },
      width : '120'
    },
    {
      Header : 'Data aktu not.',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract ? contract.actDate : '';
      },
      width : '120'
    },
    {
      Header : 'Handl.',
      accessor : 'traderInitials',
      width : '80'
    },
    {
      Header : 'Zakup na dział gosp.',
      width : '100',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contractCustomers = getActiveContract(a.contracts)?.contractCustomers ?? [];
        let isBusinesss = false;
        for (let i = 0; i < contractCustomers?.length; i++) {
          if (contractCustomers[i]?.isBusiness) {
            isBusinesss = true;
            return '☑';
          }
        }
        if (!isBusinesss) {
          return '☐';
        } else {
          return '☑';
        }
      }
    },
    {
      Header : 'Spółka',
      width : '100',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contractCustomersCompanies = getActiveContract(a.contracts)?.contractCustomerCompanies ?? [];
        return contractCustomersCompanies.length ? '☑' : '☐';
      }
    },
    {
      Header : 'Walor.',
      width : '50',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract && contract.valorization ? '☑' : '☐';
      }
    },
    {
      Header : 'Kredyt',
      width : '50',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract && contract.isCredit ? '☑' : '☐';
      }
    },
    {
      Header : 'Wysyłka FV na @',
      width : '80',
      accessor : (a : { contracts : IContract[] }) : string => {
        const contract = getActiveContract(a.contracts);
        return contract && contract.isEmailInvoice ? '☑' : '☐';
      }
    },
    {
      Header : 'ID',
      accessor : 'id',
      width : '10'
    }
  ];
};
