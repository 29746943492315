import { difference } from 'lodash-es';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Route as Router, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';

import { hasRoles } from '../../services/idp';

import { USER_ROLES } from '../../constants/user';

import {
  DARreportsMeta,
  DKOreportsMeta,
  DKSReportsMeta,
  DNIAdministrationMeta,
  DNIreportsMeta,
  DNKAdministrationMeta,
  DNKreportsMeta,
  DSPAdministrationMeta,
  DSPandDSPADMandDNK,
  DSPandDSPADMreportsMeta,
  SKAReportsMeta
} from '../../mockups/tempHeaderMeta';

export interface ILabelbuttonID {
  id ?: number;
  buttonID ?: string;
  href ?: string;
  label ?: string;
}

interface IHeaderMenuSpecificOptionProps {
  buttonID ? : string;
  ctrlID : string;
  disabled ? : boolean;
  meta : string | ILabelbuttonID[];
  nameOfHeaderOption : string;
  placeActiveCustomers ?: boolean;
  placeActiveContract ?: boolean;
  selectedPlaceID ?: number;
}

export const HeaderMenuSpecificOption : FunctionComponent <IHeaderMenuSpecificOptionProps> = (
  props : IHeaderMenuSpecificOptionProps
) => {

  const history = useHistory();
  const { buttonID, ctrlID, disabled = false, meta, nameOfHeaderOption, placeActiveCustomers, selectedPlaceID } = props;
  const isDisabledForArrangements = (nameOfHeaderOption === 'Aranżacje' && !selectedPlaceID);

  const onLinkClick = useCallback((href : string) => {
    history.push(href);
  }, [history]);

  if (meta === 'button') {
    return <>
      <Button disabled={isDisabledForArrangements} id={`${nameOfHeaderOption}-${meta}-${ctrlID}`}
        className='ik2-main-button-color' onClick={() : void => onLinkClick(!isDisabledForArrangements && buttonID ? buttonID : '/')}>
        {nameOfHeaderOption}
      </Button>
    </>;
  }
  else {
    return <>
    <Dropdown>
      {(placeActiveCustomers || !selectedPlaceID ) && (nameOfHeaderOption.indexOf('Rejestracja') !== -1)
      ? <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor : '#f2f2f2', color : 'black' }} disabled={!selectedPlaceID || placeActiveCustomers}>
          {nameOfHeaderOption}
        </Dropdown.Toggle>
      : <Dropdown.Toggle id="dropdown-basic" disabled={disabled}>{nameOfHeaderOption}</Dropdown.Toggle>}
      {!disabled && (
        <Dropdown.Menu className="dw-100">
        {Array.isArray(meta) && (
          meta.map((item : ILabelbuttonID) => (
            <Dropdown.Item className="d-flex justify-content-start w-100 ik2-main-button-color"
              onClick={() : void => onLinkClick(item?.buttonID ?? '/' )}
              key={`headerMenu-HeaderMenuSpecificOption-dropdown-${item.label}-${item.id}-dropdown`}>
                {item.label}
            </Dropdown.Item>)))}
      </Dropdown.Menu>)}
      </Dropdown>
    </>;
  }
};

interface HeaderMenuSpecificOptionsProps {
  id : string;
  placeActiveContract ?: boolean;
  placeActiveCustomers ?: boolean;
  selectedPlaceID ?: number;
}

export const HeaderMenuSpecificOptions : FunctionComponent <HeaderMenuSpecificOptionsProps> = (
  props : HeaderMenuSpecificOptionsProps
) => {

  const history = useHistory();
  const { t } = useTranslation('headerMenu');
  const { id, placeActiveContract, placeActiveCustomers, selectedPlaceID } = props;

  const onLinkClick = useCallback((href : string) => {
    history.push(href);
  }, [history]);

  const { roles } = useSelector((state : RootState) => state.globalUser);
  const administrationViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DKO', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DPP', 'DSP', 'DYR', 'SEK', 'SKA']);
  const arrangementsViewRoles = difference(USER_ROLES, ['ADM', 'DKO', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DNI', 'DNK', 'DPP', 'DSP',
    'DSP-ADM', 'DYR', 'SEK', 'SKA']);
  const interestedCustomersViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DKO', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DNI', 'DPP',
  'DYR', 'SEK', 'SKA']);
  const mailMergeViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DPI', 'DMA', 'DMA-ADM', 'DNI', 'DNK', 'DPP', 'DSP', 'DSP-ADM',
    'DYR', 'SKA']);
  const reportsButtonViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DKO', 'DPI', 'DKS', 'DMA', 'DNI', 'DPP', 'DSP', 'DSP-ADM',
    'DYR', 'SEK', 'DNK']);
  const reportsListViewRoles = difference(USER_ROLES, ['ADM', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DNK', 'DPP', 'DYR', 'SEK']);

  //TODO : poza zakresem podstawowym
  //const rentalRegistrationViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DKO', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DNI', 'DPP',
    //'DSP', 'DSP-ADM', 'DYR', 'SEK', 'SKA']);
  const salesRegistrationViewRoles = difference(USER_ROLES, ['ADM', 'DAR', 'DKO', 'DPI', 'DKS', 'DMA', 'DMA-ADM', 'DNI', 'DPP',
    'DYR', 'SEK', 'SKA']);
  const dksViewRoles = difference(USER_ROLES, [
    'ADM', 'DAR', 'DKO', 'DPI', 'DNK', 'DMA', 'DMA-ADM', 'DNI', 'DPP', 'DSP', 'DSP-ADM',
    'DYR', 'SEK', 'SKA'
  ]);
  const administrationMeta = (id : string | ILabelbuttonID[]) : string | ILabelbuttonID[] => {
    if (id === 'DNI') {return DNIAdministrationMeta;}
    if (id === 'DNK') {return DNKAdministrationMeta;}
    if (id === 'DSP-ADM') {return DSPAdministrationMeta;}
    else {return DNIAdministrationMeta;}
  };
  const reportsMeta = (id : string | ILabelbuttonID[]) : string | ILabelbuttonID[] => {
    if (id === 'DAR') { return DARreportsMeta; }
    else if (id === 'SKA') { return SKAReportsMeta; }
    else if (id === 'DKO') { return DKOreportsMeta; }
    else if (id === 'DKS') { return DKSReportsMeta; }
    else if (id === 'DMA' || id === 'DMA-ADM') {return 'marketing-reports';}
    else if (id === 'DNI') { return DNIreportsMeta; }
    else if (id === 'DNK') { return DNKreportsMeta; }
    else if (id === 'DSP' || id === 'DSP-ADM') { return DSPandDSPADMreportsMeta; }
    else { return DARreportsMeta; }
  };
  // TODO, poza zakresem podstawowym
  //const rentalRegistrationMeta = (id : string | ILabelbuttonID[]) : string | ILabelbuttonID[] => {
  //  return DSPandDSPADMandDNK;
  //};
  const salesRegistrationMeta = (id : string | ILabelbuttonID[]) : string | ILabelbuttonID[] => {
    return DSPandDSPADMandDNK;
  };

  const { chosenPlacesContract } = useSelector((state : RootState) => state.globalInvestment);

  return (
  <>
    {hasRoles(interestedCustomersViewRoles, roles) && (
      <Button className="ik2-main-button-color" onClick={() : void => onLinkClick('/interested-customers-sales-department')}>
        {t('commons:menu.interested-customers')}
      </Button>)}
    {hasRoles(salesRegistrationViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.sales-registration')}
        placeActiveContract={placeActiveContract} placeActiveCustomers={placeActiveCustomers}
        meta={salesRegistrationMeta(id)} ctrlID={id}
        selectedPlaceID={selectedPlaceID} disabled={chosenPlacesContract?.investment?.isArchive} />)}
        {/* TODO : poza zakresem podstawowym
    {hasRoles(rentalRegistrationViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.rental-registration')} meta={rentalRegistrationMeta(id)}
    ctrlID={id} selectedPlaceID={selectedPlaceID} />)} */}
    {hasRoles(arrangementsViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.arrangements')} meta={'button'} ctrlID={id} buttonID={'arrangements'}
        selectedPlaceID={selectedPlaceID} />)}
    {hasRoles(mailMergeViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.mail-merge')} meta={'button'} ctrlID={id} buttonID={'mail-merge'}
        selectedPlaceID={selectedPlaceID} />)}
    {hasRoles(reportsButtonViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.reports')} meta={'button'} ctrlID={id}
        buttonID={String(reportsMeta(id))} selectedPlaceID={selectedPlaceID} />)}
    {hasRoles(reportsListViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.reports')} meta={reportsMeta(id)} ctrlID={id}
        selectedPlaceID={selectedPlaceID} />)}
    {hasRoles(dksViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.reports')} meta={reportsMeta(id)} ctrlID={id}
        selectedPlaceID={selectedPlaceID} />)}
    {hasRoles(administrationViewRoles, roles) && (
      <HeaderMenuSpecificOption nameOfHeaderOption={t('commons:menu.administration')} meta={administrationMeta(id)} ctrlID={id}
        selectedPlaceID={selectedPlaceID} />)}
  </>
  );
};

interface HeaderMenuProps {
  id : string;
}

const HeaderMenu : FunctionComponent <HeaderMenuProps> = (
  props : HeaderMenuProps
) => {

  const { id } = props;

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('headerMenu');

  const { chosenPlacesContract } = useSelector((state : RootState) => state.globalInvestment);
  const user = useSelector((state : RootState) => state.globalUser);

  const hasPlaceActiveContract = chosenPlacesContract?.activeContracts?.length;

  const hasContractCustomers = hasPlaceActiveContract && chosenPlacesContract?.activeContracts[0]?.contractCustomers?.length;
  const hasContractCustomerCompanies = hasPlaceActiveContract && chosenPlacesContract?.activeContracts[0]?.contractCustomerCompanies?.length;

  const onLinkClick = useCallback((href : string) => {
    history.push(href);
  }, [history]);

  const generateHeader = () : JSX.Element | null => {
    const administationPathnames : string[] = ['/add-investment-admin', '/buildings-admin', '/garages-admin', '/places-admin', '/utility-rooms-admin', '/parking-places-admin', '/tough-customers-admin'];
    const isUserDSPADMinAdministration = user.roles[0] === 'DSP-ADM' && administationPathnames?.includes(location.pathname);
    if (location.pathname === '/' || isUserDSPADMinAdministration) {
      return (
        <Row className="d-flex justify-content-center">
          <Button className="ik2-main-button-color" onClick={() : void => onLinkClick('/tasks')}>
            {t('commons:menu.tasks')}
          </Button>
          <HeaderMenuSpecificOptions
            id={id}
            placeActiveContract={Boolean(hasPlaceActiveContract)}
            placeActiveCustomers={
              Boolean(
                (hasPlaceActiveContract && hasContractCustomers) ||
                (hasPlaceActiveContract && hasContractCustomerCompanies)
              )
            }
            selectedPlaceID={chosenPlacesContract?.id} />
        </Row>
      );
    } else {
      return null;
    }
  };

  return (
    <Router>
      {generateHeader()}
    </Router>
  );
};

export default HeaderMenu;
