import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import UtilityRoomService from '../services/utilityRoomsService';
import { RootState } from './index';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';


export interface IUtilityRoom {
  id ?: number;
  number : string;
  designedInnerUtilRoomArea : number;
  asBuildUsableUtilRoomArea : number;
  asBuildInnerUtilRoomArea : number;
  comment : string;
  garageID ?: number | null;
  placeID ?: number | null;
  contractID ?: number | null;
  isTypeService : boolean;
  building ?: {
    id : number;
    name : string;
  };
  garage ?: {
    id : number;
    name : string;
  };
  place ?: {
    placeCode : string;
    id : number;
    activeContracts ?: {
      id ?: number;
    }[];
  };

}

interface IUtilityRoomsStore {
  rooms : IUtilityRoom[];
  allRooms : IUtilityRoom[];
  roomsLinkedToPlace : IUtilityRoom[];
  roomsLinkedToContract : IUtilityRoom[];
  unassignedUtilityRooms : IUtilityRoom[];
}

const initialState : IUtilityRoomsStore = {
  rooms : [],
  allRooms : [],
  roomsLinkedToPlace : [],
  roomsLinkedToContract : [],
  unassignedUtilityRooms : []
};

export const getUnassignedUtilityRoomsMinusAdded = createSelector((state : RootState) => state.utilityRooms, (utilityRooms) => {
    return utilityRooms.unassignedUtilityRooms.filter(unassigned => !unassigned.contractID);
  }
);

export const getAllRooms = createAsyncThunk(
  'utilityRooms/getAllRooms',
  async () => {
    return UtilityRoomService.getAllUtilityRooms();
  }
);

export const getRoomsByInvestmentName = createAsyncThunk('utilityRooms/getRoomsByInvestmentName', async (investmentName : string) => {
  return UtilityRoomService.getRoomsByInvestmentName(investmentName);
});

export const getRoomsByInvestmentStageId = createAsyncThunk('utilityRooms/getRoomsByInvestmentStageId', async (stageID : number) => {
  return UtilityRoomService.getRoomsByInvestmentStageId(stageID);
});

export const addNewUtilityRoom = createAsyncThunk('utilityRooms/addNewUtilityRoom', async (variables : { utilityRoom : IUtilityRoom }) => {
  return UtilityRoomService.addNewRoom(variables);
});

export const updateUtilityRoom = createAsyncThunk('utilityRooms/updateUtilityRoom',
  async (variables : { utilityRoom : IUtilityRoom; utilityRoomID : number }) => {
    return UtilityRoomService.updateRoom(variables);
});

export const removeUtilityRoom = createAsyncThunk('utilityRooms/removeUtilityRoom', async (utilityRoomID : number) => {
  return UtilityRoomService.removeRoom(utilityRoomID);
});

export const getUtilityRoomsLinkedToContract = createAsyncThunk(
  'utilityRooms/getUtilityRoomsLinkedToContract',
  async (variables : { investmentName : string; contractID : number }) => {
    return {
      rooms : await UtilityRoomService.getRoomsByInvestmentName(variables.investmentName) as IUtilityRoom[],
      contractID : variables.contractID
    };
  }
);

export const getUtilityRoomsLinkedToPlace = createAsyncThunk(
  'utilityRooms/getUtilityRoomsLinkedToPlace',
  async (variables : { investmentName : string; placeID : number }) => {
    return {
      rooms : await UtilityRoomService.getRoomsByInvestmentName(variables.investmentName) as IUtilityRoom[],
      placeID : variables.placeID
    };
  }
);

export const getUnassignedUtilityRooms = createAsyncThunk(
  'utilityRooms/getUnassignedUtilityRooms',
  async (variables : { investmentName : string}) => {
    return {
      rooms : await UtilityRoomService.getRoomsByInvestmentName(variables.investmentName) as IUtilityRoom[]
    };
  }
);

const utilityRoomsSlice = createSlice({
  name : 'utilityRooms',
  initialState,
  reducers : {
    addUtilityRoomToContract (state, action : PayloadAction<IUtilityRoom>) : void {
      state.roomsLinkedToContract.push(action.payload);
    },
    removeUtilityRoomFromContract (state, action : PayloadAction<number>) : void {
      const id = action.payload;
      const foundIndex = state.roomsLinkedToContract.findIndex(room => room.id === id);
      if (foundIndex !== -1){
        state.roomsLinkedToContract.splice(foundIndex);
      }
    },
    clearLinkedUtilityRooms (state) : void {
      state.roomsLinkedToContract = [];
      state.roomsLinkedToPlace = [];
    }
  },
  extraReducers : {
    [getAllRooms.fulfilled.toString()] : (state, action) : void => {
      state.allRooms = action.payload.utilityRooms;
    },
    [getRoomsByInvestmentName.fulfilled.toString()] : (state, action) : void => {
      state.rooms = action.payload.utilityRoomsByInvestmentsNamesThroughGarage;
    },
    [getRoomsByInvestmentName.rejected.toString()] : (state, action) : void => {
      state.rooms = [];
    },
    [getUtilityRoomsLinkedToContract.fulfilled.toString()] : (state, action) : void => {
      const contractID = action.payload.contractID;
      const rawRooms = action.payload.rooms.utilityRoomsByInvestmentsNamesThroughGarage as IUtilityRoom[] ?? [];
      const filteredRooms = rawRooms.filter((room : IUtilityRoom) => room.contractID === contractID);
      state.roomsLinkedToContract = filteredRooms;
    },
    [getUtilityRoomsLinkedToContract.rejected.toString()] : (state, _) : void => {
      state.roomsLinkedToContract = [];
    },
    [getUtilityRoomsLinkedToPlace.fulfilled.toString()] : (state, action) : void => {
      const placeID = action.payload.placeID;
      const rawRooms = action.payload.rooms.utilityRoomsByInvestmentsNamesThroughGarage as IUtilityRoom[] ?? [];
      const filteredRooms = rawRooms.filter((room : IUtilityRoom) => room.placeID === placeID);
      state.roomsLinkedToPlace = filteredRooms;
      //if roomsLinkedToPlace is not empty there should not be any rooms linked to Contract! only 'either - or'
      if (filteredRooms.length > 0) {
        state.roomsLinkedToContract = [];
      }
    },
    [getUtilityRoomsLinkedToPlace.rejected.toString()] : (state, _) : void => {
      state.roomsLinkedToPlace = [];
    },
    [getUnassignedUtilityRooms.fulfilled.toString()] : (state, action) : void => {
      const possibleUtilityRooms = action.payload.rooms.utilityRoomsByInvestmentsNamesThroughGarage ?? [];
      const rawRooms = possibleUtilityRooms.filter(
        (room : IUtilityRoom) => room.contractID == null
      ) as IUtilityRoom[] ?? [];

      const filteredRooms = rawRooms.filter((room : IUtilityRoom) => room.placeID == null); // Business logic - unassigned places dont' have a place
      state.unassignedUtilityRooms = filteredRooms;
    },
    [getRoomsByInvestmentStageId.fulfilled.toString()] : (state, action) : void => {
      state.rooms = action.payload.utilityRoomsByStages;
    },
    [getRoomsByInvestmentStageId.rejected.toString()] : (state, action) : void => {
      state.rooms = [];
    },
    [addNewUtilityRoom.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addUtilityRoom')));
    },
    [removeUtilityRoom.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:deleteUtilityRoom')));
    },
    [updateUtilityRoom.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editUtilityRoom')));
    }
  }
});

export const { addUtilityRoomToContract, removeUtilityRoomFromContract, clearLinkedUtilityRooms } = utilityRoomsSlice.actions;

export default utilityRoomsSlice.reducer;
