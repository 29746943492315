import React, { FunctionComponent } from 'react';
import './Reports.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';
import { fetchDNIPickupScheduleReport } from '../../ducks/reports';
import Report from './Report';

const DNIPickupScheduleReport  : FunctionComponent = () => {
  const { DNIPickupScheduleReport } = useSelector((state : RootState) => state.reports);

 return  <Report reportData={DNIPickupScheduleReport} reportName='Raport-odbiorow.xlsx'   reduxAction={fetchDNIPickupScheduleReport} />;
  
};

export default DNIPickupScheduleReport ;