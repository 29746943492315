import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export type RedirectButtonProps = {
 path : string;
 disabled ?: boolean;
}


const RedirectButton : React.FC<RedirectButtonProps> = ({ children, path, disabled = false }) => {

  const history = useHistory();

  const handleOnClick = useCallback(() => {
    history.push(path);
  }, [history, path]);

 return (
  <Button className={disabled ? 'ik2-link-inside-button' : 'ik2-main-button-color'} disabled={disabled} onClick={handleOnClick}>
    {children}
 </Button>
 );
};
export default RedirectButton;
