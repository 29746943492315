import * as React from 'react';
import { Button } from 'react-bootstrap';
import InputDate from '../commons/Inputs/InputDate';
import moment from 'moment';
import 'moment/locale/pl';
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import InputCheckbox from '../commons/Inputs/InputCheckbox';
import { downloadXlsxReport, fetchDSPCustomerByTradersReport } from '../../ducks/reports';
import { fetchTraderInitials } from '../../ducks/adminIT';
import { useTranslation } from 'react-i18next';

const CustomerByTradersReport : React.FC = () => {
    const { t } = useTranslation();
    const traderInitialsList = useSelector((state : RootState) => state.adminIT.tradersInitials);
    const isLoading = useSelector((state : RootState) => state.combinedReports.DSPCustomerByTradersReport.isLoading);
    const dispatch : AppDispatch = useDispatch();

    const [fromDate, setFromDate] = React.useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [toDate, setToDate] = React.useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [traderInitials, setTraderInitials] = React.useState<string[]>([]);


    const updateFromDate = React.useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setFromDate(formatedDate);
    }, []);

    const updateToDate = React.useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setToDate(formatedDate);
    }, []);

    const updateTraderInitials = React.useCallback((traderInitial : string) => {
        const traderIsSelected = traderInitials.includes(traderInitial);
        if (traderIsSelected) {
           return setTraderInitials((prevState : string[]) => prevState.filter(inv => inv !== traderInitial));
        }
        setTraderInitials((prevState : string[]) => [...prevState, traderInitial]);
    }, [traderInitials]);

    const generateCustomerByTradersReport = React.useCallback(() => {
        dispatch(fetchDSPCustomerByTradersReport({ traderInitials, fromDate, toDate }))
        .then((response : any) => {
            if (response.payload?.DSPCustomerByTradersReport) {
                return dispatch(downloadXlsxReport({ content : response.payload.DSPCustomerByTradersReport, filename : 'Zainteresowani-wg-handlowcow.xlsx' }));
            }
            alert('Błąd podczas pobierania raportu');
        });
    }, [traderInitials, toDate, fromDate, dispatch ]);

    React.useEffect(() => {
        dispatch(fetchTraderInitials());
    }, [dispatch]);


    return (
        <div className="container">
            <div>
                <Button disabled={!traderInitials.length || isLoading} onClick={generateCustomerByTradersReport}>
                    {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
                </Button>
            </div>
            <p>{t('commons:actions.select-dates')}</p>
            <div className="inputs-wrapper">
                <InputDate id="1" onDayChange={updateFromDate} customWidth={300} text={t('commons:labels.from')} />
                <InputDate id="2" onDayChange={updateToDate} customWidth={300} text={t('commons:labels.to')} />
            </div>
            <div className="column">
                <p>{t('commons:actions.select-trader')}</p>
                <div className="checkbox-wrapper">
                    {traderInitialsList?.map(trader => (
                        <InputCheckbox
                            id={trader?.username}
                            label={trader?.username}
                            customWidth={150}
                            key={trader?.username}
                            onClick={() : void => updateTraderInitials(trader?.username)} />
                        ))}
                </div>
            </div>
    </div>
    );
};

export default CustomerByTradersReport;
