import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter, push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState, history } from '../../ducks';
import DKOAreasReport from '../Reports/DKOAreasReports';
import DKOExecutionStatusReport from '../Reports/DKOExecutionStatusReport';
import DKOSerialCorrespondenceReport from '../Reports/DKOSerialCorrespondenceReport';
import DNIActsReport from '../Reports/DNIActsReport';
import DARArrangementReport from '../Reports/DARArrangementReport';
import DNIPickupScheduleReport from '../Reports/DNIPickupScheduleReport';
import DNIShareTableReport from '../Reports/DNIShareTableReport';
import DSPCancelledContractsReport from '../Reports/DSPCancelledContractsReport';
import Header from '../Header/Header';
import NotarialOfficeReport from '../Reports/NotarialOfficeReport';
import Search from '../Search/Search';
import { IInvestment, IStage } from '../../graphql/investments';
import {
  setChosenPlacesContract,
  setGlobalChosenInvestment,
  setGlobalChosenStage,
  setSelectedRows
} from '../../ducks/globalInvestment';
import {
  AdminITRoles,
  CorrespondenceEventsViewRoles,
  DPPRoles,
  getUserNamesList,
  hasUserEditCustomerAndContractDataRole
} from '../../ducks/globalUser';
import CustomerByTradersReport from '../Reports/CustomerByTradersReport';
import DSPCustomersByBrokersReport from '../Reports/DSPCustomersByBrokersReport';
import CustomerSourceContractReport from '../Reports/CustomerSourceContractReport';
import DSPCustomerResignationReport from '../Reports/DSPCustomerResignationReport';
import DSPPlacesListReport from '../Reports/DSPPlacesListReport';
import DSPSummaryReport from '../Reports/DSPSummaryReport';
import BuildingAdministratorsReport from '../Reports/BuildingAdministratorsReport';
import MainTable from '../MainTable/MainTable';
import { IContract } from '../../graphql/contracts/contracts';
import { Row } from 'react-bootstrap';
import HeaderMenu from '../Header/HeaderMenu';
import DSPInterestedCustomersDailyReport from '../Reports/DSPInterestedCustomersDailyReport';
import DSPCustomerConcludedContractsReport from '../Reports/DSPCustomerConcludedContractsReport';
import DSPDailyInterestedPartiesReport from '../Reports/DSPDailyInterestedPartiesReport';
import DKSBusinessPlacesReport from '../Reports/DKSBusinessPlacesReport';
import DARDateAnnexesReport from '../Reports/DARDateAnnexesReport';
import DARArrangementListReport from '../Reports/DARArrangementListReport';
import { hasRoles } from '../../services/idp';
import Loader from '../commons/Loader/Loader';
import { SuspendedRoute } from './SuspendedRoute';
import InterestedCustomersReport from '../InterestedCustomers/raport/InterestedCustomersReport';
import BlueListSalesReport from  '../Reports/BlueListSalesReport';

const CancelledContractPlace = React.lazy(() => import('../Place/CancelledContractPlace'));
const CancelledContractCustomerData = React.lazy(() => import('../Customer/CancelledContractCustomerData'));
const BuildingAdministratorsAdmin = React.lazy(() => import('../Administration/BuildingAdministratorsAdmin/BuildingAdministratorsAdmin'));
const NotarialOfficesAdmin = React.lazy(() => import('../Administration/NotarialOfficesAdmin/NotarialOfficesAdmin'));
const DMAMarketingReport = React.lazy(() => import('../Reports/DMAMarketingReport'));
const LandStripAdminPanel = React.lazy(() => import('../Administration/LandStripAdminPanel'));
const BlueListAdminPanel = React.lazy(() => import('../Administration/BlueListAdminPanel'));
const ParkingPlacesAdminPanel = React.lazy(() => import('../Administration/ParkingPlacesAdminPanel/ParkingPlacesAdminPanel'));
const UtilityRoomAdminPanel = React.lazy(() => import('../Administration/UtilityRoomAdminPanel/UtilityRoomAdminPanel'));
const PlaceAdminPanel = React.lazy(() => import('../Administration/PlaceAdminPanel/PlaceAdminPanel'));
const GarageHallsAdminPanel = React.lazy(() => import('../Administration/GaragesAdminPanel/GaragesAdminPanel'));
const BuildingsAdminPanel = React.lazy(() => import('../Administration/BuildingsAdminPanel/BuildingsAdminPanel'));
const SalesRegisterLegalPerson = React.lazy(() => import('../SalesRegister/SalesRegisterLegalPerson'));
const SalesRegisterNaturalPerson = React.lazy(() => import('../SalesRegister/SalesRegisterNaturalPerson'));
const InterestedCustomers = React.lazy(() => import('../InterestedCustomers/InterestedCustomers'));
const Arrangements = React.lazy(() => import('../Arrangements/Arrangements'));
const Tasks = React.lazy(() => import('../Tasks/Tasks'));
const Place = React.lazy(() => import('../Place/Place'));
const CustomerData = React.lazy(() => import('../Customer/CustomerData'));
const Contract = React.lazy(() => import('../Contract/Contract'));
const ContractEventsHistory = React.lazy(() => import('../ContractHistory/ContractEventsHistory'));
const ContractCorrespondenceHistory = React.lazy(() => import('../ContractHistory/ContractCorrespondenceHistory'));
const AnnexHistory = React.lazy(() => import('../AnnexHistory/AnnexHistory'));
const CancelledArchivalContracts = React.lazy(() => import('../Header/CancelledArchivalContracts'));
const CancelledContracts = React.lazy(() => import('../Contract/cancelled-contracts/CancelledContracts'));
const AdminITPanel = React.lazy(() => import('../AdminIT/AdminITPanel'));
const InvestmentAdminPanel = React.lazy(() => import('../Administration/InvestmentsAdminPanel/InvestmentAdminPanel'));

const AppRouter : FunctionComponent = () => {

  const dispatch : AppDispatch = useDispatch();
  const { roles } = useSelector((state : RootState) => state.globalUser);
  const [searchPhrase, setSearchPhrase] = useState('');
  const { chosenPlacesContract, placesContracts } = useSelector(
    (state : RootState) => state.globalInvestment
  );
  const user = useSelector((state : RootState) => state.globalUser);

  const isActiveContract = chosenPlacesContract?.activeContracts?.length;
  const canSeeContractDetails = hasRoles(CorrespondenceEventsViewRoles, roles) && chosenPlacesContract;

  const [chosenRowIndex, setChosenRowIndex] = useState(() => {
    if (placesContracts && chosenPlacesContract) {
      return placesContracts.findIndex(
        (contr : IContract) => contr.id === chosenPlacesContract.id
      );
    } else {
      return -1;
    }
  });

  useEffect(() => {
    dispatch(getUserNamesList());
  }, [dispatch]);

  const onChangeChosenInvestment = useCallback((selectedInvestment : IInvestment) => {
    const isSelecetedCanceledContracts = selectedInvestment.id === 0;
    const isCancelledContractsLocation = history.location.pathname === '/cancelled-contracts';
    isSelecetedCanceledContracts && dispatch(push('/cancelled-contracts'));
    isCancelledContractsLocation && dispatch(push('/'));
    dispatch(setGlobalChosenInvestment(selectedInvestment));
    dispatch(setChosenPlacesContract(null));
    dispatch(setSelectedRows([]));
  }, [dispatch]);

  const onChangeChosenStage = useCallback(
    (selectedStage : IStage) => {
      dispatch(setGlobalChosenStage(selectedStage));
      dispatch(setChosenPlacesContract(null));
      dispatch(setSelectedRows([]));
    },
    [dispatch]
  );

  return (
    <ConnectedRouter history={history}>
        <Header
          onChangeChosenStage={onChangeChosenStage}
          onChangeChosenInvestment={onChangeChosenInvestment}
          departmentView={user.roles[0] || ''}
          onSearchSubmit={setSearchPhrase}
        />
        <Row className="d-flex justify-content-center mx-0">
          <HeaderMenu id={user.roles[0] || ''} />
        </Row>
        <Switch>
          <SuspendedRoute path="/cancelled-contracts">
            <CancelledContracts />
          </SuspendedRoute>
          <SuspendedRoute path="/archival-contracts">
            <CancelledArchivalContracts archivalContracts />
          </SuspendedRoute>
          <SuspendedRoute path="/cancelled-contract/place/annex-history">
            <AnnexHistory />
          </SuspendedRoute>
          <SuspendedRoute path="/valid-contract/place/annex-history">
            <AnnexHistory />
          </SuspendedRoute>
          <SuspendedRoute path="/archival-contract/place/annex-history">
            <AnnexHistory />
          </SuspendedRoute>

          <SuspendedRoute path="/cancelled-contract/contract/contract-correspondence-history">
            {canSeeContractDetails && <ContractCorrespondenceHistory disabled />}
          </SuspendedRoute>
          <SuspendedRoute path="/valid-contract/contract/contract-correspondence-history">
            {canSeeContractDetails && <ContractCorrespondenceHistory />}
          </SuspendedRoute>
          <SuspendedRoute path="/archival-contract/contract/contract-correspondence-history">
            {canSeeContractDetails && <ContractCorrespondenceHistory disabled />}
          </SuspendedRoute>

          <SuspendedRoute path="/cancelled-contract/contract/contract-events-history">
            <ContractEventsHistory disabled />
          </SuspendedRoute>
          <SuspendedRoute path="/valid-contract/contract/contract-events-history">
            <ContractEventsHistory />
          </SuspendedRoute>
          <SuspendedRoute path="/archival-contract/contract/contract-events-history">
            <ContractEventsHistory disabled />
          </SuspendedRoute>
          <SuspendedRoute path="/cancelled-contract/contract" >
            {canSeeContractDetails && <Contract key={`cancelledContract-${chosenPlacesContract?.id}`} />}
          </SuspendedRoute>
          <SuspendedRoute path="/valid-contract/contract">
            {canSeeContractDetails && <Contract key={`cancelledContract-${chosenPlacesContract?.id}`} />}
          </SuspendedRoute>
          <SuspendedRoute path="/archival-contract/contract">
            {canSeeContractDetails && <Contract key={`archivalContract-${chosenPlacesContract?.id}`} />}
          </SuspendedRoute>
          {!hasRoles(DPPRoles, roles) && (
            <SuspendedRoute path="/valid-contract/customer">
              {chosenPlacesContract && <CustomerData key={`contractCustomer-${chosenPlacesContract.id}`}
                selectedPlace={chosenPlacesContract} />}
            </SuspendedRoute>
          )}
          <SuspendedRoute path="/cancelled-contract/place">
            {chosenPlacesContract && <CancelledContractPlace />}
          </SuspendedRoute>
          <SuspendedRoute path="/cancelled-contract/customer">
            {chosenPlacesContract && <CancelledContractCustomerData />}
          </SuspendedRoute>
          <SuspendedRoute path="/valid-contract/place">
            {chosenPlacesContract &&
              <Place
                key={`placeValidContract${chosenPlacesContract.id}`}
              />
            }
          </SuspendedRoute>
          <SuspendedRoute path="/archival-contract/place">
            {chosenPlacesContract &&
              <Place
                key={`placeArchivalContract${chosenPlacesContract.id}`}
                disabled
              />
            }
          </SuspendedRoute>
          <SuspendedRoute path="/tasks">
            <Tasks />
          </SuspendedRoute>
          <SuspendedRoute path="/arrangements">
            <Arrangements />
          </SuspendedRoute>
          <SuspendedRoute path="/interested-customers-sales-department">
            <InterestedCustomers />
          </SuspendedRoute>
          <SuspendedRoute path="/natural-people">
            {chosenPlacesContract &&
              <SalesRegisterNaturalPerson
                isEditButton={dispatch(hasUserEditCustomerAndContractDataRole())}
                selectedPlace={chosenPlacesContract}
              />
            }
          </SuspendedRoute>
          <SuspendedRoute path="/legal-people">
            {chosenPlacesContract && <SalesRegisterLegalPerson selectedPlace={chosenPlacesContract} />}
          </SuspendedRoute>
          <Route path="/mail-merge">
            {chosenPlacesContract && <DKOSerialCorrespondenceReport />}
          </Route>
          <Route path="/dks-places-legal-person-report">
            <DKSBusinessPlacesReport />
          </Route>
          <Route path="/cancelled-contracts-report">
            <DSPCancelledContractsReport />
          </Route>
          <Route path="/places-list-report">
            <DSPPlacesListReport />
          </Route>
          <Route path="/archival-contracts-report">
            <CancelledArchivalContracts report archivalContracts />
          </Route>
          <Route path="/daily-sales-department-report">
            <DSPDailyInterestedPartiesReport />
          </Route>
          <Route path="/tough-sales-report">
              <BlueListSalesReport />
          </Route>
          <SuspendedRoute path="/add-investment-admin">
              <InvestmentAdminPanel key="AddUpdateTabsAdminPanelInvestment" />
          </SuspendedRoute>
          <SuspendedRoute path="/buildings-admin">
            <BuildingsAdminPanel key="BuildingsAdminPanelDSP" />
          </SuspendedRoute>
          <SuspendedRoute path="/service-buildings-admin">
            <BuildingsAdminPanel key="BuildingsAdminPanelDNK" />
          </SuspendedRoute>
          <SuspendedRoute path="/garages-admin">
            <GarageHallsAdminPanel />
          </SuspendedRoute>
          <SuspendedRoute path="/service-garages-admin">
          <GarageHallsAdminPanel />
          </SuspendedRoute>
          <SuspendedRoute path="/places-admin">
            <PlaceAdminPanel />
          </SuspendedRoute>
          <SuspendedRoute path="/service-places-admin">
            <PlaceAdminPanel />
          </SuspendedRoute>
          <SuspendedRoute path="/utility-rooms-admin">
            <UtilityRoomAdminPanel key="AddUpdateTabsAdminPanelUtilityRooms" />
          </SuspendedRoute>
          <SuspendedRoute path="/service-utility-rooms-admin">
            <UtilityRoomAdminPanel key="AddUpdateTabsAdminPanelUtilityRoomsService" />
          </SuspendedRoute>
          <SuspendedRoute path="/parking-places-admin">
            <ParkingPlacesAdminPanel key="AddUpdateTabsAdminPanelParkingPlaces" />
          </SuspendedRoute>
          <SuspendedRoute path="/service-parking-places-admin">
            <ParkingPlacesAdminPanel key="AddUpdateTabsAdminPanelParkingPlacesService" />
          </SuspendedRoute>
          <SuspendedRoute path="/tough-customers-admin">
            <BlueListAdminPanel />
          </SuspendedRoute>
          <SuspendedRoute path="/service-land-strips-admin">
            <LandStripAdminPanel />
          </SuspendedRoute>
          <Route path="/search">
            <Search phrase={searchPhrase} />
          </Route>
          <Route path="/offices-report">
            <NotarialOfficeReport />
          </Route>
          <Route path="/building-administrators-report">
            <BuildingAdministratorsReport />
          </Route>
          <Route path="/interested-customers-traders-report">
            <CustomerByTradersReport />
          </Route>
          <Route path="/execution-statuses-report">
            <DKOExecutionStatusReport />
          </Route>
          <Route path="/receptions-report">
            <DNIPickupScheduleReport />
          </Route>
          <Route path="/places-area-report">
            <DKOAreasReport />
          </Route>
          <Route path="/acts-report">
            <DNIActsReport />
          </Route>
          <Route path="/arrangements-report">
            {isActiveContract
              ? <DARArrangementReport />
              : <div className='d-flex justify-content-center py-5'>
                  <p>Brak aktualnej umowy dla wybranego lokalu!</p>
                </div>
            }
          </Route>
          <Route path="/shares-report">
            {chosenPlacesContract && <DNIShareTableReport />}
          </Route>
          <Route path="/interested-customers-broker-reports">
            <DSPCustomersByBrokersReport isFromContract={false} />
          </Route>
          <Route path="/interested-customers-broker-contracts-reports">
            <DSPCustomersByBrokersReport isFromContract={true} />
          </Route>
          <Route path="/concluded-contracts-report">
            <DSPCustomerConcludedContractsReport />
          </Route>
          <Route path="/customer-source-contract-report">
            <CustomerSourceContractReport />
          </Route>
          <Route path="/interested-customers-report">
            <InterestedCustomersReport />
          </Route>
          <Route path="/summary-report-dsp">
            <DSPSummaryReport />
          </Route>
          <Route path="/interested-customers-daily-report">
            <DSPInterestedCustomersDailyReport />
          </Route>
	        <Route path="/customer-resignation-report">
            <DSPCustomerResignationReport />
          </Route>
          <SuspendedRoute path="/building-administrators-admin">
            <BuildingAdministratorsAdmin />
          </SuspendedRoute>
          <SuspendedRoute path="/offices-admin">
            <NotarialOfficesAdmin />
          </SuspendedRoute>
          <Route path="/date-annexes-report">
            <DARDateAnnexesReport />
          </Route>
          <Route path="/list-report">
            <DARArrangementListReport />
          </Route>
          <Route path="/">
          {
            hasRoles(AdminITRoles, roles)
            ? <React.Suspense fallback={<Loader />}>
                <AdminITPanel />
              </React.Suspense>
            : hasRoles(['DMA'], roles)
            ? <React.Suspense fallback={<Loader />}>
                <DMAMarketingReport />
              </React.Suspense>
            : <MainTable setChosenRowIndex={setChosenRowIndex} chosenRowIndex={chosenRowIndex} />
          }
          </Route>
        </Switch>
      </ConnectedRouter>
  );
};

export default AppRouter;
