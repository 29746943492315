import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export interface PdfReport {
  content : string;
}

export interface IFetchCompanyDataReportProps {
  contractCustomerCompanyID : number;
  contractID : number;
}

export interface IFetchCustomerDataReportProps {
  contractCustomerID : number;
  contractID : number;
}

export interface IFetchInformationCompanyReportProps {
  contractCustomerCompanyID : number;
  contractID : number;
}

export interface IFetchInformationCustomerReportProps {
  contractCustomerID : number;
  contractID : number;
}

const fetchCompanyDataReport = ({ contractCustomerCompanyID, contractID } : IFetchCompanyDataReportProps) : Promise<any> => {
  const FETCH_COMPANY_DATA_RAPORT = gql`
    query compdr($contractCustomerCompanyID: Float!, $contractID: Float!) {
      companyDataReport(contractCustomerCompanyID: $contractCustomerCompanyID, contractID: $contractID) {
        content
      }
    }
  `;

  return GraphqlService.send(FETCH_COMPANY_DATA_RAPORT, { contractCustomerCompanyID, contractID });
};

const fetchCustomerDataReport = ({ contractCustomerID, contractID } : IFetchCustomerDataReportProps) : Promise<any> => {
  const FETCH_CUSTOMER_DATA_RAPORT = gql`
    query custdr($contractCustomerID: Float!, $contractID: Float!) {
      customerDataReport(contractCustomerID: $contractCustomerID, contractID: $contractID) {
        content
      }
    }
  `;

  return GraphqlService.send(FETCH_CUSTOMER_DATA_RAPORT, { contractCustomerID, contractID });
};

const fetchInformationCompanyReport = ({ contractCustomerCompanyID, contractID } : IFetchInformationCompanyReportProps) : Promise<any> => {
  const FETCH_INFORMATION_COMPANY_RAPORT = gql`
    query iccompr($contractCustomerCompanyID: Float!, $contractID: Float!) {
      informationCardCompanyReport(contractCustomerCompanyID: $contractCustomerCompanyID, contractID: $contractID) {
        content
      }
    }
  `;

  return GraphqlService.send(FETCH_INFORMATION_COMPANY_RAPORT, { contractCustomerCompanyID, contractID });
};

const fetchInformationCustomerReport = ({ contractCustomerID, contractID } : IFetchInformationCustomerReportProps) : Promise<any> => {
  const FETCH_INFORMATION_CUSTOMER_RAPORT = gql`
    query iccustr($contractCustomerID: Float!, $contractID: Float!) {
      informationCardCustomerReport(contractCustomerID: $contractCustomerID, contractID: $contractID) {
        content
      }
    }
  `;

  return GraphqlService.send(FETCH_INFORMATION_CUSTOMER_RAPORT, { contractCustomerID, contractID });
};

const mutations = {
  fetchCompanyDataReport,
  fetchCustomerDataReport,
  fetchInformationCompanyReport,
  fetchInformationCustomerReport
};
export default mutations;
