import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PaginationState {
  interestedCustomersSearchQuery : string;
  refetch : boolean;
}

const initialState = {
  interestedCustomersSearchQuery : '',
  refetch : false
} as PaginationState;

const paginationSlice = createSlice({
  name : 'pagination',
  initialState : initialState,
  reducers : {
    setInterestedCustomersSearchQuery (
      state,
      action : PayloadAction<string>
    ) : void {
      state.interestedCustomersSearchQuery = action.payload;
    },
    refetchPagination (state, action : PayloadAction<boolean>) : void {
      state.refetch = action.payload;
    }
  }
});

export const {
  refetchPagination,
  setInterestedCustomersSearchQuery
} = paginationSlice.actions;
export default paginationSlice.reducer;
