import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';
import { IContractCustomerInput } from '../graphql/contractCustomers';
import { IContractCustomerCompany, IContractCustomerCompanyInput } from '../graphql/contractCustomerCompany';
import { IContract } from '../graphql/contractRegister';
import { IPlaceContract } from '../graphql/currentPlaceContracts/currentPlaceContracts';
import { IMarketingConsentInput } from '../graphql/marketingConsents';

export interface SaveContractWithCustomersProps {
  contractCustomersList : IContractCustomerInput[];
  contract : any; // TODO 'any' because no good IContract type
  customersList : number[];
  utilityRoomsList : number[];
  parkingPlacesList : number[];
  marketingConsents : Partial<IMarketingConsentInput>[];
}

export interface SaveContractWithCustomerCompaniesProps {
  marketingConsents : Partial<IMarketingConsentInput>[];
  customersList : number[];
  contractCustomerCompanys : IContractCustomerCompanyInput[];
  contract : any;
  utilityRoomsList : number[];
  parkingPlacesList : number[];
}

const saveContractWithCustomers = (variables : SaveContractWithCustomersProps) : Promise<any> => {
  const SAVE_CONTRACT_WITH_CUSTOMERS_QUERY = gql`
    mutation scwc(
      $contractCustomersList: [ContractCustomerInput2!]!
      $contract: ContractInput!
      $customersList: [Float!]!
      $utilityRoomsList: [Float!]
      $parkingPlacesList: [Float!]
      $marketingConsents: [MarketingConsentInput2!]
    ) {
      saveContractWithCustomers(
        contractCustomersList: $contractCustomersList
        contract: $contract
        customersList: $customersList
        utilityRoomsList: $utilityRoomsList
        parkingPlacesList: $parkingPlacesList
        marketingConsents: $marketingConsents
      ) {
        id
        contractCustomers {
          id
          firstName
          lastName
        }
      }
    }
  `;

  return GraphqlService.send(SAVE_CONTRACT_WITH_CUSTOMERS_QUERY, variables);
};

const saveContractWithCustomerCompanies = (variables : SaveContractWithCustomerCompaniesProps) : Promise<any> => {
  const SAVE_CONTRACT_WITH_CUSTOMER_COMPANIES_QUERY = gql`
    mutation scwcc(
      $marketingConsents: [MarketingConsentInput2!]
      $customersList: [Float!]
      $contractCustomerCompanys: [ContractCustomerCompanyInputR!]!
      $contract: ContractInput!
      $utilityRoomsList: [Float!]
      $parkingPlacesList: [Float!]
    ) {
      saveCompanyContract(
        marketingConsents: $marketingConsents
        customersList: $customersList
        contractCustomerCompanys: $contractCustomerCompanys
        contract: $contract
        utilityRoomsList: $utilityRoomsList
        parkingPlacesList: $parkingPlacesList
      ) {
        id
        contractCustomerCompanies {
          id
          firstName
          lastName
          businessName
        }
      }
    }
  `;

  return GraphqlService.send(SAVE_CONTRACT_WITH_CUSTOMER_COMPANIES_QUERY, variables);
};

const getContractVariablesFromForm = (
  customerCode : string,
  contractPlaceValuesData : IContractCustomerCompany,
  initialContractNo : string,
  selectedPlace : IPlaceContract,
  activeContract : IContract,
  numberOfOwners : number,
  placeData : any
) : any => {

  return {
    customerCode,
    comments : placeData?.comments ??
      '',
    contractNo : placeData?.contractNo
      ? String(placeData?.contractNo)
      : initialContractNo,
    street : selectedPlace?.stage?.street && selectedPlace?.stage.street,
    backyardTerrace : placeData?.backyardTerrace ?? false,
    roofTerrace : placeData?.roofTerrace ?? false,
    garden : placeData?.garden ?? false,
    repNumber : placeData?.repNumber
      ? String(placeData?.repNumber)
      : activeContract?.repNumber
      ? String(activeContract.repNumber)
      : '',
    otherContract : placeData?.otherContract
      ? String(placeData?.otherContract)
      : activeContract?.otherContract
      ? String(activeContract.otherContract)
      : '',
    lawContract : placeData?.lawContract
      ? Boolean(placeData?.lawContract)
      : activeContract?.lawContract
      ? Boolean(activeContract.lawContract)
      : false,
    deluxeContract : placeData?.deluxeContract
      ? Boolean(placeData?.deluxeContract)
      : activeContract?.deluxeContract
      ? Boolean(activeContract.deluxeContract)
      : false,
    valorization : placeData?.valorization
      ? Boolean(placeData?.valorization)
      : activeContract?.valorization
      ? Boolean(activeContract.valorization)
      : false,
    cancelledContract : false,
    cancelledContractDate : null,
    isArchived : false,
    archivedDate : null,
    isEmailInvoice : placeData?.isEmailInvoice
      ? Boolean(placeData?.isEmailInvoice)
      : activeContract?.isEmailInvoice
      ? Boolean(activeContract.isEmailInvoice)
      : false,
    isCredit : placeData?.isCredit
      ? Boolean(placeData?.isCredit)
      : activeContract?.isCredit
      ? Boolean(activeContract.isCredit)
      : false,
    creditType : placeData?.creditType
      ? String(placeData?.creditType)
      : activeContract?.creditType
      ? String(activeContract.creditType)
      : '',
    creditSecurity : placeData?.creditSecurity
      ? String(placeData?.creditSecurity)
      : activeContract?.creditSecurity
      ? String(activeContract.creditSecurity)
      : '',
    lendingBank : placeData?.lendingBank
      ? String(placeData?.lendingBank)
      : activeContract?.lendingBank
      ? String(activeContract.lendingBank)
      : '',
    amountOfCredit1 : placeData?.amountOfCredit1
      ? Number(placeData?.amountOfCredit1)
      : activeContract?.amountOfCredit1
      ? Number(activeContract.amountOfCredit1)
      : 0,
    amountOfCredit2 : placeData?.amountOfCredit2
      ? Number(placeData?.amountOfCredit2)
      : activeContract?.amountOfCredit2
      ? Number(activeContract.amountOfCredit2)
      : 0,
    creditDealNo : placeData?.creditDealNo ??
      activeContract?.creditDealNo ??
      null,
      //TODO : contractDealDate jako optional na backendzie
    contractDealDate : placeData?.contractDealDate ?? '2000-01-01',
    creditDealDate : placeData?.creditDealDate,
    contractualAcceptanceDate : placeData?.contractualAcceptanceDate,
    contractualActDate : placeData?.contractualActDate,
    acceptanceDate : placeData?.acceptanceDate,
    actDate : placeData?.actDate,
    notarialOffice : placeData?.notarialOffice
      ? String(placeData?.notarialOffice)
      : activeContract?.notarialOffice
      ? String(activeContract.notarialOffice)
      : '',
    isPledge : placeData?.isPledge
      ? Boolean(placeData?.isPledge)
      : activeContract?.isPledge
      ? Boolean(activeContract.isPledge)
      : false,
    pledge : placeData?.isPledge ? (placeData?.pledge === 'Inne' ? `${placeData?.pledgeMonths} mscy` : placeData?.pledge ) : '',
    isWarranty : placeData?.isWarranty
      ? Boolean(placeData?.isWarranty)
      : activeContract?.isWarranty
      ? Boolean(activeContract.isWarranty)
      : false,
    warranty : placeData?.isWarranty ? (placeData?.warranty === 'Inne' ? `${placeData?.warrantyMonths} mscy` : placeData?.warranty ) : '',
    isDeveloperContract : placeData?.isDeveloperContract
      ? Boolean(placeData?.isDeveloperContract)
      : activeContract?.isWarranty
      ? Boolean(activeContract.isWarranty)
      : false,
    developerContractDate : placeData?.developerContractDate
      ? String(placeData?.developerContractDate)
      : activeContract?.developerContractDate
      ? String(activeContract.developerContractDate)
      : null,
    placeID : selectedPlace?.id ? Number(selectedPlace?.id) : 1,
    numberOfOwners : numberOfOwners,
    isBusinessPurchase : placeData?.isBusinessPurchase
      ? Boolean(placeData?.isBusinessPurchase)
      : activeContract?.isBusinessPurchase
      ? Boolean(activeContract.isBusinessPurchase)
      : false
  };
};

const mutations = {
  saveContractWithCustomers,
  saveContractWithCustomerCompanies,
  getContractVariablesFromForm
};

export default mutations;
