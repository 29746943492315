import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IChanges } from '../services/correspondenceService';
import EventServices, { IEventFileInput, IEventInput, IEventQuery } from '../services/eventsService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';


interface IEventsState {
    events : IEventQuery;
    history : IChanges[];
};

const initialState : IEventsState = {
    events : { getContract : { events : [] } },
    history : []
};

export const getAllEvents = createAsyncThunk('events/getAllEvents', async () => {
    return EventServices.getAllEvents();
});

export const getEventByContractID = createAsyncThunk('events/getEventByContractID', async (contractID : number) => {
    return EventServices.getEventByContractID(contractID);
});

export const saveEvent = createAsyncThunk('events/saveEvent', async (event : IEventInput) => {
    return EventServices.saveEvent(event);
});

export const saveEventFile = createAsyncThunk('events/saveEventFile', async (eventFile : IEventFileInput) => {
    return EventServices.saveEventFile(eventFile);
});

export const deleteEvent = createAsyncThunk('events/deleteEvent', async (eventID : number) => {
    return EventServices.deleteEvent(eventID);
});

export const fetchEventsChanges = createAsyncThunk('events/fetchEventsChanges', async (arg, thunkAPI) => {
    const contractID = (thunkAPI.getState() as RootState).globalInvestment.chosenPlacesContract?.activeContracts[0]?.id as number;
    return EventServices.fetchEventsChanges(contractID);
});



const eventsSlice = createSlice({
    name : 'events',
    initialState,
    reducers : {},
    extraReducers : {
        [getEventByContractID.fulfilled.toString()] : (state, action) : void => {
            state.events = action.payload;
        },
        [saveEvent.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:saveEvent')));
            state.events.getContract.events = [...state.events.getContract.events, action.payload.saveEvent];
        },
        [saveEventFile.fulfilled.toString()] : (state, action) : void => {
            const eventFile = action.payload.saveEventFile;
            const savedEventIndex = state.events.getContract.events.findIndex(ev => ev.id === eventFile.eventID);
            state.events.getContract.events[savedEventIndex].eventFile = eventFile;
        },
        [deleteEvent.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:deleteEvent')));
            const deletedEventID = action.meta.arg;
            const filteredEvents = state.events.getContract.events.filter(ev => ev.id !== deletedEventID);
            state.events.getContract.events = filteredEvents;
        },
        [fetchEventsChanges.fulfilled.toString()] : (state, action) : void => {
            state.history = action.payload.getEventsChangesByContract;
        }
    }   
});


export default eventsSlice.reducer;
