import * as React from 'react';
import AppLoader from '../Loader/Loader';
import { useTableContext } from './TableContext';
import '../Table/Table.scss';

export type TableBodyProps = {
  multipleRowSelect ?: boolean;
  noSelectRows ?: boolean;
  onRowClick ?: (arg : any) => void;
  onTableClick ?: any;
  setChosenRowIndex ?: (arg : number) => void;
  timeout ?: number;
  orderRows ?: boolean;
  isLoading ?: boolean;
}


const TableBody : React.FC<TableBodyProps> = (
  { multipleRowSelect,
    noSelectRows = false,
    onRowClick = () : void => {},
    onTableClick = () : void => {},
    setChosenRowIndex = () : void => {},
    timeout,
    orderRows,
    isLoading }) => {
 const { headerGroups, getTableProps, data, page, prepareRow, getTableBodyProps,
  selectedFlatRows, toggleAllRowsSelected, state : { pageIndex }, customLoading } = useTableContext();

 const selectRow = React.useCallback((e : React.MouseEvent<HTMLDivElement, MouseEvent>, row : any, index : number) : void => {
   if (noSelectRows) {
     return;
   }
   onRowClick(row.original);
   setChosenRowIndex(index);
   if (!multipleRowSelect) {
    toggleAllRowsSelected(false);
  }
  if (multipleRowSelect && selectedFlatRows.length > 1 && !(e.ctrlKey || e.metaKey)) {
    toggleAllRowsSelected(false);
  }
  multipleRowSelect
  ? !(e.ctrlKey || e.metaKey) && !noSelectRows && page.map(row => row.isSelected && row.toggleRowSelected())
  : !noSelectRows && page.map(row => row.isSelected && row.toggleRowSelected());
  !noSelectRows && row.toggleRowSelected();

 }, [multipleRowSelect, noSelectRows, toggleAllRowsSelected, page, onRowClick, setChosenRowIndex, selectedFlatRows.length]);

 if (isLoading && !customLoading) {throw new Error('Add customLoading prop to Table component if you want to use isLoading prop');}
 return (
  <div {...getTableProps()} className='table sticky'  style={{ backgroundColor : '#f2f2f2' }}>
    <div className='header'>
    {headerGroups.map(headerGroup => (
      <div {...headerGroup.getHeaderGroupProps()} className='tr'>
        {headerGroup.headers.map((column : any) => (
          <div {...column.getHeaderProps(column.getSortByToggleProps({ title : column.fullName }))} className='th'>
           {column.render('Header')}
          </div>))}
      </div>))}
    </div>
      {(customLoading ? isLoading : !data?.length)
        ? (<div className='d-flex justify-content-center w-100'>
            <AppLoader timeout={timeout} />
          </div>)
        : (
        <div {...getTableBodyProps()} className='body' onClick={onTableClick}>
          {page.map((row, index : number) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className='tr' onClick={(e) : void => selectRow(e, row, index)}>
              {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className={`td ${(row.isSelected) ? 'rowSelected' : ''}`}>
                   {orderRows && cell.column.Header === 'L.p.' ? <div className='tr'>{pageIndex * 10 + index + 1}</div> : cell.render('Cell')}
                </div>);})}
              </div>
              );})}
        </div>)}
  </div>
 );
};
export default TableBody;
