import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export interface IFile {
  id : number;
  contractID : number;
  name : string;
  length : number;
  category : string;
  created : string;
  description : string;
  content : string;
}

export interface IDownloadFileProps {
  filename : string;
  content : string;
}

export interface IOpenBase64PdfFileProps {
  content : string;
}

export interface ISaveFileInput {
  event : any;
  contractID : number;
  category : string;
  description : string;
}

export interface IFileInput {
  contractID : number;
  name : string;
  length : number;
  category : string;
  created : string;
  description : string;
  content : string;
}

const saveFile = (file : IFileInput) : Promise<any> => {
  const SAVE_FILE_FOR_CONTRACT = gql`
    mutation saveFile($file: FileInput!) {
      saveFile(file: $file) {
        id
        name
        length
        category
        contractID
        content
        created
        description
      }
    }
  `;

  return GraphqlService.send(SAVE_FILE_FOR_CONTRACT, { file });
};

export const fetchAllFiles = () : Promise<any> => {
  const FETCH_ALL_FILES = gql`
    query {
      files {
        id
        name
        length
        category
        content
        created
        contractID
        description
      }
    }
  `;

  return GraphqlService.send(FETCH_ALL_FILES, {});
};

const fetchFileContentByFileID = (fileID : number) : Promise<any> => {
  const FETCH_FILE_CONTENT_BY_ID = gql`
    query ffcbfi($fileID: Float!) {
      file(fileID: $fileID) {
        id
        name
        length
        contractID
        category
        content
        created
        description
      }
    }
  `;

  return GraphqlService.send(FETCH_FILE_CONTENT_BY_ID, { fileID });
};

export const deleteFileByID = (fileID : number) : Promise<any> => {
  const DELETE_FILE_BY_ID = gql`
    mutation deleteFile($fileID: Float!) {
      deleteFile(fileID: $fileID)
    }
  `;

  return GraphqlService.send(DELETE_FILE_BY_ID, { fileID });
};

const importPlaces = (investmentID : number, content : string, isTypeService : boolean) : Promise<any> => {
  const IMPORT_PLACES = gql`
    mutation($investmentID:Float!, $content: String!, $isTypeService: Boolean!) {
      importPlaces(investmentID:$investmentID, content: $content, isTypeService: $isTypeService)
    }
  `;
  return GraphqlService.send(IMPORT_PLACES, { investmentID, content, isTypeService });
};

const downloadFile = ({ filename, content } : IDownloadFileProps) : void => {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const downloadFileSavedByUser = ({ filename, content } : IDownloadFileProps) : void => {
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  downloadLink.href = content;
  downloadLink.target = '_self';
  downloadLink.download = filename;
  downloadLink.click();
};

const downloadXlsxReport = (content : string, filename : string) : void => {
  const byteCharacters = atob(content);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const openPdfFileSavedByUser = ({ content } : IOpenBase64PdfFileProps) : void => {
  const pdfContentString = content?.includes('data:application/pdf;base64,') ? content?.replace('data:application/pdf;base64,', '') : content;
  const binaryString = window.atob(pdfContentString);
  const emptyBytesArray = new Uint8Array(binaryString.length);
  const bytes = emptyBytesArray.map((_, index) => binaryString.charCodeAt(index));

  const blob = new Blob([bytes], { type : 'application/pdf' });
  window.open(URL.createObjectURL(blob), '_blank');
};

const openBase64PdfFile = ({ content } : IOpenBase64PdfFileProps) : void => {
  const url = window.URL.createObjectURL(new Blob([content], { type : 'application/pdf' }));
  window.open(url, '_blank');
};

const actions = {
  saveFile,
  fetchAllFiles,
  fetchFileContentByFileID,
  deleteFileByID,
  downloadFile,
  downloadFileSavedByUser,
  openBase64PdfFile,
  openPdfFileSavedByUser,
  downloadXlsxReport,
  importPlaces
};

export default actions;
