import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';
import { fetchDSPBlueListReport } from '../../ducks/reports';
import Report from './Report';

const BlueListSalesReport : FunctionComponent = () => {
  const { DSPBlueListReport } = useSelector((state : RootState) => state.reports);
  
  return (
    <Report
      reportData={DSPBlueListReport}
      reportName='Raport-Błękitna lista.xlsx'
      reduxAction={fetchDSPBlueListReport}
    />
  );
};

export default BlueListSalesReport;
