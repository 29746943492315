import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAnnexFileInput } from '../services/annexFilesService';
import AnnexFilesService from '../services/annexFilesService';
import FileService from '../services/fileService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

export interface IAnnexFile extends IAnnexFileInput {
    id : number;
}

interface IAnnexFilesState {
    protocolsFiles : IAnnexFile[];
    annexFiles : IAnnexFile[];
    disclaimerFiles : IAnnexFile[];
    error : string | null;
};

const initialState : IAnnexFilesState = {
    protocolsFiles : [],
    annexFiles : [],
    disclaimerFiles : [],
    error : null
};

export const fetchAllFilesByContractID = createAsyncThunk(
    'annexFiles/fetchFiles',
    async (contractID : number) => {
        return AnnexFilesService.getFilesByContractID(contractID);
    }
);

export const addFileToContract = createAsyncThunk(
    'annexFiles/addFile',
    async (file : IAnnexFileInput) => {
        return AnnexFilesService.saveAnnexFileForContract(file);
    }
);

export const updateFileFromContract = createAsyncThunk(
    'annexFiles/updateFile',
    async ({ file, fileID } : {file : Partial<IAnnexFileInput>; fileID : number}) => {
        return AnnexFilesService.updateAnnexFileFromContract(file, fileID);
    }
);

export const deleteAnnexFileFromContract = createAsyncThunk(
    'annexFiles/deleteAnnexFile',
    async (fileID : number) => {
        return AnnexFilesService.deleteAnnexFileForContract(fileID);
    }
);

export const deleteProtocolFileFromContract = createAsyncThunk(
    'annexFiles/deleteProtocolFile',
    async (fileID : number) => {
        return AnnexFilesService.deleteAnnexFileForContract(fileID);
    }
);

export const deleteDisclaimerFileFromContract = createAsyncThunk(
    'annexFiles/deleteDisclaimerFile',
    async (fileID : number) => {
        return AnnexFilesService.deleteAnnexFileForContract(fileID);
    }
);

export const getAnnexFileContentById = createAsyncThunk(
  'annexFiles/deleteDisclaimerFile',
  async (variables : {fileID : number; fileName : string}) => {
    const result = await AnnexFilesService.fetchAnnexFileContentById(variables.fileID);

    if (result && result.file.content){
      FileService.downloadFileSavedByUser({ filename : variables.fileName, content : result.file.content });
    }
  }
);

const annexFilesSlice = createSlice({
    name : 'annexFiles',
    initialState,
    reducers : {
        clearDownloadedFiles ( state ) : void {
            state.disclaimerFiles = [];
            state.annexFiles = [];
            state.protocolsFiles = [];
        }
    },
    extraReducers : {
        [fetchAllFilesByContractID.fulfilled.toString()] : (state, action) : void => {
            const files = action.payload.getContract.files;
            const newProtocolsFiles = files.filter((file : IAnnexFile) => file.category === 'protocols');
            const newAnnexFiles = files.filter((file : IAnnexFile) => file.category === 'annex');
            const newDisclaimerFiles = files.filter((file : IAnnexFile) => file.category === 'disclaimer');
            state.protocolsFiles = newProtocolsFiles;
            state.annexFiles = newAnnexFiles;
            state.disclaimerFiles = newDisclaimerFiles;
        },
        [addFileToContract.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:addFile')));
            const newFile = action.payload.saveFile;
            if (newFile.category === 'annex') {
                state.annexFiles = [...state.annexFiles, newFile];
            } else if (newFile.category === 'protocols') {
                state.protocolsFiles = [...state.protocolsFiles, newFile];
            } else {
                state.disclaimerFiles = [...state.disclaimerFiles, newFile];
            }
        },
        [updateFileFromContract.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:updateFile')));
            const newFile = action.payload.updateFile;
            if (newFile.category === 'annex') {
                const index = state.annexFiles.findIndex((file : { id : number }) => file.id = newFile.id);
                state.annexFiles[index] = newFile;
            } else if (newFile.category === 'protocols') {
                const index = state.protocolsFiles.findIndex((file : { id : number }) => file.id = newFile.id);
                state.protocolsFiles[index] = newFile;
            } else {
                const index =  state.disclaimerFiles.findIndex((file : { id : number }) => file.id = newFile.id);
                state.disclaimerFiles[index] = newFile;
            }
        },
        [deleteAnnexFileFromContract.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:removeFile')));
            const deletedFileID = action.meta.arg;
            const filteredFiles = state.annexFiles.filter(annexFile => annexFile.id !== deletedFileID);
            state.annexFiles = filteredFiles;
        },
        [deleteProtocolFileFromContract.fulfilled.toString()] : (state, action) : void => {
            const deletedFileID = action.meta.arg;
            const filteredFiles = state.protocolsFiles.filter(protocolFile => protocolFile.id !== deletedFileID);
            state.protocolsFiles = filteredFiles;
        },
        [deleteDisclaimerFileFromContract.fulfilled.toString()] : (state, action) : void => {
            const deletedFileID = action.meta.arg;
            const filteredFiles = state.disclaimerFiles.filter(disclaimerFile => disclaimerFile.id !== deletedFileID);
            state.disclaimerFiles = filteredFiles;
        }
    }
});

export const {
    clearDownloadedFiles
} = annexFilesSlice.actions;

export default annexFilesSlice.reducer;
