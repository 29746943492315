import React, { FC } from 'react';
import './Reports.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';

import { fetchDARArrangementReport } from '../../ducks/reports';
import Report from './Report';

const DARArrangementReport : FC = () => {

  const { DARArrangementReport } = useSelector((state : RootState) => state.reports);

  return (
    <Report
      reportName="RaportAranżacje.xlsx"
      reportData={DARArrangementReport}
      reduxAction={fetchDARArrangementReport}
    />
  );
};

export default DARArrangementReport;
