import { gql } from 'graphql-request';
import { IParkingPlace } from '../ducks/parkingPlaces';
import { IUtilityRoom } from '../ducks/utilityRooms';
import GraphqlService from './graphqlService';

export interface IGarageInput {
    buildingID : number;
    name : string;
    description : string;
    isTypeService : boolean;
}

export interface IGarageGroundPlanInput {
    name : string;
    length : number;
    category : string;
    created : string;
    description : string;
    content : string;
    garageID : number;
}

export interface IGarageGroundPlanFile extends IGarageGroundPlanInput {
    id : number;
}

export interface IGarage {
    id : number;
    name : string;
    description : string;
    isTypeService : boolean;
    buildingID : number;
    building : {
      name : string;
      stageID : number;
    };
    groundPlans : IGarageGroundPlanFile[];
    utilityRooms ? : IUtilityRoom[];
    parkingPlaces ? : IParkingPlace[];
}

export const getAllGarages = () : Promise<any> => {
    const FETCH_ALL_GARAGES = gql`
        query {
            garages {
                id
                name
                description
                isTypeService
                buildingID
                groundPlans {
                    id
                    name
                    length
                    category
                    created
                    description
                    content
                    garageID
                }
                utilityRooms {
                    id
                    isTypeService
                    number
                }
                parkingPlaces {
                    id
                    isTypeService
                    name
                }
                building {
                  name
                  isTypeService
                  stageID
                }
            }
        }
    `;

    return GraphqlService.send(FETCH_ALL_GARAGES, {});
};

export const getGaragesByInvestmentNames = (investmentsNames : string[]) : Promise<any> => {
    const FETCH_GARAGES_BY_INVESTMENT_NAMES = gql`
        query ($investmentsNames: [String!]!) {
            garagesByInvestmentsNames(investmentsNames: $investmentsNames) {
                id
                name
                description
                isTypeService
                buildingID
                groundPlans {
                    id
                    name
                    length
                    category
                    created
                    description
                    content
                    garageID
                }
                building {
                  name
                  stageID
                  isTypeService
                }
            }
        }
    `;

    return GraphqlService.send(FETCH_GARAGES_BY_INVESTMENT_NAMES, { investmentsNames });
};

export const addGarage = (garage : IGarageInput) : Promise<any> => {
    const ADD_GARAGE = gql`
        mutation sg($garage: GarageInput!) {
            saveGarage(garage: $garage) {
                id
                name
                description
                isTypeService
                buildingID
                groundPlans {
                    id
                    name
                    length
                    category
                    created
                    description
                    content
                    garageID
                }
            }
        }
    `;

    return GraphqlService.send(ADD_GARAGE, { garage });
};

export const deleteGarage = (garageID : number) : Promise<any> => {
    const DELETE_GARAGE = gql`
        mutation deleteGarage($garageID : Float!) {
            deleteGarage(garageID : $garageID)
        }
    `;

    return GraphqlService.send(DELETE_GARAGE, { garageID });
};

export const updateGarage = ({ garageID, garage } : {garageID : number; garage : IGarageInput}) : Promise<any> => {
    const UPDATE_GARAGE = gql`
        mutation sc4($garageID : Float!, $garage: GarageInput!) {
            updateGarage(garageID : $garageID, garage:$garage) {
                id
                name
                isTypeService
                description
                buildingID
                groundPlans {
                    id
                    name
                    length
                    category
                    created
                    description
                    content
                    garageID
                }
            }
        }
    `;

    return GraphqlService.send(UPDATE_GARAGE, { garageID, garage });
};

export const saveGroundPlanIntoGarage = (file : IGarageGroundPlanInput) : Promise<any> => {
    const SAVE_GROUND_PLAN_GARAGE = gql`
        mutation saveFile($file: FileInput!) {
            saveFile(file: $file) {
                id
                name
                length
                category
                content
                created
                description
            }
        }
    `;

  return GraphqlService.send(SAVE_GROUND_PLAN_GARAGE, { file });
};

export default {
    getAllGarages,
    getGaragesByInvestmentNames,
    addGarage,
    deleteGarage,
    updateGarage,
    saveGroundPlanIntoGarage
};
