import { faQuestionCircle, faTimesCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeycloak } from '@react-keycloak/web';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IUserProfile {
  firstName : string;
  lastName : string;
  username : string;
}

const HeaderUser : FunctionComponent = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState({} as IUserProfile);

  const isUserAuthenticated = keycloak?.authenticated;

  useEffect(() => {
    if (keycloak?.authenticated) {
      keycloak.loadUserProfile()
        .then(() => setUserProfile(keycloak.profile as IUserProfile));
    }
  }, [isUserAuthenticated, keycloak]);

  const logout = () : void => {
    keycloak && keycloak.logout()
      .then(() => localStorage.setItem('idp:token', ''));
  };

  return (
    <Col >
      <div className="user-panel">
        <FontAwesomeIcon icon={faUser} className="fontIcon" />
      </div>
      <div className="user-panel2">
        <p >{userProfile.firstName} {userProfile.lastName}</p>
      </div>
      <div className="user-panel3">
        <FontAwesomeIcon icon={faQuestionCircle} className="fontIcon" />
        <p className="small ">{t('commons:actions.help')}</p>
      </div>
      <div className="user-panel3 clickableGroup" onClick={logout}>
        <FontAwesomeIcon icon={faTimesCircle} className="fontIcon" />
        <p className="small ">{t('commons:actions.logout')}</p>
      </div>
    </Col>
  );
};

export default HeaderUser;
