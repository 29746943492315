import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';
import { useTableContext } from './TableContext';

const TableSearch : React.FC = () => {
 const { t } = useTranslation();
 const { globalFilter, setGlobalFilter } = useTableContext();

 const [value, setValue] = React.useState(globalFilter);
 const onChange = useAsyncDebounce((value) => {
   setGlobalFilter(value || undefined);
 }, 200);

 return (
  <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
   <span>
      <input
        className='search-input'
        value={value || ''}
        onChange={(e) : void => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${t('commons:labels.type-searched-keyword')}...`}
      />
    </span>
  </Col>
 );
};

export default TableSearch;
