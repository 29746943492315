import { gql } from 'graphql-request';
import { IParkingPlace } from '../graphql/parkingPlaces';
import { IUtilityRoom } from '../graphql/utilityRooms';
import { IArrangement } from './arrangementsService';
import { IGarageGroundPlanInput } from './garagesService';
import GraphqlService from './graphqlService';
import { GenericPaginationProps } from './interestedCustomerService';

export interface IPlaceGroundPlan  {
  id : number;
  name : string;
  length : number;
  category : string;
  created : string;
  description : string;
  content : string;
  placeID ?: number;
}

export interface IPlaceGroundPlanInput {
  name : string;
  length : number;
  category : string;
  created : string;
  description : string;
  content : string;
  placeID ?: number;
}

export interface IAdminPlace {
  id : number;
  buildingID : number;
  staircase : number;
  floor : number;
  flatNumber : number;
  designedUsablePropertyArea : number;
  designedInnerPropertyArea : number;
  asBuildUsablePropertyArea : number;
  asBuildInnerPropertyArea : number;
  roomCount : number;
  isTypeService : boolean;
  contracts : {
    id : number;
    contractNo : string;
  }[];
  administrativeAddress ?: string;
  administrativeCode1 : string;
  administrativeCode2 : string;
  placeCode : string;
  landStripCode : string;
  landStripDescription : string;
  parkingPlaces : IParkingPlace[];
  utilityRooms : IUtilityRoom[];
  landStrips ?: {
    id : number;
    name : string;
  };
  building : {
    id : number;
    isTypeService : boolean;
    name : string;
  };
  stage : {
    id : number;
    name : string;
    street : string;
  };
  investment : {
    id : number;
    name : string;
  };
  activeContracts ?: {
    id : number;
    contractNo : string;
  }[];
  cancelledContracts ?: {
    id : number;
    contractNo : string;
  }[];
  archivedContracts ?: {
    id : number;
    contractNo : string;
  }[];
  arrangement ?: IArrangement;
  groundPlan ?: IPlaceGroundPlan;
}

export interface IPlaceInput {
  buildingID : number;
  staircase : number;
  floor : number;
  flatNumber : number;
  designedInnerPropertyArea : number;
  designedUsablePropertyArea : number;
  asBuildInnerPropertyArea : number;
  asBuildUsablePropertyArea : number;
  roomCount : number;
  isTypeService : boolean;
  administrativeCode1 : string;
  placeCode : string;
  landStripCode : string;
}

export interface IGroundPlanPlaceInput {
  placeID : number;
  name : string;
  length : number;
  category : string;
  created : string;
  description : string;
  content : string;
}

export interface PlacesPaginationProps extends GenericPaginationProps {
  investmentName ?: string;
  stageID ?: number;
}

export interface PlacesByInvestmentsNamesForAdminPanel extends GenericPaginationProps {
  investmentsNames : string[];
  stageID : number | null;
}


/* for main view we NEED TO FETCH fields, as groundPlans for places and garages in buildings,
  the data for table, activeContracts[0].id, contractCustomers and contractCompanyCustomers */

export const getPlacesByInvestmentNameForMainTable = (props : PlacesPaginationProps) : Promise<any> => {
  const FETCH_PLACES_BY_INVESTMENT_NAME_FOR_MAIN_TABLE = gql`
    query($investmentsNames: [String!]!, $page: Float, $perPage: Float, $stageID: Float, $isTypeService: Boolean) {
      paginatedPlacesByInvestmentsNames(
        investmentsNames : $investmentsNames,
        page: $page,
        perPage: $perPage,
        stageID: $stageID,
        isTypeService: $isTypeService
      )
      {
        total
        places {
          id
          isTypeService
          placeCode
          roomCount
          administrativeCode1
          administrativeCode2
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          building {
            garages {
              id
              name
              groundPlans {
                id
                name
                content
              }
            }
          }
          groundPlan {
            id
            name
            content
          }
          investment {
            name
          }
          stage {
            id
            street
          }
          activeContracts {
            id
            contractNo
            parkingPlaces {
              name
              garage {
                id
              }
            }
            contractCustomers {
              id
            }
            contractCustomerCompanies {
              id
              businessName
            }
            utilityRooms {
              number
              designedInnerUtilRoomArea
              asBuildInnerUtilRoomArea
              asBuildUsableUtilRoomArea
              garage {
                id
              }
            }
          }
          parkingPlaces {
            name
            garage {
              id
            }
          }
          utilityRooms {
            number
            designedInnerUtilRoomArea
            asBuildInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            garage {
              id
            }
          }
          contracts {
            id
            isArchived
            cancelledContract
            contractDealDate
            cancelledContract
            isArchived
            contractualAcceptanceDate
            contractualActDate
            acceptanceDate
            actDate
            valorization
            isCredit
            isEmailInvoice
            contractCustomers {
              isBusiness
              isSpouseOfMainCustomer
              isAgentOfCustomerSpouse
              isAgentOfMainCustomer
              isSpouseOfMainCustomer
              firstName
              lastName
              maritalStatus
            }
            contractCustomerCompanies {
              id
              businessName
              isAgentOfMainCustomer
            }
          }
        }
      }
    }
  `;

  const { investmentName, page, perPage } = props;
  return GraphqlService.send(FETCH_PLACES_BY_INVESTMENT_NAME_FOR_MAIN_TABLE, {
    investmentsNames : [investmentName],
    page,
    perPage,
    isTypeService : props?.isTypeService ?? undefined,
    stageID : props?.stageID ?? null
  });
};

const getPlacesByInvestmentsNamesForInterestedCustomers = (investmentsNames : string[]) : Promise<any> => {
  const FETCH_QUERY = gql`
    query($investmentsNames: [String!]!) {
      placesByInvestmentsNames(investmentsNames: $investmentsNames) {
        id
        placeCode
      }
    }
  `;
  return GraphqlService.send(FETCH_QUERY, { investmentsNames });
};


export const getPlacesByInvestmentsNamesForAdminPanel = (investmentsNames : string[]) : Promise<any> => {
  const FETCH_ADMIN_PLACES_BY_INVESTMENTS_NAMES = gql`
    query($investmentsNames: [String!]!) {
      placesByInvestmentsNames(investmentsNames: $investmentsNames) {
        id
        buildingID
        staircase
        floor
        flatNumber
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        isTypeService
        roomCount
        isTypeService
        contracts {
          id
          contractNo
        }
        administrativeCode1
        administrativeCode2
        placeCode
        landStripCode
        landStripDescription
        parkingPlaces {
          id
          name
          isTypeService
          type
          comment
          isExternal
          garageID
          placeID
          garage {
            id
            isTypeService
            name
          }
          building {
            id
            isTypeService
            name
          }
        }
        utilityRooms {
          id
          number
          isTypeService
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
          comment
          garageID
          placeID
          garage {
            id
            isTypeService
            name
          }
          building {
            id
            isTypeService
            name
          }
        }
        landStrips {
          id
          name
        }
        building {
          id
          isTypeService
          name
        }
        stage {
          id
          name
          street
        }
        investment {
          id
          name
        }
        activeContracts {
          id
          contractNo
        }
        cancelledContracts {
          id
          contractNo
        }
        archivedContracts {
          id
          contractNo
        }
        arrangement {
          id
          placeID
          arrangementFiles {
            id
            name
            content
            arrangementID
            creationDate
            trader
          }
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        groundPlan {
          id
          name
          length
          category
          created
          description
          content
          placeID
        }
      }
    }
  `;

  return GraphqlService.send(FETCH_ADMIN_PLACES_BY_INVESTMENTS_NAMES, {
    investmentsNames
  });
};



const getPaginatedPlacesByInvestmentsNamesForAdminPanel = (variables : PlacesByInvestmentsNamesForAdminPanel) : Promise<any> => {
  const FETCH_PLACES = gql`
    query(
      $investmentsNames: [String!]!
      $page: Float
      $perPage: Float
      $stageID: Float
      $isTypeService: Boolean
    ) {
      paginatedPlacesByInvestmentsNames(
        investmentsNames: $investmentsNames
        page: $page
        perPage: $perPage
        stageID: $stageID
        isTypeService: $isTypeService
      ) {
        total
        places {
          id
          buildingID
          flatNumber
          placeCode
          administrativeCode1
          administrativeCode2
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          designedUsablePropertyArea
          designedInnerPropertyArea
          asBuildUsablePropertyArea
          asBuildInnerPropertyArea
          roomCount
          staircase
          floor
          isTypeService
          contracts {
            id
            contractNo
          }
          parkingPlaces {
            id
            name
            isTypeService
            type
            comment
            isExternal
            garageID
            placeID
            garage {
              id
              isTypeService
              name
            }
            building {
              id
              isTypeService
              name
            }
          }
          utilityRooms {
            id
            number
            isTypeService
            designedInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            asBuildInnerUtilRoomArea
            comment
            garageID
            placeID
            garage {
              id
              isTypeService
              name
            }
            building {
              id
              isTypeService
              name
            }
          }
          building {
            name
          }
          parkingPlaces {
            name
          }
          investment {
            name
          }
          groundPlan {
            id
            name
            length
            category
            created
            description
            content
            placeID
          }
        }
      }
    }
  `;

  variables.stageID = variables.stageID === -1 ? null : variables.stageID;
  return GraphqlService.send(FETCH_PLACES, variables);
};

export const getPlacesByInvestmentStageId = (stageID : number) : Promise<any> => {
  const FETCH_PLACES_BY_INVESTMENTS_STAGE_ID_QUERY = gql`
    query($stagesIDs: [Float!]!) {
      placesByStages(stagesIDs: $stagesIDs) {
        id
        buildingID
        staircase
        floor
        flatNumber
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        roomCount
        isTypeService
        contracts {
          id
          contractNo
        }
        administrativeCode1
        administrativeCode2
        placeCode
        landStripCode
        landStripDescription
        parkingPlaces {
          id
          name
          isTypeService
          type
          comment
          isExternal
          garageID
          placeID
          garage {
            id
            isTypeService
            name
          }
          building {
            id
            isTypeService
            name
          }
        }
        utilityRooms {
          id
          number
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
          comment
          garageID
          placeID
          garage {
            id
            isTypeService
            name
          }
          building {
            id
            isTypeService
            name
          }
        }
        landStrips {
          id
          name
        }
        building {
          id
          isTypeService
          name
        }
        stage {
          id
          name
          street
        }
        investment {
          id
          name
        }
        activeContracts {
          id
          contractNo
        }
        cancelledContracts {
          id
          contractNo
        }
        archivedContracts {
          id
          contractNo
        }
        arrangement {
          id
          placeID
          arrangementFiles {
            id
            name
            content
            arrangementID
            creationDate
            trader
          }
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        groundPlan {
          id
          name
          length
          category
          created
          description
          content
          placeID
        }
      }
    }
  `;

  return GraphqlService.send(FETCH_PLACES_BY_INVESTMENTS_STAGE_ID_QUERY, {
    stagesIDs : [stageID]
  });
};

export const addPlace = (place : IPlaceInput) : Promise<any> => {
  const ADD_PLACE = gql`
    mutation ap($place: PlaceInput!) {
      savePlace(place: $place) {
        id
      }
    }
  `;

  return GraphqlService.send(ADD_PLACE, { place });
};

export const updatePlace = ({
  placeID,
  place
} : {
  placeID : number;
  place : IPlaceInput;
}) : Promise<any> => {
  const UPDATE_PLACE = gql`
    mutation sp($place: PlaceInput!, $placeID: Float!) {
      updatePlace(place: $place, placeID: $placeID) {
        id
      }
    }
  `;

  return GraphqlService.send(UPDATE_PLACE, { placeID, place });
};

export const deletePlace = (placeID : number) : Promise<any> => {
  const DELETE_PLACE = gql`
    mutation deletePlace($placeID: Float!) {
      deletePlace(placeID: $placeID)
    }
  `;

  return GraphqlService.send(DELETE_PLACE, { placeID });
};

export const saveGroundPlanIntoPlace = (
  file : IGroundPlanPlaceInput
) : Promise<any> => {
  const SAVE_GROUND_PLAN_PLACE = gql`
    mutation saveFile($file: FileInput!) {
      saveFile(file: $file) {
        id
        name
        length
        category
        content
        created
        description
      }
    }
  `;

  return GraphqlService.send(SAVE_GROUND_PLAN_PLACE, { file });
};

export interface IUpdateGroundPlan {
  fileID : number;
  file : IGroundPlanPlaceInput | IGarageGroundPlanInput;
}

export const editGroundPlane = (
  paramaters : IUpdateGroundPlan
) : Promise<any> => {
  const UPDATE_GROUND_PLAN = gql`
    mutation updateFile($file: FileInput!, $fileID: Float!) {
      updateFile(file: $file, fileID: $fileID) {
        id
        name
        length
        category
        content
        created
        description
      }
    }
  `;

  return GraphqlService.send(UPDATE_GROUND_PLAN, paramaters);
};

export const deleteGroundPlan = (fileID : number) : Promise<any> => {
  const DELETE_GROUND_PLAN = gql`
    mutation deleteFile($fileID: Float!) {
      deleteFile(fileID: $fileID)
    }
  `;

  return GraphqlService.send(DELETE_GROUND_PLAN, { fileID });
};

const fetchPlaceByPlaceID = (placeID : number) : Promise<any> => {
  const FETCH_PLACE_BY_PLACE_ID = gql`
    query($placeID: Float!) {
      place(placeID: $placeID) {
        id
        placeCode
        investment {
          name
          isArchive
        }
        stage {
          id
          street
        }
        arrangement {
          arrangementFiles {
            id
            name
            content
            arrangementID
            creationDate
            trader
          }
          id
          placeID
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        activeContracts {
          id
          customerCode
          placeID
          id
          comments
          contractNo
          customerCode
          isDeveloperContract
          developerContractDate
          street
          backyardTerrace
          roofTerrace
          garden
          repNumber
          otherContract
          lawContract
          deluxeContract
          valorization
          cancelledContract
          cancelledContractDate
          isEmailInvoice
          isCredit
          creditType
          creditSecurity
          lendingBank
          amountOfCredit1
          amountOfCredit2
          creditDealNo
          creditDealDate
          contractDealDate
          contractualAcceptanceDate
          contractualActDate
          acceptanceDate
          actDate
          notarialOffice
          isPledge
          pledge
          isWarranty
          warranty
          numberOfOwners
          contractCustomers {
            id
            isFromMigration
            uuid
            customerParentID
            purchasePurpose
            customerCode
            customerType
            customerID
            lastName
            firstName
            secondName
            maidenName
            parentsNames
            pesel
            taxNumber
            sex
            customerID
            phone1
            phone2
            email
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            isBusiness
            traderInitials
            businessName
            regon
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            businessAddressFlatNumber
            residenceName
            residenceSurname
            residenceBusinessName
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residenceFlatNumber
            residencePostalCode
            residenceVoivodeship
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            maritalStatus
            contractID
            customerInvestmentCode
            sourceInfo
            isBroker
            brokerName
            isCommissionDivision
            commissionDivision
            isToughCustomer
            isSpouseOfMainCustomer
            isAgentOfMainCustomer
            isAgentOfCustomerSpouse
            isBlueList
            purchasePurpose
            businessType
            businessPhone
            placeShares
            spouseFiles {
              id
              name
              created
            }
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
            }
          }
          contractCustomerCompanies {
            id
            uuid
            isFromMigration
            companyParentID
            businessName
            companyForm
            sourceInfo
            pesel
            krs
            taxNumber
            phone1
            phone2
            businessActivityRegister
            regon
            email
            businessType
            placeShares
            residenceName
            residenceSurname
            residenceBusinessName
            customerType
            purchasePurpose
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residencePostalCode
            residenceVoivodeship
            residenceFlatNumber
            customerID
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressFlatNumber
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            isInfoDutyFulfilled
            infoDutyForm
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            contractID
            customerInvestmentCode
            firstName
            lastName
            secondName
            businessPhone
            pesel
            isBroker
            brokerName
            customerCode
            isAgentOfMainCustomer
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
            }
          }
          parkingPlaces {
            id
            isTypeService
            name
            type
            isExternal
          }
          utilityRooms {
            id
            number
            isTypeService
            designedInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            asBuildInnerUtilRoomArea
          }

        }
        parkingPlaces {
          id
          name
          isTypeService
          type
          isExternal
        }
        placeCode
        roomCount
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        administrativeCode1
        administrativeCode2
        utilityRooms {
          id
          number
          isTypeService
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
        }
      }
    }
  `;
  return GraphqlService.send(FETCH_PLACE_BY_PLACE_ID, { placeID });
};
const fetchCancelledContractPlaceByPlaceID = (placeID : number) : Promise<any> => {
  const FETCH_PLACE_BY_PLACE_ID = gql`
    query($placeID: Float!) {
      place(placeID: $placeID) {
        id
        placeCode

        investment {
          name
          isArchive
        }
        parkingPlaces {
          id
          name
        }
        stage {
          id
          street
        }
        arrangement {
          arrangementFiles {
            id
            name
            content
            arrangementID
            creationDate
            trader
          }
          id
          placeID
          conductingPerson
          wallsAnnexSignatureDate
          wallsIsDeliveredToConstruction
          wallsDeliveredToConstructionDate
          sanitaryInstallationsAnnexSignatureDate
          sanitaryInstallationsIsDeliveredToConstruction
          sanitaryInstallationsDeliveredToConstructionDate
          electricInstallationsAnnexSignatureDate
          electricInstallationsIsDeliveredToConstruction
          electricInstallationsDeliveredToConstructionDate
          finishingWorksAnnexSignatureDate
          finishingWorksIsDeliveredToConstruction
          finishingWorksDeliveredToConstructionDate
        }
        cancelledContracts {
          id
          customerCode
          placeID
          id
          comments
          contractNo
          customerCode
          isDeveloperContract
          developerContractDate
          street
          backyardTerrace
          roofTerrace
          garden
          repNumber
          otherContract
          lawContract
          deluxeContract
          valorization
          cancelledContract
          cancelledContractDate
          isEmailInvoice
          isCredit
          creditType
          creditSecurity
          lendingBank
          amountOfCredit1
          amountOfCredit2
          creditDealNo
          creditDealDate
          contractDealDate
          contractualAcceptanceDate
          contractualActDate
          acceptanceDate
          actDate
          notarialOffice
          isPledge
          pledge
          isWarranty
          warranty
          numberOfOwners
          parkingPlaces {
            id
            name
          }
          utilityRooms {
            id
            number
          }
          contractCustomers {
            id
            uuid
            customerParentID
            purchasePurpose
            customerCode
            customerType
            customerID
            lastName
            firstName
            secondName
            maidenName
            parentsNames
            pesel
            taxNumber
            sex
            customerID
            phone1
            phone2
            email
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            isBusiness
            businessName
            regon
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            businessAddressFlatNumber
            residenceName
            residenceSurname
            residenceBusinessName
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residenceFlatNumber
            residencePostalCode
            residenceVoivodeship
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            maritalStatus
            contractID
            customerInvestmentCode
            sourceInfo
            isBroker
            brokerName
            isCommissionDivision
            commissionDivision
            isToughCustomer
            isSpouseOfMainCustomer
            isAgentOfMainCustomer
            isAgentOfCustomerSpouse
            isBlueList
            purchasePurpose
            businessType
            placeShares
            spouseFiles {
              id
              name
              created
            }
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
            }
          }
          contractCustomerCompanies {
            id
            uuid
            businessName
            companyForm
            sourceInfo
            pesel
            krs
            taxNumber
            phone1
            phone2
            businessActivityRegister
            regon
            email
            businessType
            placeShares
            residenceName
            residenceSurname
            residenceBusinessName
            customerType
            purchasePurpose
            residenceCountry
            residenceCity
            residenceStreet
            residenceNumber
            residencePostalCode
            residenceVoivodeship
            residenceFlatNumber
            customerID
            residenceIsForeign
            correspondenceName
            correspondenceSurname
            correspondenceBusinessName
            correspondenceFlatNumber
            correspondenceCountry
            correspondenceCity
            correspondenceStreet
            correspondenceNumber
            correspondencePostalCode
            correspondenceVoivodeship
            correspondenceIsForeign
            businessAddressName
            businessAddressSurname
            businessAddressBusinessName
            businessAddressCountry
            businessAddressFlatNumber
            businessAddressCity
            businessAddressStreet
            businessAddressNumber
            businessAddressNumber
            businessAddressPostalCode
            businessAddressVoivodeship
            businessAddressIsForeign
            isInfoDutyFulfilled
            infoDutyForm
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            contractID
            customerInvestmentCode
            firstName
            lastName
            pesel
            isBroker
            brokerName
            customerCode
            isAgentOfMainCustomer
            passportNumberAndSeries
            IDNumberAndSeries
            citizenship
            marketingConsents {
              customerID
              id
              isInfoDutyFulfilled
              infoDutyForm
              infoDutyFormDate
              isAgreementPhone
              agreementPhoneDate
              isAgreementPhoneCancelled
              agreementPhoneCancellationDate
              isAgreementEmail
              agreementEmailDate
              isAgreementEmailCancelled
              agreementEmailCancellationDate
              isMSWiAAgreement
            }
          }
          parkingPlaces {
            id
            isTypeService
            name
            type
            isExternal
          }
          utilityRooms {
            id
            number
            isTypeService
            designedInnerUtilRoomArea
            asBuildUsableUtilRoomArea
            asBuildInnerUtilRoomArea
          }
        }
        placeCode
        roomCount
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        administrativeCode1
        administrativeCode2
        utilityRooms {
          id
          number
          isTypeService
          designedInnerUtilRoomArea
          asBuildUsableUtilRoomArea
          asBuildInnerUtilRoomArea
        }
      }
    }
  `;
  return GraphqlService.send(FETCH_PLACE_BY_PLACE_ID, { placeID });
};

const updatePlaceRelatedToContract = (variables : any) : Promise<any> => {
  const UPDATE_PLACE_RELATED_CONTRACT_DATA_QUERY = gql`
  mutation uprcd($contract : ContractInput!, $utilityRoomsList : [Float!], $parkingPlacesList : [Float!],
    $contractID : Float!, $customersList : [Float!]!) {
      updateContract(contract : $contract, utilityRoomsList : $utilityRoomsList, contractID : $contractID,
        parkingPlacesList : $parkingPlacesList, customersList : $customersList) {
        id
    }
  }
`;
  return GraphqlService.send(UPDATE_PLACE_RELATED_CONTRACT_DATA_QUERY, variables);
};

const mutations = {
  getPlacesByInvestmentsNamesForInterestedCustomers,
  fetchCancelledContractPlaceByPlaceID,
  getPlacesByInvestmentsNamesForAdminPanel,
  getPlacesByInvestmentStageId,
  getPlacesByInvestmentNameForMainTable,
  addPlace,
  updatePlace,
  deletePlace,
  saveGroundPlanIntoPlace,
  deleteGroundPlan,
  editGroundPlane,
  fetchPlaceByPlaceID,
  updatePlaceRelatedToContract,
  getPaginatedPlacesByInvestmentsNamesForAdminPanel
};

export default mutations;
