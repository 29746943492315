import { ICustomerInput, IInterestedCustomer } from '../graphql/customers';
import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export interface IDSPCustomerSourceInfoReportProps {
  fromDate : string;
  toDate : string;
  investmentIDs : number[];
}

export const anonymizeInterestedCustomer = (
  customerID : number
) : Promise<any> => {
  const ANONYMIZE_INTERESTED_CUSTOMER_QUERY = gql`
    mutation anonymizeCustomer($customerID: Float!) {
      anonymizeCustomer(customerID: $customerID)
    }
  `;
  return GraphqlService.send(ANONYMIZE_INTERESTED_CUSTOMER_QUERY, {
    customerID
  });
};

const fetchInterestedCustomersByContractID = (contractID : number[]) : Promise<any> => {
  const FETCH_INTERESTED_CUSTOMERS = gql`
  query($contractID : [Float!]!) {
    customersByContractID(contractID:$contractID) {
         id
        lastName
        firstName
        secondName
        pesel
        phone1
        phone2
        email
        passportNumberAndSeries
        IDNumberAndSeries
        citizenship
        regon
        isDNKCustomer
        purchasePurpose
        isIKCustomer
        isToughCustomer
        isVIP
        isBlueList
        sourceInfo
        isBroker
        brokerName
        isCommissionDivision
        commissionDivision
        traderInitials
        marketingConsents {
          id
          isInfoDutyFulfilled
          infoDutyForm
          infoDutyFormDate
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          isMSWiAAgreement
        }
        contacts {
          id
          date
          name
          type
          comment
          resignationReason
          traderInitials
          nextContactName
          nextContactComment
          nextContactDate
          nextContactType
          isResignation
          isSigned
          customerID
        }
        proposedInvestments {
          id
          investmentID
          placeID
          investment {
            name
            stages {
              name
            }
            id
          }
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
        }
        proposedPlaces {
          id
          placeID
          customerID
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
        }

      }
    }
  `;
  return GraphqlService.send(FETCH_INTERESTED_CUSTOMERS, { contractID });
};

export const addNewInterestedCustomer = ({
  customer
} : {
  customer : IInterestedCustomer;
}) : Promise<any> => {
  const ADD_INTERESTED_CUSTOMER_QUERY = gql`
    mutation saveCustomerWithMarketingConsentsProposedPlacesAndContacts(
      $customer: CustomerInput!
    ) {
      saveCustomerWithMarketingConsentsProposedPlacesAndContacts(
        customer: $customer
      ) {
        id
      }
    }
  `;
  return GraphqlService.send(ADD_INTERESTED_CUSTOMER_QUERY, { customer });
};

export const updateInterestedCustomer = ({
  customer,
  customerID
} : {
  customer : Partial<ICustomerInput>;
  customerID : number;
}) : Promise<any> => {
  const UPDATE_INTERESTED_CUSTOMER_QUERY = gql`
    mutation updateCustomer($customer: CustomerInput!, $customerID: Float!) {
      updateCustomer(customer: $customer, customerID: $customerID) {
        id
      }
    }
  `;
  return GraphqlService.send(UPDATE_INTERESTED_CUSTOMER_QUERY, {
    customer,
    customerID
  });
};

export const deleteInterestedCustomer = (customerID : number) : any => {
  const DELETE_INTERESTED_CUSTOMER_QUERY = gql`
    mutation deleteCustomer($customerID: Float!) {
      deleteCustomer(customerID: $customerID)
    }
  `;
  return GraphqlService.send(DELETE_INTERESTED_CUSTOMER_QUERY, { customerID });
};

const fetchDSPCustomerSourceInfoReport = ({
  fromDate,
  investmentIDs,
  toDate
} : IDSPCustomerSourceInfoReportProps) : Promise<any> => {
  const FETCH_DSP_CUSTOMER_SOURCE_INFO_REPORT = gql`
    query dspcsir(
      $fromDate: String!
      $toDate: String!
      $investmentIDs: [Float!]!
    ) {
      DSPCustomerSourceInfoReport(
        fromDate: $fromDate
        toDate: $toDate
        investmentIDs: $investmentIDs
      )
    }
  `;
  return GraphqlService.send(FETCH_DSP_CUSTOMER_SOURCE_INFO_REPORT, {
    fromDate,
    toDate,
    investmentIDs
  });
};

export interface IContactInput {
  date : string | null;
  name : string;
  type : string;
  comment : string;
  traderInitials : string;
  resignationReason ?: string;
  nextContactName ?: string;
  nextContactComment ?: string;
  nextContactDate : string | null;
  nextContactType ?: string;
  isResignation : boolean;
  isSigned : boolean;
  customerID : number;
}

export interface IUpdateContactInput {
  contactID : number;
  contact : IContactInput;
}

const updateInterestedCustomerContact = (
  parameters : IUpdateContactInput
) : Promise<any> => {
  const UPDATE_CONTACT = gql`
    mutation updateContact($contact: ContactInput!, $contactID: Float!) {
      updateContact(contact: $contact, contactID: $contactID) {
        id
      }
    }
  `;

  return GraphqlService.send(UPDATE_CONTACT, parameters);
};

const deleteInterestedCustomerContact = (contactID : number) : Promise<any> => {
  const DELETE_CONTACT = gql`
    mutation deleteContact($contactID: Float!) {
      deleteContact(contactID: $contactID)
    }
  `;

  return GraphqlService.send(DELETE_CONTACT, { contactID });
};

const addInterestedCustomerContact = (contact : IContactInput) : Promise<any> => {
  const ADD_CONTACT = gql`
    mutation saveContact($contact: ContactInput!) {
      saveContact(contact: $contact) {
        id
      }
    }
  `;

  return GraphqlService.send(ADD_CONTACT, { contact });
};
const getInterestedCustomerByID = (customerID : number) : Promise<any> => {
  const GET_CUSTOMER = gql`
    query($customerID: Float!) {
      customer(customerID: $customerID) {
        id
        customerType
        lastName
        firstName
        isDNKCustomer
        phone1
        phone2
        email
        isIKCustomer
        isToughCustomer
        isVIP
        isBlueList
        sourceInfo
        isBroker
        brokerName
        isCommissionDivision
        commissionDivision
        traderInitials
        marketingConsents {
          id
          isInfoDutyFulfilled
          infoDutyForm
          infoDutyFormDate
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          isMSWiAAgreement
        }
        contacts {
          id
          date
          name
          type
          comment
          resignationReason
          traderInitials
          nextContactName
          nextContactComment
          nextContactDate
          nextContactType
          isResignation
          isSigned
          customerID
        }
        proposedInvestments {
          id
          investmentID
          placeID
          investment {
            name
            stages {
              name
            }
            id
          }
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
        }
        proposedPlaces {
          id
          placeID
          customerID
          place {
            id
            placeCode
            investment {
              id
              name
            }
          }
        }

        contracts {
          id
          contractNo
          contractDealDate
          customerCode
        }
      }
    }
  `;
  return GraphqlService.send(GET_CUSTOMER, { customerID });
};

export interface GenericPaginationProps {
  perPage : number;
  page : number;
  isDNKCustomer ?: boolean;
  isTypeService ?: boolean;
}

export interface CustomerPagination {
  total : number;
  customers : IInterestedCustomer[];
}

export interface CustomerPaginationResponse {
  paginateCustomers : CustomerPagination;
}

export interface CustomerPaginationByKeyWordsResponse {
  paginateCustomersByKeyWords : CustomerPagination;
}

export interface InterestedCustomerPaginationProps extends GenericPaginationProps {
  isBlueList ?: boolean;
  keyword ?: string;
}

export const fetchInterestedCustomersPagination = (
  props : InterestedCustomerPaginationProps
) : Promise<CustomerPaginationResponse> => {
  const FETCH_ALL_INTERESTED_CUSTOMERS_QUERY = gql`
    query($perPage: Float, $page: Float, $isDNKCustomer: Boolean, $isBlueList: Boolean) {
      paginateCustomers(perPage: $perPage, page: $page, isDNKCustomer: $isDNKCustomer, isBlueList: $isBlueList) {
        total
        customers {
          id
          customerType
          lastName
          firstName
          phone1
          phone2
          email
          isDNKCustomer
          maritalStatus
          isIKCustomer
          isToughCustomer
          isVIP
          isBlueList
          sourceInfo
          isBroker
          brokerName
          isCommissionDivision
          commissionDivision
          traderInitials
          marketingConsents {
            id
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
          }
          contacts {
            id
            date
            name
            type
            comment
            resignationReason
            traderInitials
            nextContactName
            nextContactComment
            nextContactDate
            nextContactType
            isResignation
            isSigned
            customerID
          }
          proposedInvestments {
            id
            investmentID
            placeID
            investment {
              name
              stages {
                name
              }
              id
            }
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
          }
          proposedPlaces {
            id
            placeID
            customerID
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
          }

          contracts {
            id
            contractNo
            customerCode
          }
        }
      }
    }
  `;
  return GraphqlService.send(FETCH_ALL_INTERESTED_CUSTOMERS_QUERY, {
    perPage : props.perPage,
    page : props.page,
    isDNKCustomer : props.isDNKCustomer,
    isBlueList : props.isBlueList
  });
};

export const fetchInterestedCustomersByKeywordPagination = (
  props : InterestedCustomerPaginationProps
) : Promise<CustomerPaginationResponse> => {
  const FETCH_ALL_INTERESTED_CUSTOMERS_QUERY = gql`
    query($perPage: Float, $page: Float, $keyWords: String!, $isDNKCustomer: Boolean) {
      paginateCustomersByKeyWords(perPage: $perPage, page: $page, keyWords: $keyWords, isDNKCustomer: $isDNKCustomer) {
        total
        customers {
          id
          customerType
          lastName
          firstName
          phone1
          phone2
          email
          isDNKCustomer
          maritalStatus
          isIKCustomer
          isToughCustomer
          isVIP
          isBlueList
          sourceInfo
          isBroker
          brokerName
          isCommissionDivision
          commissionDivision
          traderInitials
          marketingConsents {
            id
            isInfoDutyFulfilled
            infoDutyForm
            infoDutyFormDate
            isAgreementPhone
            agreementPhoneDate
            isAgreementPhoneCancelled
            agreementPhoneCancellationDate
            isAgreementEmail
            agreementEmailDate
            isAgreementEmailCancelled
            agreementEmailCancellationDate
            isMSWiAAgreement
          }
          contacts {
            id
            date
            name
            type
            comment
            resignationReason
            traderInitials
            nextContactName
            nextContactComment
            nextContactDate
            nextContactType
            isResignation
            isSigned
            customerID
          }
          proposedInvestments {
            id
            investmentID
            placeID
            investment {
              name
              stages {
                name
              }
              id
            }
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
          }
          proposedPlaces {
            id
            placeID
            customerID
            place {
              id
              placeCode
              investment {
                id
                name
              }
            }
          }

          contracts {
            id
            contractNo
            customerCode
          }
        }
      }
    }
  `;
  return GraphqlService.send(FETCH_ALL_INTERESTED_CUSTOMERS_QUERY, {
    perPage : props.perPage,
    page : props.page,
    keyWords : props.keyword,
    isDNKCustomer : props.isDNKCustomer,
    isBlueList : props.isBlueList
  });
};

const mutations = {
  getInterestedCustomerByID,
  addNewInterestedCustomer,
  anonymizeInterestedCustomer,
  deleteInterestedCustomer,
  updateInterestedCustomer,
  fetchDSPCustomerSourceInfoReport,
  updateInterestedCustomerContact,
  deleteInterestedCustomerContact,
  addInterestedCustomerContact,
  fetchInterestedCustomersByContractID,
  fetchInterestedCustomersPagination,
  fetchInterestedCustomersByKeywordPagination
};

export default mutations;
