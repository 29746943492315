import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRegisterPlaceForm } from '../components/SalesRegister/RegisterPlaceDataFormType';

interface IPersistCopyOfFormStore {
    copyOfRegisterPlaceData : Partial<IRegisterPlaceForm> | null;
    copyOfCustomersDataInNaturalPersonAgreement : any;
    copyOfCustomersDataInLegalPersonAgreement : any;
}

const initialState : IPersistCopyOfFormStore = {
    copyOfRegisterPlaceData : null,
    copyOfCustomersDataInNaturalPersonAgreement : null,
    copyOfCustomersDataInLegalPersonAgreement : null
};

const persistCopyOfFormSlice = createSlice({
    name : 'persistCopyOfForm',
    initialState,
    reducers : {
        pasteRegisterPlaceDataIntoPersistStore (state, action : PayloadAction<any>) : void {
            state.copyOfRegisterPlaceData = action.payload;
        },
        pasteCustomersDataInNaturalPersonAgreementIntoPersistStore (state, action : PayloadAction<any>) : void {
            state.copyOfCustomersDataInNaturalPersonAgreement = action.payload;
        },
        pasteCustomersDataInLegalPersonAgreementIntoPersistStore (state, action : PayloadAction <any>) : void {
            state.copyOfCustomersDataInLegalPersonAgreement = action.payload;
        },
        clearPersistRegisterSalesCopy (state) : void {
            state.copyOfRegisterPlaceData = null;
            state.copyOfCustomersDataInNaturalPersonAgreement = null;
            state.copyOfCustomersDataInLegalPersonAgreement = null;
        }
    },
    extraReducers : {}
});

export const {
    pasteRegisterPlaceDataIntoPersistStore,
    pasteCustomersDataInNaturalPersonAgreementIntoPersistStore,
    pasteCustomersDataInLegalPersonAgreementIntoPersistStore,
    clearPersistRegisterSalesCopy
} = persistCopyOfFormSlice.actions;

export default persistCopyOfFormSlice.reducer;