import React, { FunctionComponent } from 'react';
import './Reports.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';

import { fetchDNIActsReport } from '../../ducks/reports';
import Report from './Report';

const DNIActsReport : FunctionComponent = () => {
  const { DNIActsReport } = useSelector((state : RootState) => state.reports);

  return (
    <Report
      reportName="RaportDNIActy.xlsx"
      reportData={DNIActsReport}
      reduxAction={fetchDNIActsReport}
    />
  );
};

export default DNIActsReport;
