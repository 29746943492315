import * as React from 'react';
import { useTableOptions } from './useTableOptions';
import Pagination, { PaginationProps } from './Pagination';
import TableBody, { TableBodyProps } from './TableBody';
import { TableContext } from './TableContext';
import TableFooter from './TableFooter';
import RedirectButton, { RedirectButtonProps } from './RedirectButton';
import TableHeader, { TableHeaderProps } from './Header';
import SensitiveInfoButton, { SensitiveInfoButtonProps } from './SensitiveInfoButton';
import Filters from './Filters';
import TableSearch from './TableSearch';
import TableTitle from './TableTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../ducks';
import { setSelectedRows } from '../../../ducks/globalInvestment';
import { DNKRoles, FirstLastNameViewRoles } from '../../../ducks/globalUser';

import { hasRoles } from '../../../services/idp';
import PaginationWithFetch, { PaginationWithFetchProps } from './PaginationWithFetch';
import TableSearchWithPagination, { TableSearchWithPaginationProps } from './TableSearchWithPagination';

interface TableComposition {
  Pagination : React.FC<PaginationProps>;
  PaginationWithFetch : React.FC<PaginationWithFetchProps>;
  TableBody : React.FC<TableBodyProps>;
  Footer : React.FC;
  RedirectButton : React.FC<RedirectButtonProps>;
  Header : React.FC<TableHeaderProps>;
  SensitiveInfoButton : React.FC<SensitiveInfoButtonProps>;
  Filters : React.FC;
  TableSearch : React.FC;
  TableSearchWithPagination : React.FC<TableSearchWithPaginationProps>;
  Title : React.FC;
}

type TableProps<Data, Columns> = {
  data : Data[];
  columns : Columns[];
  chosenRowIndex ?: number;
  defaultNumberOfRows ?: number;
  isMainTable ?: boolean;
  isSelectAllCheckbox ?: boolean;
  reSelectAllOnChange ?: boolean;
  customLoading ?: boolean;
  children : React.ReactNode;
}


function Table<Data, Columns> ({
  children,
  data,
  columns,
  chosenRowIndex,
  defaultNumberOfRows = 10,
  isMainTable = false,
  isSelectAllCheckbox,
  reSelectAllOnChange = false,
  customLoading = false } : TableProps<Data, Columns>) : JSX.Element {

  const { roles } = useSelector((state : RootState) => state.globalUser);
  const tableOptions = useTableOptions({
    data, columns, chosenRowIndex, defaultNumberOfRows,
    initialHiddenData : hasRoles(FirstLastNameViewRoles, roles)
        ? (hasRoles(DNKRoles, roles)
            ? ['roomCount']
            : ['']
          )
      : ['firstName1', 'firstName2', 'lastName1', 'lastName2'],
  isSelectAllCheckbox
  });

  const value = React.useMemo(() => tableOptions, [tableOptions]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    isMainTable && dispatch(setSelectedRows(value.selectedFlatRows));
  }, [dispatch, value.selectedFlatRows, isMainTable]);

  React.useEffect(() => {
    isMainTable && tableOptions.toggleAllRowsSelected(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data, isMainTable]);

  React.useEffect(() => {
    reSelectAllOnChange && tableOptions.toggleAllRowsSelected(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reSelectAllOnChange]);

 return (
  <TableContext.Provider value={{ table : { ...value, customLoading } }}>
    <div className="table-container">
      {children}
    </div>
  </TableContext.Provider>
 );
};

Table.Pagination = Pagination;
Table.PaginationWithFetch = PaginationWithFetch;
Table.TableBody = TableBody;
Table.Footer = TableFooter;
Table.RedirectButton = RedirectButton;
Table.Header = TableHeader;
Table.SensitiveInfoButton = SensitiveInfoButton;
Table.Filters = Filters;
Table.TableSearch = TableSearch;
Table.TableSearchWithPagination = TableSearchWithPagination;
Table.Title = TableTitle;

export default Table;
