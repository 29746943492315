import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AppLoader from '../commons/Loader/Loader';

import { AppDispatch, RootState } from '../../ducks';
import { clearReportsState, downloadXlsxReport, fetchDKOSerialCorrespondenceReport } from '../../ducks/reports';

import './Reports.scss';

const DKOSerialCorrespondenceReport : FC = () => {

  const dispatch : AppDispatch = useDispatch();
  const history = useHistory();

  const { DKOSerialCorrespondenceReport } = useSelector((state : RootState) => state.reports);

  useEffect(() => {
    dispatch(fetchDKOSerialCorrespondenceReport());
    return () : void => {
      dispatch(clearReportsState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (DKOSerialCorrespondenceReport) {
      dispatch(downloadXlsxReport({ content : DKOSerialCorrespondenceReport, filename : 'Korespondencja-seryjna.xlsx' }))
      .then(() => {
        history.goBack();
      });
    }
  }, [DKOSerialCorrespondenceReport, dispatch, history]);

 return (
  <>
   { DKOSerialCorrespondenceReport ? (
      <div className="reports-container">
        <div className="reports-header">Wczytano raport, plik zaraz zostanie pobrany</div>
      </div>
    ) : (
      <div className="reports-container">
        <div className="reports-header">Trwa pobieranie raportu</div>
        <AppLoader />
      </div>
    )}
    </>
  );
};

export default DKOSerialCorrespondenceReport;
