import React, { FunctionComponent } from 'react';
import './Reports.scss';
import {  useSelector } from 'react-redux';
import {
  fetchDNIAdministrationReport
} from '../../ducks/reports';
import { RootState } from '../../ducks';
import Report from './Report';

const BuildingAdministratorsReport : FunctionComponent = () => {
  const { DNIAdministrationReport } = useSelector((state : RootState) => state.reports);

  return <Report reportData={DNIAdministrationReport} reportName='Raport-Administratora.xlsx' reduxAction={fetchDNIAdministrationReport} />;
};

export default BuildingAdministratorsReport;
