import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import {
  clearPlacesContracts,
  fetchInvestmentsWithStagesOnly,
  fetchPlacesContractsByInvestmentNameForMainTable,
  setChosenPlacesContract
} from '../../ducks/globalInvestment';
import { checkReminderTraderInitials, fetchCustomersByDailyReminders, fetchNotesByUserID } from '../../ducks/reminder';
import { IContract } from '../../graphql/contracts/contracts';
import { IPlaceContractsQuery } from '../../graphql/currentPlaceContracts/currentPlaceContracts';
import { ICustomersReminder } from '../../services/reminderService';
import { getMainTableColumns } from '../App/mainTableColumns';
import Reminder from '../Reminder/Reminder';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import Table from '../commons/Table2/Table';
import { useTranslation } from 'react-i18next';
import { hasRoles } from '../../services/idp';
import { difference } from 'lodash-es';
import { USER_ROLES } from '../../constants/user';
import { Col, Row } from 'react-bootstrap';
import ToggleSumBar from '../commons/Table/ToggleSumBar';
import GroundPlanButton from '../commons/Table2/GroundPlanButton';
import {
  CorrespondenceEventsViewRoles,
  DNKRoles,
  DSPRoles,
  FirstLastNameViewRoles,
  GenericServiceEntity,
  serviceTypePredicate
} from '../../ducks/globalUser';
import { useKeycloak } from '@react-keycloak/web';
import { PlacesPaginationProps } from '../../services/placesService';
import { push } from 'connected-react-router';

interface IMainTableProps {
  chosenRowIndex : number;
  setChosenRowIndex(arg : number) : void;
}

const customerAccessRoles = difference(USER_ROLES, ['DPP']);
const toggleSumBarAccessRoles = difference(USER_ROLES, ['DAR']);
const sensitiveColums = ['firstName1', 'firstName2', 'lastName1', 'lastName2'];

const MainTable : React.FC<IMainTableProps> = (
  { chosenRowIndex, setChosenRowIndex }
) => {

  const dispatch : AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const {
    chosenInvestment,
    chosenPlacesContract,
    chosenStage,
    placesContracts,
    amountOfPlacesContracts,
    isLoading
  } = useSelector((state : RootState) => state.globalInvestment);
  const { profile, roles, tokenParsed } = useSelector((state : RootState) => state.globalUser);
  const checkReminders = useSelector(checkReminderTraderInitials);
  const { todayNotes, areNotesDownloaded } = useSelector((state : RootState) => state.reminder);

  const [isReminderModalVisible, setIsReminderModalVisible] = useState(false);
  const [customersReminder, setCustomersReminder] = useState<null | ICustomersReminder[]>(null);
  const today = moment().format(DATE_FORMAT_YYYYMMDD);
  const hasPlaceActiveContract = chosenPlacesContract?.activeContracts?.length;
  const isNoContract = Boolean(!(hasPlaceActiveContract));
  const isDNKCustomer = hasRoles(DNKRoles, roles || []);
  const isDSPCustomer = hasRoles(DSPRoles, roles || []);

  const serviceTypeFilter = useCallback((entity : GenericServiceEntity) => {
    return serviceTypePredicate<GenericServiceEntity>(entity, isDNKCustomer, isDSPCustomer);
  }, [isDNKCustomer, isDSPCustomer]);

  const getActiveContract = useCallback((contracts : IContract[]) : IContract | undefined => {
    return contracts.find(contract => !contract.cancelledContract && !contract.isArchived);
  }, []);

  const mainTableColumns = useMemo(() => getMainTableColumns({ getActiveContract }), [getActiveContract]);
  const closeReminderModal = useCallback(() => setIsReminderModalVisible(false), [setIsReminderModalVisible]);

  const onSelectPlaceContract = useCallback((row : IPlaceContractsQuery) => {
    const sameAsPrevious = row.id === chosenPlacesContract?.id;
    if (sameAsPrevious) {
      return dispatch(setChosenPlacesContract(null));
    }
    const newChosenPlaceContract = placesContracts.find((placeContract : IContract) => placeContract.id === row.id);
    dispatch(setChosenPlacesContract(newChosenPlaceContract));

  }, [chosenPlacesContract, dispatch, placesContracts]);

  const getSortedPlacesContracts = useMemo(() => {
    // This will be probably deleted
    return placesContracts.filter(serviceTypeFilter);
  }, [placesContracts, serviceTypeFilter]);

  useEffect(() => {
    dispatch(clearPlacesContracts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenInvestment.id, chosenStage.id]);

  useEffect(() => {
    const validReminders = checkReminders();
    setCustomersReminder(validReminders);
  }, [checkReminders]);

  const [lastTimeLoggedIn, setLastTimeLoggedIn] = useState(localStorage.getItem('lastTimeLoggedIn'));
  useEffect(() => {
    if (lastTimeLoggedIn !== today && keycloak?.authenticated && tokenParsed?.sub) {
      dispatch(fetchCustomersByDailyReminders()).then(() => {
        dispatch(fetchNotesByUserID(tokenParsed?.sub || ''));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTimeLoggedIn, tokenParsed?.sub]);

  useEffect(() => {
    dispatch(fetchInvestmentsWithStagesOnly());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const todayReminders = Boolean(customersReminder?.length) || Boolean(todayNotes.length);
    const canRemindersBeCheck = lastTimeLoggedIn !== today && profile && areNotesDownloaded && todayReminders !== null;
    if (canRemindersBeCheck) {
      if (todayReminders) {
        setIsReminderModalVisible(true);
      }
      setLastTimeLoggedIn(today);
      localStorage.setItem('lastTimeLoggedIn', today);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areNotesDownloaded]);

  const getParametersForPlacesQuery = useCallback(() : Partial<PlacesPaginationProps> => {
    return {
      investmentName : chosenInvestment?.name ?? '',
      stageID : chosenStage?.id === -1 ? null : chosenStage?.id
    } as Partial<PlacesPaginationProps>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenInvestment?.name, chosenStage.id]);

   useEffect(() => {
    const isSelecetedCanceledContracts = chosenInvestment.id === 0;
    isSelecetedCanceledContracts && dispatch(push('/cancelled-contracts'));
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch]);

  return (
    <>
      <Reminder
        show={isReminderModalVisible}
        customersBody={customersReminder || []}
        closeFunction={closeReminderModal}
        myTasksBody={todayNotes}
      />
      <Table
        columns={mainTableColumns}
        data={getSortedPlacesContracts}
        chosenRowIndex={chosenRowIndex}
        isMainTable
        isSelectAllCheckbox
        customLoading
        >
        <Table.Header align='between'>
          <Table.Filters />
          {hasRoles(FirstLastNameViewRoles, roles) && (
            <Table.SensitiveInfoButton sensitiveColumns={sensitiveColums} />
          )}
          <Table.TableSearch />
        </Table.Header>
        <Table.TableBody
          multipleRowSelect
          onRowClick={onSelectPlaceContract}
          setChosenRowIndex={setChosenRowIndex}
          timeout={9999999999}
          orderRows
          isLoading={isLoading}
        />
        <Row className={'d-flex align-items-center justify-content-between'}>
          {hasRoles(toggleSumBarAccessRoles, roles) && (
            <Col className='d-flex justify-content-start' lg={8} md={12} sm={12}>
              <ToggleSumBar data={getSortedPlacesContracts} />
            </Col>)}
          <Table.PaginationWithFetch
            genericFetch={fetchPlacesContractsByInvestmentNameForMainTable}
            totalRows={amountOfPlacesContracts}
            additionalParameters={getParametersForPlacesQuery()}
          />
        </Row>

        <Table.Footer>
          <Table.RedirectButton path={isNoContract ? '/' : '/valid-contract/place'} disabled={isNoContract}>
            {t('commons:menu.place')}
          </Table.RedirectButton>
          {hasRoles(customerAccessRoles, roles) ?
            <Table.RedirectButton path={isNoContract ? '/' : '/valid-contract/customer'} disabled={isNoContract}>
              {t('commons:menu.customer')}
            </Table.RedirectButton> : null}
          {hasRoles(CorrespondenceEventsViewRoles, roles) ?
            <Table.RedirectButton path={isNoContract ? '/' : '/valid-contract/contract'} disabled={isNoContract}>
              {t('commons:menu.contract')}
            </Table.RedirectButton> : null}
          <GroundPlanButton />
        </Table.Footer>
      </Table>

    </>
  );
};
export default MainTable;
