import * as React from 'react';
import { TableInstance } from 'react-table';

type TableContextType = {
  table : TableInstance<object>;
}

export const TableContext = React.createContext({} as TableContextType);

export const useTableContext = () : TableInstance<object> => {
    const context = React.useContext(TableContext).table;
    if (!context) {
      throw new Error(
        'Table compound components cannot be rendered outside the Table component'
      );
    };
    return context;
 };