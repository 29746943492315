import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GaragesService, { IGarage, IGarageGroundPlanInput, IGarageInput } from '../services/garagesService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface IGaragesState {
    allGarages : IGarage[];
    garagesByInvestmentsNames : IGarage[];
}

const initialState : IGaragesState = {
    allGarages : [],
    garagesByInvestmentsNames : []
};

export const getAllGarages = createAsyncThunk(
    'garages/getAllGarages',
    async () => {
        return GaragesService.getAllGarages();
    }
);

export const getGaragesByInvestmentNames = createAsyncThunk(
    'garages/getGaragesByInvestmentNames',
    async (investmentsNames : string[]) => {
        return GaragesService.getGaragesByInvestmentNames(investmentsNames);
    }
);

export const addNewGarage = createAsyncThunk(
    'garages/addNewGarage',
    async (garage : IGarageInput) => {
        return GaragesService.addGarage(garage);
    }
);

export const deleteGarage = createAsyncThunk(
    'garages/deleteGarage',
    async (garageID : number) => {
        return GaragesService.deleteGarage(garageID);
    }
);

export const updateGarage = createAsyncThunk(
    'garages/updateGarage',
    async (variables : {garageID : number; garage : IGarageInput}) => {
        return GaragesService.updateGarage(variables);
    }
);

export const addGroundPlanGarage = createAsyncThunk(
    'garages/addGroundPlanGarage',
    async (file : IGarageGroundPlanInput) => {
        return GaragesService.saveGroundPlanIntoGarage(file);
    }
);

const garagesSlice = createSlice({
    name : 'garages',
    initialState,
    reducers : {},
    extraReducers : {
        [getAllGarages.fulfilled.toString()] : (state, action) : void => {
            state.allGarages = action.payload.garages;
        },
        [getGaragesByInvestmentNames.fulfilled.toString()] : (state, action) : void => {
            state.garagesByInvestmentsNames = action.payload.garagesByInvestmentsNames;
        },
        [addNewGarage.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:addGarage')));
            const newGarage = action.payload.saveGarage;
            state.allGarages = [...state.allGarages, newGarage];
        },
        [deleteGarage.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:deleteGarage')));
            const deletedGarageID = action.meta.arg;
          state.allGarages = state.allGarages.filter(garage => garage.id !== deletedGarageID);
        },
        [updateGarage.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:editGarage')));
            const editedGarage = action.payload.updateGarage;
            const editedGarageIndex = state.allGarages.findIndex(garage => garage.id === editedGarage.id);
            state.allGarages[editedGarageIndex] = editedGarage;
        },
        [addGroundPlanGarage.fulfilled.toString()] : (state, action) : void => {
            successToastNotify(String(i18n.t('toast:addGroundPlan')));
        }
    }
});

export default garagesSlice.reducer;
