import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SimpleInputList from '../commons/Inputs/SimpleInputs/SimpleInputList';
import HeaderBreadcrumb from './HeaderBreadcrumb';
import HeaderLogo from './HeaderLogo';
import HeaderSearch from './HeaderSearch';
import HeaderUser from './HeaderUser';

import { IInvestment, IStage } from '../../graphql/investments';

import './Header.scss';
import { switchArchiveMode } from '../../ducks/globalInvestment';
import { RootState } from '../../ducks';
import { refetchPagination } from '../commons/Table2/paginationSlice';


interface IHeaderProps {
  onChangeChosenInvestment : (selectedInvestment : IInvestment) => void;
  onChangeChosenStage : (selectedStage : IStage) => void;
  departmentView : string;
  onSearchChange ?: (phrase : string) => void;
  onSearchSubmit : (phrase : string) => void;
}

const Header : FunctionComponent<IHeaderProps> = (
  props : IHeaderProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onChangeChosenInvestment, onChangeChosenStage, departmentView, onSearchChange, onSearchSubmit } = props;
  const { chosenInvestment, chosenStage, investmentsList, isArchiveMode } = useSelector((state : RootState) => state.globalInvestment);

  const onChangeInvestmentValue = (selectedInvestment : IInvestment) : void => {
    const selectedInvestmentFromTheList = investmentsList.find(investment => investment.id === selectedInvestment.id) || selectedInvestment;
    onChangeChosenInvestment(selectedInvestmentFromTheList);
  };

  const onChangeStageValue = (selectedStage : IStage) : void => {
    onChangeChosenStage(selectedStage);
  };

  const initialStagesListOptions = useMemo(() => [{ id : -1, name : 'Wszystkie' }], []);
  const stagesListOptions = useMemo(() => {
    if (chosenInvestment.stages?.length) {
      const sortedStagesInChosenInvestment = [...chosenInvestment.stages].sort((a, b) => ('' + a.name).localeCompare(b.name));
      return [...initialStagesListOptions, ...sortedStagesInChosenInvestment];
    } else {
      return initialStagesListOptions;
    }
  }, [chosenInvestment.stages, initialStagesListOptions]);


  const location = useLocation();
  const { pathname } = location;

  const isIvestmentAndStageInputListDisabled = pathname.indexOf('admin') === -1 && pathname.length > 1;

  const notArchivedInvestments = useMemo(() : IInvestment[] => investmentsList.filter((investment : IInvestment) => investment.isArchive === isArchiveMode), [investmentsList, isArchiveMode]);
  const allInvestments = useMemo(() : IInvestment[] => [...investmentsList].map((investment : IInvestment) => {
    if (investment.isArchive) {
      const { id, isArchive, name, stages } = investment;
      return {
        id,
        isArchive,
        stages,
        name : `${name} (archiwum)`
      };
    }
    return investment;
  }), [investmentsList]);

  const activeInvestment = useMemo(() => investmentsList.filter((i) => { return i.id === chosenInvestment.id; }), [investmentsList, chosenInvestment]);
  const activeStage = useMemo(() => stagesListOptions.filter((s) => { return s.id === chosenStage.id; }), [stagesListOptions, chosenStage]);

  const handleArchiveSwitch = useCallback(() => {
    dispatch(switchArchiveMode());
    dispatch(refetchPagination(true));
  }, [dispatch]);

  return (
    <nav id="nav" className="navbar">
      <Col className="sticky-top">
        <Row className="d-flex align-items-center ik2-light-color-1">
        <div className="mt-2">
          <Col sm={3} md={1} lg={1}>
            <HeaderLogo />
          </Col>
          <Col sm={3} md={2} lg={2}>
            <HeaderSearch
              onChange={onSearchChange}
              onSubmit={onSearchSubmit}
              />
          </Col>
          <div className="d-flex align-items-center col-md-6">
            <Form className="d-flex align-items-center justify-content-center ">
              <Row className="d-flex align-items-center extra-class">
                <div className="d-flex flex-col">
                  <Form.Label>{t('commons:labels.investment')}:</Form.Label>
                </div>
                {departmentView === 'DSP-ADM' && (
		            <div className="d-flex flex-col">
                  <Form.Check
                    className="d-flex flex-column align-items-center text-center archives-checkbox"
                    type="checkbox"
                    label={t('commons:labels.Archives')}
                    defaultChecked={isArchiveMode}
                    onClick={handleArchiveSwitch}
                  />
                  </div>)}
              </Row>
              <Row className="extra-class">
                <div className="d-flex flex-col">
                  <SimpleInputList
                    defaultValue={activeInvestment[0]}
                    disabled={isIvestmentAndStageInputListDisabled && pathname.indexOf('cancelled-contracts') === -1}
                    onChangeValue={onChangeInvestmentValue}
                    label={t('commons:labels.investment').toString()}
                    customSelectWidth={6}
                    options={isArchiveMode ? allInvestments : notArchivedInvestments}
                  />
                </div>
              </Row>
              <Row className="extra-class">
                <div className="d-flex flex-col">
                  <SimpleInputList
                    defaultValue={activeStage[0]}
                    disabled={isIvestmentAndStageInputListDisabled || !chosenInvestment.stages?.length}
                    onChangeValue={onChangeStageValue}
                    label={t('commons:labels.stage').toString()}
                    customSelectWidth={6}
                    options={stagesListOptions}
                  />
                </div>
              </Row>
            </Form>
          </div>
          <Col sm={6} md={3} className="mx-0 px-0 ik2-light-color-1">
            <HeaderUser />
          </Col>
        </div>
        </Row>
        <Row className="d-flex justify-content-start ik2-light-color-1">
          <div className="mx-5">
            <HeaderBreadcrumb />
          </div>
        </Row>
      </Col>
    </nav>
  );
};

export default Header;
