import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import Report from './Report';

import './Reports.scss';

import { RootState } from '../../ducks';
import { fetchDSPCancelledContractsReport } from '../../ducks/reports';


const DSPCancelledContractsReport : FunctionComponent = () => {

  const { DSPCancelledContractsReport } = useSelector((state : RootState) => state.reports);

  return (
    <Report
      reportName="Raport-umowy-anulowane.xlsx"
      reportData={DSPCancelledContractsReport}
      reduxAction={fetchDSPCancelledContractsReport}
    />
  );
};

export default DSPCancelledContractsReport;
