import * as React from 'react';
import { Col, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ColumnInstance } from 'react-table';
import ToggleContainer from '../Containers/ToggleContainer';
import { useTableContext } from './TableContext';
import { hasRoles } from '../../../services/idp';
import { FirstLastNameViewRoles } from '../../../ducks/globalUser';
import { useSelector } from 'react-redux';
import { RootState } from '../../../ducks';

const Filters : React.FC = () => {
  const { allColumns } = useTableContext();
  const { t } = useTranslation();
  const { roles } = useSelector((state : RootState) => state.globalUser);

  return (
    <Col
      className="d-flex align-items-top justify-content-start my-2"
      lg={8}
      md={12}
      sm={12}>
      <ToggleContainer
        arrowColor="#000000"
        barColor="main-bg-color"
        customWidth={97.7}
        title={t('commons:labels.column-menu')}>
        <InputGroup>
          {allColumns
            .filter((column : ColumnInstance) => {
              const col = column.id;

              if (col === 'firstName1' || col === 'firstName2' || col === 'lastName1' || col === 'lastName2'){
                return hasRoles(FirstLastNameViewRoles, roles);
              } else {
                return true;
              }
            })
            .map(
              (column : ColumnInstance, index : number) =>
                column.id !== 'selection' &&
                column.id !== 'orderNo' &&
                column.id !== 'investmentCode' &&
                column.id !== 'propertyNo' && (
                  <Col lg={3} key={`col${column.id}${index}`}>
                    <label key={`label${column.id}${index}`}>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                        key={`input${column.id}${index}`}
                      />{' '}
                      {column.Header}
                    </label>
                  </Col>
                )
            )}
        </InputGroup>
      </ToggleContainer>
    </Col>
  );
};
export default Filters;
