import React, { FunctionComponent } from 'react';
import './Reports.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';

import { fetchDKOAreasReport } from '../../ducks/reports';
import Report from './Report';

const DKOAreasReport : FunctionComponent = () => {
  const { DKOAreasReport } = useSelector((state : RootState) => state.reports);

 return <Report reportName="Raport-powierzchni.xlsx" reportData={DKOAreasReport} reduxAction={fetchDKOAreasReport} />;
};

export default DKOAreasReport ;