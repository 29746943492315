import { createAsyncThunk } from '@reduxjs/toolkit';
import { IMarketingConsentInput } from '../graphql/marketingConsents';
import MarketingConsentsService from '../services/marketingConsents';
import { IUpdateMarketingConsent } from '../services/marketingConsents';

export const defaultMarketingConsents = {
  isAgreementEmailCancelled : false,
  agreementEmailDate : undefined,
  isAgreementEmail : false,
  isAgreementPhoneCancelled : false,
  agreementPhoneDate : undefined,
  isAgreementPhone : false,
  infoDutyForm : '',
  isMSWiAAgreement : false,
  agreementEmailCancellationDate : undefined,
  agreementPhoneCancellationDate : undefined,
  infoDutyFormDate : undefined,
  isInfoDutyFulfilled : false,
  customerID : undefined
} as IMarketingConsentInput;

export interface IMarketingConsentsState {
  uuid : string;
  customerID ?: number;
  consents : IMarketingConsentInput;
}

export const updateMarketingConsent = createAsyncThunk(
  'marketingConsents/updateMarketingConsent',
  async ({ marketingConsent, marketingConsentID } : Partial<IUpdateMarketingConsent>) => {
    return MarketingConsentsService.updateMarketingConsent({
      marketingConsent,
      marketingConsentID
    });
  }
);
