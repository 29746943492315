import * as React from 'react';
import {  useSelector } from 'react-redux';
import {  RootState } from '../../ducks';
import { fetchDARDateAnnexesReport } from '../../ducks/reports';
import Report from './Report';


const DARDateAnnexesReport : React.FC = () => {
 const { DARDateAnnexesReport } = useSelector((state : RootState) => state.reports);
 return <Report reportName="Raport-aneksy.xlsx" reportData={DARDateAnnexesReport} reduxAction={fetchDARDateAnnexesReport} />;
};
export default DARDateAnnexesReport;
