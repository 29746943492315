/* eslint-disable @typescript-eslint/camelcase */
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from '../commons/Table/Table';
import InputCheckbox from '../commons/Inputs/InputCheckbox';
import SearchRegularCustomers_Columns from '../../mockups/SearchRegularCustomers_Columns.json';
import SearchRegularCustomers_Rows from '../../mockups/SearchRegularCustomers_Rows.json';
import SearchInterestedCustomers_Columns from '../../mockups/SearchInterestedCustomers_Columns.json';
import SearchInterestedCustomers_Rows from '../../mockups/SearchInterestedCustomers_Rows.json';
import _ from 'lodash';

interface ISearchbarProps {
  phrase : string;
}

interface IRowProps {
  'customer1' : {};
  'customer2' ? : {};
}

const Search : FunctionComponent <ISearchbarProps> = (
  props : ISearchbarProps
) => {
  const { t } = useTranslation();
  const { phrase } = props;

  const phraseTmp : string[] = phrase.split('');
  const index : number = phraseTmp.indexOf('%') !== -1 ? phrase.indexOf('%') : -1;
  const half1 : string = index !== -1 ? phrase.substring(0, index) : phrase;
  const half2 : string = index !== -1 ? phrase.substring(index + 1) : '';
  const [regularChecked, setRegularChecked] = useState(true);
  const [interestedChecked, setInterestedChecked] = useState(true);

  const filteredRows = (rows : IRowProps[]) => _.filter(rows, (item : IRowProps) => (
    String(Object.values(item['customer2'] ? item['customer1'] || item['customer2'] : item['customer1'])).toLowerCase().includes(String(half1 || half2).toLowerCase())
  ));

  const columnsRegularData = useMemo(
    () => SearchRegularCustomers_Columns,
    []);
  const columnsInterestedData = useMemo(
    () => SearchInterestedCustomers_Columns,
    []);
  const rowsRegularData = useMemo(() => SearchRegularCustomers_Rows, []);
  const rowsInterestedData = useMemo(() => SearchInterestedCustomers_Rows, []);
  const filteredRowsRegular = filteredRows(rowsRegularData);
  const filteredRowsInterested = filteredRows(rowsInterestedData);

  return (
    <Container fluid>
      <Row className="d-flex align-items-center justify-content-center search-narrow-results">
        <Col lg="auto" md="auto" sm="auto">
          <span>{t('table:labels.narrow-search-results-to')}:</span>
        </Col>
        <Col lg="auto" md="auto" sm="auto">
          <InputCheckbox defaultChecked={regularChecked} id="regular-customers-searchbar-checkbox" label={t('table:labels.regular-customers')}
            onClick={() : void => setRegularChecked(!regularChecked)} />
        </Col>
        <Col lg="auto" md="auto" sm="auto">
          <InputCheckbox customWidth={200} defaultChecked={interestedChecked} id="interested-customers-searchbar-checkbox"
            label={t('table:labels.interested-customers')} onClick={() : void => setInterestedChecked(!interestedChecked)} />
        </Col>
      </Row>
      {regularChecked && (
      <Table
        addRowForTitle
        columns={columnsRegularData}
        data={filteredRowsRegular}
        isColumnsMenuComp
        isFilter
        isGenerateDataRow
        title={`${t('customers:labels:all-investments-customers')}:`}
      />)}
      {interestedChecked && (
      <Table
      addRowForTitle
        columns={columnsInterestedData}
        data={filteredRowsInterested}
        title={`${t('customers:labels:in-interested-customers')}:`}
        isColumnsMenuComp
        isFilter
        isGenerateDataRow
        />)}
    </Container>
  );
};

export default Search;
