import React, { FunctionComponent } from 'react';
import './Reports.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../ducks';
import { fetchDNIShareTableReport } from '../../ducks/reports';
import Report from './Report';

const DNIShareTableReport  : FunctionComponent = () => {

  const { DNIShareTableReport } = useSelector((state : RootState) => state.reports);

  return (
    <Report
      reportData={DNIShareTableReport}
      reportName='Raport-udziałów.xlsx'
      reduxAction={fetchDNIShareTableReport}
    />
  );

};

export default DNIShareTableReport;
