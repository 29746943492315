import React, { FunctionComponent, useCallback, useState } from 'react';

import './SimpleInputs.scss';

interface ISimpleInputCheckboxProps {
  additionalOnChange ?: (e : any) => void;
  customLabelWidth ?: number | string;
  defaultChecked ?: boolean;
  disabled ?: boolean;
  input ?: {
    value : string;
  };
  label : string | number;
  wordWrap ?: boolean;
  currentChecked ?: boolean;
  externalChecked ?: boolean;
  name ?: string;
}

export const SimpleInputCheckbox : FunctionComponent<ISimpleInputCheckboxProps> = ({
  additionalOnChange,
  customLabelWidth,
  defaultChecked,
  disabled,
  input : inputFromReduxFormField,
  label,
  wordWrap = false,
  currentChecked,
  externalChecked,
  name
}) => {
  const [checked, setChecked] = useState(
    currentChecked ? currentChecked : defaultChecked ? defaultChecked : false
  );
  const onChange = useCallback(
    (event) : void => {
      setChecked(!checked);
      if (additionalOnChange) {
        return additionalOnChange(event);
      }
    },
    [additionalOnChange, setChecked, checked]
  );

  const stylesForWordWrapping = {
    width : customLabelWidth ? `${customLabelWidth}em` : '10em',
    height : '70px',
    whiteSpace : 'normal',
    textAlign : 'left'
  };

  const defaultStyles = {
    width : customLabelWidth ? `${customLabelWidth}em` : '10em'
  };

  return (
    <div className="d-flex align-items-center">
      <label className="simple-input-label">
        <span
          className="input-group-text simple-input-label-span"
          style={wordWrap ? stylesForWordWrapping : defaultStyles}>
          <input
            {...inputFromReduxFormField}
            className="simple-input-checkbox"
            type="checkbox"
            disabled={disabled}
            onChange={onChange}
            checked={externalChecked ?? checked}
            name={name || ''}
          />
          {label}
        </span>
      </label>
    </div>
  );
};

export default SimpleInputCheckbox;
