import * as React from 'react';
import { Button } from 'react-bootstrap';
import InputDate from '../commons/Inputs/InputDate';
import moment from 'moment';
import 'moment/locale/pl';
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import InputCheckbox from '../commons/Inputs/InputCheckbox';
import {
  downloadXlsxReport,
  fetchDSPCustomersByBrokersContractsReport,
  fetchDSPCustomersByBrokersReport
} from '../../ducks/reports';
import { useTranslation } from 'react-i18next';

import brokersName from '../../mockups/brokersName.json';

interface Props {
    isFromContract : boolean;
}

const DSPCustomersByBrokersReport : React.FC<Props> = ({ isFromContract }) => {
    const { t } = useTranslation();
    const investmentList = useSelector((state : RootState) => state.globalInvestment.investmentsList);
    const isLoading = useSelector((state : RootState) => state.combinedReports.DSPCustomersByBrokersReport.isLoading);
    const dispatch : AppDispatch = useDispatch();

    const [fromDate, setFromDate] = React.useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [toDate, setToDate] = React.useState(moment().format(DATE_FORMAT_YYYYMMDD));
    const [investmentIDs, setInvestmentIDs] = React.useState<number[]>([]);
    const [brokerNames, setBrokerNames] = React.useState<string[]>([]);

    const updateFromDate = React.useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setFromDate(formatedDate);
    }, []);

    const updateToDate = React.useCallback((day : Date) => {
        const formatedDate = moment(day).format(DATE_FORMAT_YYYYMMDD);
        setToDate(formatedDate);
    }, []);

    const updateSelectedInvestments = React.useCallback((id : number) => {
        const investmentIsSelected = investmentIDs.includes(id);
        if (investmentIsSelected) {
           return setInvestmentIDs((prevState : number[]) => prevState.filter(inv => inv !== id));
        }
        setInvestmentIDs((prevState : number[]) => [...prevState, id]);
    }, [investmentIDs]);

    const updateBrokers = React.useCallback((traderInitial : string) => {
        const brokersIsSelected = brokerNames.includes(traderInitial);
        if (brokersIsSelected) {
           return setBrokerNames((prevState : string[]) => prevState.filter(inv => inv !== traderInitial));
        }
        setBrokerNames((prevState : string[]) => [...prevState, traderInitial]);
    }, [brokerNames]);

    const generateCustomersByBrokersReport = React.useCallback(() => {
        dispatch(fetchDSPCustomersByBrokersReport({ investmentIDs, fromDate, toDate, brokerNames }))
        .then((response : any) => {
            if (response.payload?.DSPCustomersByBrokersReport) {
                return dispatch(downloadXlsxReport({
                  content : response.payload.DSPCustomersByBrokersReport,
                  filename : 'Zainteresowani od pośredników.xlsx'
                }));
            }
            alert('Błąd podczas pobierania raportu');
        });
    }, [investmentIDs, toDate, fromDate, dispatch, brokerNames ]);

    const generateCustomersByBrokersContractsReport = React.useCallback(() => {
        dispatch(fetchDSPCustomersByBrokersContractsReport({ investmentIDs, fromDate, toDate, brokerNames }))
        .then((response : any) => {
            if (response.payload?.DSPCustomersByBrokersContractsReport) {
                return dispatch(downloadXlsxReport({
                  content : response.payload.DSPCustomersByBrokersContractsReport,
                  filename : 'Zainteresowani umowy od pośredników.xlsx'
                }));
            }
            alert('Błąd podczas pobierania raportu');
        });
    }, [investmentIDs, toDate, fromDate, dispatch, brokerNames ]);

    const handleClick = () : void => {
        if (isFromContract) {
            generateCustomersByBrokersContractsReport();
        } else {
            generateCustomersByBrokersReport();
        }
    };


    return (
      <div className="container">
        <div className='pb-4 px-2'>
          <Button disabled={!investmentIDs.length || isLoading} onClick={handleClick}>
            {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
          </Button>
        </div>
        <div className="column">
          <p className='px-3'>{t('commons:actions.select-dates')}</p>
          <div className="inputs-wrapper pb-4">
            <InputDate id="1" onDayChange={updateFromDate} customWidth={350} text={t('commons:labels.from')} />
            <InputDate id="2" onDayChange={updateToDate} customWidth={350} text={t('commons:labels.to')} />
          </div>
          <p className='px-3 pb-1'>{t('commons:actions.select-investment')}</p>
          <div className="checkbox-wrapper pb-4">
            {investmentList?.map(investment => (
              <InputCheckbox
                id={investment.name}
                label={investment.name}
                customWidth={150}
                key={investment.id}
                onClick={() : void => updateSelectedInvestments(investment.id)}
              />
            ))}
          </div>
          <p className='px-3 pb-1'>{t('commons:actions.select-broker')}</p>
          <div className="checkbox-wrapper-brokers">
          {/* TODO: FETCH BROKERS */}
          {brokersName?.map((broker, index) => {
            return broker.length === 1 ? null :
              <InputCheckbox
                id={broker}
                label={broker}
                customWidth={350}
                key={index}
                onClick={() : void => updateBrokers(broker)}
              />;
            })}
          </div>
        </div>
      </div>
    );
};

export default DSPCustomersByBrokersReport;
