import {
  downloadXlsxReport,
  fetchDKSBusinessPlacesReport
} from '../../ducks/reports';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../ducks';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SimpleInputCheckbox from '../commons/Inputs/SimpleInputs/SimpleInputCheckbox';
import SimpleInputList from '../commons/Inputs/SimpleInputs/SimpleInputList';

const DKSBusinessPlacesReport : FC = () => {
  const { t } = useTranslation();
  const isLoading = useSelector((state : RootState) => state.combinedReports.DKSBusinessPlacesReport.isLoading);
  const dispatch = useDispatch<AppDispatch>();
  const [customerType, setCustomerType] = useState('');
  const [emailInvoice, setEmailInvoice] = useState(false);
  const [valorization, setValorization] = useState(false);
  const disableGenerating = customerType === '';
  const customerTypes = [
    '',
    'Osoba fizyczna',
    'Działalność gospodarcza',
    'Podmiot gospodarczy'
  ];

  const updateCustomerType = useCallback((e : ChangeEvent<HTMLInputElement>) => {
    setCustomerType(e.target.value);
  }, []);

  const updateInvoice = useCallback((e : ChangeEvent<HTMLInputElement>) => {
    setEmailInvoice(e.target.checked);
  }, []);

  const updateValorization = useCallback((e : ChangeEvent<HTMLInputElement>) => {
    setValorization(e.target.checked);
  }, []);

  const onGenerateReport = useCallback(() => {
    let type = '';
    switch (customerType) {
      case 'Osoba fizyczna':
        type = 'CUSTOMER';
        break;
      case 'Działalność gospodarcza':
        type = 'BUSINESS_CUSTOMER';
        break;
      case 'Podmiot gospodarczy':
        type = 'COMPANY';
        break;
    }
    dispatch(
      fetchDKSBusinessPlacesReport({
        customerType : type,
        emailInvoice,
        valorization
      })
    ).then((res : any) => {
      if (res.payload?.DKSBusinessPlacesReport) {
        const parameters = {
          content : res.payload.DKSBusinessPlacesReport,
          filename : 'DKSRaport-LokaleNaDzialalnosc.xlsx'
        };
        return dispatch(downloadXlsxReport(parameters));
      }
      alert('Błąd podczas pobierania raportu');
    });
  }, [customerType, dispatch, emailInvoice, valorization]);

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <div className="icr-header">Lokale na działalność</div>
      <div className="d-flex mt-2">
        <SimpleInputList
          options={customerTypes}
          additionalOnChange={updateCustomerType}
          defaultValue={customerType}
          label={String(t('commons:labels.customer-type'))}
        />
      </div>
      <div className="d-flex mt-2">
        <SimpleInputCheckbox
          customLabelWidth="auto"
          additionalOnChange={updateInvoice}
          defaultChecked={emailInvoice}
          label={String(t('commons:labels.email-invoice'))}
        />
        <SimpleInputCheckbox
          customLabelWidth="auto"
          additionalOnChange={updateValorization}
          defaultChecked={valorization}
          label={String(t('commons:labels.valorization'))}
        />
      </div>

      <Button
        className="mt-5"
        onClick={onGenerateReport}
        disabled={disableGenerating || isLoading}>
        {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
      </Button>
    </Container>
  );
};

export default DKSBusinessPlacesReport;
