import React, { FC, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import './InterestedCustomersReport.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../ducks';
import { IDSPCustomerSourceInfoReportProps } from '../../../services/interestedCustomerService';
import { useTranslation } from 'react-i18next';
import { IInvestment } from '../../../graphql/investments';
import SimpleInputDate from '../../commons/Inputs/SimpleInputs/SimpleInputDate';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants/dates';
import SimpleInputCheckbox from '../../commons/Inputs/SimpleInputs/SimpleInputCheckbox';
import { downloadXlsxReport, getDSPCustomerSourceInfoReport } from '../../../ducks/reports';

const InterestedCustomersReport : FC = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state : RootState) => state.combinedReports.DSPCustomerSourceInfoReport.isLoading);
  const { investmentsList } = useSelector((state : RootState) => state.globalInvestment);

  const today = moment().format(DATE_FORMAT_YYYYMMDD);

  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(today);
  const [investments, setInvestments] = useState([] as IInvestment[]);

  const disableGenerating = Boolean(investments.length < 1);

  const onInvestmentToggle = useCallback((selectedInvestment : IInvestment) => {
    const index = investments.findIndex(
      (investment : IInvestment) => investment.id === selectedInvestment.id
    );
    if (index === -1) {
      setInvestments([...investments, selectedInvestment]);
    } else {
      const newInvestments = [...investments];
      newInvestments.splice(index, 1);
      setInvestments(newInvestments);
    }
  }, [investments]);

  const updateFromDate = useCallback((day : Date) => {
    setFromDate(moment(day).format(DATE_FORMAT_YYYYMMDD));
  }, []);

  const updateToDate = useCallback((day : Date) => {
    setToDate(moment(day).format(DATE_FORMAT_YYYYMMDD));
  }, []);

  const onGenerateReport = useCallback(() => {
    const investmentIDs = investments.map((investment : IInvestment) => investment.id);
    dispatch(getDSPCustomerSourceInfoReport({
        toDate,
        fromDate,
        investmentIDs
      } as IDSPCustomerSourceInfoReportProps)
    ).then((response : any) => {
      if (response.payload?.DSPCustomerSourceInfoReport) {
          return dispatch(downloadXlsxReport({ content : response.payload.DSPCustomerSourceInfoReport, filename : 'Zainteresowani_zrodlo_informacji.xlsx' }));
      }
      alert('Błąd podczas pobierania raportu');
  });
  }, [dispatch, fromDate, investments, toDate]);

  return (
    <div className="container">
      <div className='pb-4 px-2'>
        <Button onClick={onGenerateReport} disabled={disableGenerating || isLoading}>
          {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
        </Button>
      </div>
      <div className="column">
        <div className='d-flex justify-content-center w-100'>
          <div className="icr-header">Klienci zainteresowani - źródła informacji</div>
        </div>
        <div className="d-flex row justify-content-center align-items-center py-2 my-5">
          <SimpleInputDate
            additionalOnChange={updateFromDate}
            defaultValue={today}
            label={String(t('commons:labels.from-date'))}
          />
          <SimpleInputDate
            additionalOnChange={updateToDate}
            defaultValue={today}
            label={String(t('commons:labels.to-date'))}
          />
        </div>
        <p className='pb-1'>{t('commons:actions.select-investment')}</p>
        <div className="d-flex row justify-content-start align-items-center mt-2">
          {investmentsList.map((investment, index) => (
            <div className='col-3' key={investment.name}>
              <SimpleInputCheckbox
                customLabelWidth={14}
                label={`${index + 1} - ${investment.name}`}
                additionalOnChange={() : void => onInvestmentToggle(investment)}
              />
            </div>
          ))}
        </div>

        {investments.length > 0 && (
          <div className="icr-investments-header">Inwestycje dodane do raportu</div>
        )}
        {investments.map((investment : IInvestment, index : number) => (
          <div className="icr-investment" key={index}>
            {index + 1} - {investment.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InterestedCustomersReport;
