import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTableContext } from './TableContext';

export type PaginationProps = {
    defaultAmountOfRows ?: number;
}

const Pagination : React.FC<PaginationProps> = ({ defaultAmountOfRows = 10 }) => {
const { t } = useTranslation();
const { state : { pageSize, pageIndex }, pageOptions, canNextPage, canPreviousPage, gotoPage, setPageSize,
 previousPage, pageCount, nextPage } = useTableContext();

const amountOfRows = React.useMemo(() : number[] => {
    let standardOptions = [5, 10, 20];
    if (!standardOptions?.includes(defaultAmountOfRows)) {
      standardOptions = [defaultAmountOfRows, ...standardOptions];
    }
    return standardOptions;
  }, [defaultAmountOfRows]);
  const handleChangePageSizeClick = React.useCallback((e) : void => {
    setPageSize(Number(e.target.value));
  }, [setPageSize]);

  const handleGoToFirstPageClick = React.useCallback(() : void => (
    gotoPage(0)
  ), [gotoPage]);

  const handleGoToPreviousPageClick = React.useCallback(() : void => (
    previousPage()
  ), [previousPage]);

  const handleLastPageClick = React.useCallback(() : void => (
    gotoPage(pageCount - 1)
  ), [gotoPage, pageCount]);

  const handleNextPageClick = React.useCallback(() : void => (
    nextPage()
  ), [nextPage]);

return (
 <div className='d-flex align-items-center justify-content-end pagination mx-4'>
  <div className='mx-1'>
   <button className='py-2' onClick={handleGoToFirstPageClick} disabled={!canPreviousPage}
   style={{ backgroundColor : '#ffffff', border : '0' }}>
   {'«'}
   </button>
   <button className='py-2' onClick={handleGoToPreviousPageClick} disabled={!canPreviousPage}
    style={{ backgroundColor : '#ffffff', border : '0' }}>
    {'◄'}
   </button>
  </div>
  <div className='mx-1'>
   <select
    className='py-2 px-1 mx-2'
    onChange={handleChangePageSizeClick}
    style={{ backgroundColor : '#ffffff', border : '1px solid #dedede', height : '40px' }}
    value={pageSize}
   >
   {amountOfRows.map(pageSize => (
    <option key={pageSize} value={pageSize}>
     {pageSize} {t('commons:labels.of-rows')}
    </option>))}
     </select>
     <span className='mx-2'>
     {t('commons:labels.page')}
     <strong className='px-1' style={{ fontWeight : 'initial' }}>
      <span>{pageIndex + 1}</span> {t('commons:labels.from-2')} {pageOptions.length}
     </strong>
    </span>
  </div>
  <div className='mx-1'>
    <button className='py-2' onClick={handleNextPageClick} disabled={!canNextPage}
     style={{ backgroundColor : '#ffffff', border : '0' }}>
     {'►'}
    </button>
    <button className='py-2' onClick={handleLastPageClick} disabled={!canNextPage}
     style={{ backgroundColor : '#ffffff', border : '0' }}>
     {'»'}
    </button>
  </div>
 </div>
  );
};

export default Pagination;
