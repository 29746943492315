import GraphqlService from './graphqlService';
import { gql } from 'graphql-request';
const fetchCancelledContracts = () : Promise<any> => {
    const FETCH_CANCELLED_CONTRACTS = gql`
    query {
    cancelledContracts {
        id
        place {
        placeCode
        roomCount
        administrativeCode1
        administrativeCode2
        designedUsablePropertyArea
        designedInnerPropertyArea
        asBuildUsablePropertyArea
        asBuildInnerPropertyArea
        investment {
            name
        }
        parkingPlaces {
            name
        }
        }
        placeID
        comments
        street
        backyardTerrace
        roofTerrace
        garden
        repNumber
        otherContract
        lawContract
        deluxeContract
        valorization
        cancelledContract
        cancelledContractDate
        isEmailInvoice
        isCredit
        creditType
        creditSecurity
        lendingBank
        amountOfCredit1
        amountOfCredit2
        creditDealNo
        creditDealDate
        contractDealDate
        contractualAcceptanceDate
        contractualActDate
        acceptanceDate
        actDate
        notarialOffice
        isPledge
        pledge
        isWarranty
        warranty
        numberOfOwners
        placeID
        contractNo
        parkingPlaces {
        name
        }
        contractCustomerCompany {
        businessName
        isAgentOfMainCustomer
        traderInitials
        }
        contractCustomers {
        lastName
        firstName
        isAgentOfMainCustomer
        isSpouseOfMainCustomer
        traderInitials
        isAgentOfCustomerSpouse
        }
    }
    }`;
    return GraphqlService.send(FETCH_CANCELLED_CONTRACTS, {});
};

const mutations = { fetchCancelledContracts };

export default mutations;