import React, { FunctionComponent } from 'react';

import './AppVersion.scss';

const { REACT_APP_VERSION } = process.env;

const AppVersion : FunctionComponent = () => {
  return (
    <div className="app-version">
      {REACT_APP_VERSION}
    </div>
  );
};

export default AppVersion;
