import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../ducks';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../../constants/dates';
import React, { FC, useCallback, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import SimpleInputDate from '../commons/Inputs/SimpleInputs/SimpleInputDate';

import { hasRoles } from '../../services/idp';
import { DNKRoles } from '../../ducks/globalUser';

import { downloadXlsxReport, fetchDSPInterestedCustomersDailyReport } from '../../ducks/reports';
import { IDSPDailyReport } from '../../services/reportsService';

const DSPInterestedCustomersDailyReport : FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const { roles } = useSelector((state : RootState) => state.globalUser);
  const isLoading = useSelector((state : RootState) => state.combinedReports.DSPInterestedCustomersDailyReport.isLoading)
  const { t } = useTranslation();
  const today = moment().format(DATE_FORMAT_YYYYMMDD);

  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(today);
  const disableGenerating = Boolean(false);

  const updateFromDate = useCallback((day : Date) => {
    setFromDate(moment(day).format(DATE_FORMAT_YYYYMMDD));
  }, []);

  const updateToDate = useCallback((day : Date) => {
    setToDate(moment(day).format(DATE_FORMAT_YYYYMMDD));
  }, []);

  const onGenerateReport = useCallback(() => {
    dispatch(
      fetchDSPInterestedCustomersDailyReport({
        startDate : fromDate,
        endDate : toDate
      } as IDSPDailyReport)
    ).then((res : any) => {
      if (res.payload?.DSPInterestedPartiesReport) {
        const parameters = {
          content : res.payload.DSPInterestedPartiesReport,
          filename : 'Dzienny-raport-zainteresowanych.xlsx'
        };

        return dispatch(downloadXlsxReport(parameters));
      }
      alert('Błąd podczas pobierania raportu');
    });
  }, [dispatch, fromDate, toDate]);

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <div className="icr-header">{`Zainteresowani - Dzienny Raport ${hasRoles(DNKRoles, roles) ? 'DNK' : 'DSP'}`}</div>
      <div className="d-flex mt-2">
        <SimpleInputDate
          additionalOnChange={updateFromDate}
          defaultValue={today}
          label={String(t('commons:labels.from-date'))}
        />
        <SimpleInputDate
          additionalOnChange={updateToDate}
          defaultValue={today}
          label={String(t('commons:labels.to-date'))}
        />
      </div>

      <Button className="mt-5" onClick={onGenerateReport} disabled={disableGenerating || isLoading}>
        {isLoading ? 'Pobieranie...' : t('commons:actions.generate-report')}
      </Button>
    </Container>
  );
};

export default DSPInterestedCustomersDailyReport;
