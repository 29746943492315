import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import proposedInvestmentsService, { IProposedInvestmentInput, IProposedInvestment } from '../services/proposedInvestmentsService';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface IProposedInvestmentState {
    allProposedInvestments : IProposedInvestment[];
}

const initialState : IProposedInvestmentState = {
    allProposedInvestments : []
};


export const getAllProposedInvestments = createAsyncThunk(
    'proposedPlaces/getAllProposedInvestments',
    async () => {
        return proposedInvestmentsService.fetchAllProposedInvestments();
    }
);

export const addNewProposedInvestment = createAsyncThunk(
    'proposedPlaces/addNewProposedInvestment',
    async (proposedInvestment : IProposedInvestmentInput) => {
        return proposedInvestmentsService.saveProposedInvestment(proposedInvestment);
    }
);

export const removeProposedInvestment = createAsyncThunk(
    'proposedPlaces/removeProposedInvestment',
    async (proposedPlaceID : number) => {
        return proposedInvestmentsService.deleteProposedInvestment(proposedPlaceID);
    }
);

const proposedInvestmentsSlice = createSlice({
    name : 'proposedInvestments',
    initialState,
    reducers : {},
    extraReducers : {
        [getAllProposedInvestments.fulfilled.toString()] : (state, action) : void => {
            state.allProposedInvestments = action.payload.proposedInvestments;
        },
        [getAllProposedInvestments.rejected.toString()] : (state) : void => {
            state.allProposedInvestments = [];
        },
        [addNewProposedInvestment.rejected.toString()] : (state) : void => {
            successToastNotify(String(i18n.t('toast:addProposedPlace')));
        },
        [removeProposedInvestment.rejected.toString()] : (state) : void => {
            successToastNotify(String(i18n.t('toast:removeProposedPlace')));
        }
    }
});

export default proposedInvestmentsSlice.reducer;
