import { difference } from 'lodash-es';
import React, { FunctionComponent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import {
  ColumnInstance,
  useAsyncDebounce,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useTranslation } from 'react-i18next';

import AppActions from '../../App/AppActions';
import InputDate from '../../commons/Inputs/InputDate';
import ToggleSumBar from './ToggleSumBar';

import { hasRoles } from '../../../services/idp';
import { USER_ROLES } from '../../../constants/user';
import ToggleContainer from '../Containers/ToggleContainer';

import './Table.scss';
import { INote } from '../../../graphql/notes/notes';

import { IPlaceContractsQuery } from '../../../graphql/currentPlaceContracts/currentPlaceContracts';
import AppLoader from '../Loader/Loader';
import { setSelectedRows } from '../../../ducks/globalInvestment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../ducks';


const useCombinedRefs = (...refs : any[]) : React.MutableRefObject<any> => {
  const targetRef = useRef();
  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {return;}
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else { ref.current = targetRef.current; }});
  }, [refs]);
  return targetRef;
};

interface IIndeterminateCheckboxProps {
  indeterminate ?: boolean;
}
const IndeterminateCheckbox = forwardRef<HTMLInputElement, IIndeterminateCheckboxProps>(
  ({ indeterminate, ...rest }, ref : React.Ref<HTMLInputElement>) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type='checkbox' ref={resolvedRef} {...rest} />;
  }
);

interface IGlobalFilterProps {
  globalFilter : any;
  setGlobalFilter : any;
}
const GlobalFilter : FunctionComponent <IGlobalFilterProps> = (props : IGlobalFilterProps) => {
  const { t } = useTranslation();
  const { globalFilter, setGlobalFilter } = props;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        className='search-input'
        value={value || ''}
        onChange={(e) : void => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${t('commons:labels.type-searched-keyword')}...`}
      />
    </span>
  );
};

interface ITableProps {
  additionalButtonLabel ?: string;
  callback ?: any;
  chosenRowID ?: number;
  chosenRowIndex ?: number;
  columns : any[];
  data : any[];
  initialHiddenData ?: string[];
  isAddButton ?: boolean;
  isAdditionalButton ?: boolean;
  isAnnexHistoryButton ?: boolean;
  isAnonymizationButton ?: boolean;
  isAppActionsComp ?: boolean;
  isAreasSum ?: boolean;
  isColumnsMenuComp ?: boolean;
  isCorrespondenceHistoryButton ?: boolean;
  isDeleteButton ?: boolean;
  isEventsHistoryButton ?: boolean;
  isFilter ?: boolean;
  isGenerateDataRow ?: boolean;
  isShowButton ?: boolean;
  isSmall ?: boolean;
  isSensitiveButton ?: boolean;
  isTimeRange ?: boolean;
  isUpdateButton ?: boolean;
  addRowForTitle ?: boolean;
  noPagination ?: boolean;
  noSelectRows ?: boolean;
  onAddClick ?: any;
  onAdditionalButtonClick ?: any;
  onAnonymizeDataClick ?: any;
  onDeleteClick ?: any;
  onGenerateClick ?: any;
  onHistoryClick ?: any;
  onTableClick ?: () => void;
  onShowClick ?: any;
  onUpdateClick ?: any;
  selectedPlace ?: IPlaceContractsQuery | null;
  title ?: string;
  setSelectedRowForParent ?: (id : INote) => void;
  defaultAmountOfRows ?: number;
  multipleRowSelect ?: boolean;
  autoResetSelectedRows ?: boolean;
  sensitiveColumns ?: string[];
  onFromDateClick ?: (arg : Date) => void;
  onToDateClick ?: (arg : Date) => void;
}
const Table : FunctionComponent<ITableProps> = (props : ITableProps) => {
  const {
    additionalButtonLabel,
    autoResetSelectedRows = false,
    callback,
    columns,
    chosenRowIndex,
    data,
    isAddButton,
    isAdditionalButton,
    isAnnexHistoryButton,
    isAnonymizationButton,
    isAppActionsComp,
    isAreasSum,
    isColumnsMenuComp,
    isCorrespondenceHistoryButton,
    isDeleteButton,
    isEventsHistoryButton,
    isFilter,
    isGenerateDataRow,
    isSensitiveButton,
    isShowButton,
    isSmall,
    isTimeRange,
    isUpdateButton,
    addRowForTitle,
    noSelectRows,
    onAddClick,
    onAdditionalButtonClick,
    onAnonymizeDataClick,
    onDeleteClick,
    onFromDateClick,
    onGenerateClick,
    onHistoryClick,
    onTableClick,
    onToDateClick,
    onShowClick,
    onUpdateClick,
    noPagination,
    selectedPlace,
    title,
    defaultAmountOfRows = 10,
    multipleRowSelect = false,
    sensitiveColumns = [],
    initialHiddenData
  } = props;

  const { t } = useTranslation();
  const { roles } = useSelector((state : RootState) => state.globalUser);

  const [employeeCode, setEmployeeCode] = useState('');
  const [garageHallName, setGarageHallName] = useState('');
  const [id, setID] = useState(0);
  const isRowSelected = id;
  const [rowIndex, setRowIndex] = useState(0);

  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<any>({});
  const isAnonymizationButtonDisabled = selectedRow?.original?.lastName === 'X';

  const [hiddenSensitive, setHiddenSensitive] = useState(initialHiddenData || ['']);
  const toggleSumBarAccessRoles = difference(USER_ROLES, ['DAR']);
  const defaultColumn = useMemo(
    () => ({
      minWidth : 30,
      width : 30,
      maxWidth : 1500 }),
    []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    globalFilter,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    setPageSize,
    allColumns,
    selectedFlatRows,
    toggleAllRowsSelected,
    state : { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    { autoResetSelectedRows : autoResetSelectedRows,
      columns,
      data,
      defaultColumn,
      initialState : {
        hiddenColumns : hiddenSensitive,
        selectedRowIds : {
          [chosenRowIndex as number] : true
        }
      }
    },
    useBlockLayout,
    useSticky,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      !noSelectRows && hooks.visibleColumns.push(columns => [
        { id : 'selection',
          Header : ({ getToggleAllPageRowsSelectedProps }) : JSX.Element => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div> ),
          Cell : ({ row }) : JSX.Element => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>),
          sticky : 'left' },
        ...columns]);});

  const [isSensitiveHidden, setIsSensitiveHiiden] = useState(false);
  const colsToHide = initialHiddenData ?? [''];
  const handleHideSensitiveClick = useCallback(() : void => {
    if (isSensitiveHidden) {
      setHiddenColumns(colsToHide);
      setHiddenSensitive(colsToHide);
      setIsSensitiveHiiden(false);
    } else {
      setHiddenColumns(hiddenSensitive.concat(sensitiveColumns));
      setHiddenSensitive(hiddenSensitive.concat(sensitiveColumns));
      setIsSensitiveHiiden(true);
    }
  }, [isSensitiveHidden, colsToHide, hiddenSensitive, sensitiveColumns, setHiddenColumns]);

  const handleChangePageSizeClick = useCallback((e) : void => {
    setPageSize(Number(e.target.value));
  }, [setPageSize]);

  const handleGoToFirstPageClick = useCallback(() : void => (
    gotoPage(0)
  ), [gotoPage]);

  const handleGoToPreviousPageClick = useCallback(() : void => (
    previousPage()
  ), [previousPage]);

  const handleLastPageClick = useCallback(() : void => (
    gotoPage(pageCount - 1)
  ), [gotoPage, pageCount]);

  const handleNextPageClick = useCallback(() : void => (
    nextPage()
  ), [nextPage]);

  useEffect(() => {
    setHiddenColumns(hiddenSensitive);
    setEmployeeCode(employeeCode);
    setID(id);
    setGarageHallName(garageHallName);
    setRowIndex(rowIndex);
    setSelectedRow(selectedRow);
    //setSelectedRowForParent(selectedRow?.original);
  }, [employeeCode, garageHallName, hiddenSensitive, id, rowIndex, selectedRow, selectedRowIds,
    setHiddenColumns]);

  useEffect(() => {
    setPageSize(defaultAmountOfRows);
  }, [defaultAmountOfRows, setPageSize]);

  useEffect(() => {
    multipleRowSelect && dispatch(setSelectedRows(selectedFlatRows));
  }, [dispatch, selectedFlatRows, multipleRowSelect]);

  const generateArrayAmountOfRows = () : number[] => {
    let standardOptions = [5, 10, 20];
    if (!standardOptions?.includes(defaultAmountOfRows)) {
      standardOptions = [defaultAmountOfRows, ...standardOptions];
    }
    return standardOptions;
  };

  useEffect(() => {
    if (roles?.includes('DNK')) {
      setHiddenColumns(['roomCount']);
    }
  }, [columns, roles, setHiddenColumns]);

  /* TODO : proper width */
  const MenuCheckboxes = (
    <ToggleContainer arrowColor='#000000' barColor='main-bg-color' customWidth={97.7} title={t('commons:labels.column-menu')}>
      <InputGroup>
       {allColumns.map((column : ColumnInstance, index : number) => (
          column.id !== 'selection' && column.id !== 'orderNo' && column.id !== 'investmentCode' && column.id !== 'propertyNo' && (
          <Col lg={3} key={`col${column.id}${index}`}>
            <label key={`label${column.id}${index}`}>
              <input type='checkbox' {...column.getToggleHiddenProps()} key={`input${column.id}${index}`} />{' '}
                {column.Header}
              </label>
          </Col>)))}
      </InputGroup>
    </ToggleContainer>);
  return (
    <div className="table-container">
      {addRowForTitle && (
      <Row className="h6-table-title-long">
        {title}
      </Row>)}
      <Row>
        {(isColumnsMenuComp || (title && !addRowForTitle)) && (
        <Col className="d-flex align-items-top justify-content-start my-2" lg={isColumnsMenuComp ? 8 : 1}
          md={isColumnsMenuComp ? 8 : 6} sm={12}>
            {title && !addRowForTitle && (
              <h6 className="h6-table-title">
                {!addRowForTitle && title}
              </h6>)}
            {isColumnsMenuComp && MenuCheckboxes}
        </Col>)}
        <Col>
          <Row className='d-flex align-items-center justify-content-end pr-2 my-1'>
            {isAdditionalButton
              ? <Col className='d-flex justify-content-end mx-1 px-0' lg='auto' md='auto' sm='auto'>
                  <Button className='primary' onClick={onAdditionalButtonClick}>{additionalButtonLabel}</Button>
                </Col>
              : null
            }
            {(isGenerateDataRow && isRowSelected)
              ? <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
                  <Button className="primary" onClick={onGenerateClick}>{t('commons:actions.generate-data')}</Button>
                </Col>
              : null
            }
            {(isUpdateButton && isRowSelected)
              ? <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
                  <Button className="primary" onClick={onUpdateClick}>{t('commons:actions.update')}</Button>
                </Col>
              : null
            }
            {(isDeleteButton && isRowSelected)
              ? <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
                  <Button className="primary" onClick={onDeleteClick}>{t('commons:actions.delete')}</Button>
                </Col>
              : null
            }
            {isAddButton
              ? <Col className='d-flex justify-content-end mx-1 px-0' lg='auto' md='auto' sm='auto'>
                  <Button className='primary' onClick={onAddClick}>{t('commons:actions.add')}</Button>
                </Col>
              : null
            }
            {(isShowButton && isRowSelected)
              ? <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
                  <Button className="primary" onClick={onShowClick}>{t('commons:actions.show')}</Button>
                </Col>
              : null
            }
            {isAnnexHistoryButton
              ? <Col className='d-flex justify-content-end mx-1 px-0' lg='auto' md='auto' sm='auto'>
                  <Button className='primary' onClick={onHistoryClick}>
                    <Link className="ik2-main-button-color" to="valid-contract/place/annex-history">
                      {t('annexhistory:labels.annex-history')}
                    </Link>
                  </Button>
              </Col>
              : null
            }
            {isEventsHistoryButton
              ? <Col className='d-flex justify-content-end mx-1 px-0' lg='auto' md='auto' sm='auto'>
                  <Button className='primary' onClick={onHistoryClick}>
                    <Link className="ik2-main-button-color" to="contract/contract-events-history">
                      {t('commons:actions.history')}
                    </Link>
                  </Button>
                </Col>
              : null
            }
            {isCorrespondenceHistoryButton
              ? <Col className='d-flex justify-content-end mx-1 px-0' lg='auto' md='auto' sm='auto'>
                  <Button className='primary' onClick={onHistoryClick}>
                    <Link key="show-history" className="ik2-main-button-color" to="contract/contract-correspondence-history">
                      {t('commons:actions.history')}
                    </Link>
                  </Button>
                </Col>
              : null
            }
            {isTimeRange
              ? <Col className="d-flex justify-content-end mx-1 px-0" lg="auto" md="auto" sm="auto">
                  <Row>
                    <Col>
                      <InputDate id="logs-from" text={t('commons:labels.from')} onDayChange={onFromDateClick} customWidth={200} />
                    </Col>
                    <Col>
                      <InputDate id="logs-to" text={t('commons:labels.to')} onDayChange={onToDateClick} customWidth={200} />
                    </Col>
                  </Row>
                </Col>
              : null
            }
            {(isAnonymizationButton && !isAnonymizationButtonDisabled)
              ? <Col className="d-flex justify-content-end mx-1 px-0" lg="auto" md="auto" sm="auto">
                  <Button className="primary" onClick={onAnonymizeDataClick} style={{ width : '200px' }}>
                    {t('commons:actions.anonymize-data')}
                  </Button>
                </Col>
              : null
            }
            {isSensitiveButton
              ? <Col className="d-flex justify-content-end mx-1 px-0" lg="auto" md="auto" sm="auto">
                  <Button className="primary" onClick={handleHideSensitiveClick}>
                    {isSensitiveHidden ? t('commons:actions.show-sensitive-data') : t('commons:actions.hide-sensitive-data')}
                  </Button>
                </Col>
              : null
            }
            {isFilter
              ? <Col className="d-flex justify-content-end" lg="auto" md="auto" sm="auto">
                  <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </Col>
              : null
            }
          </Row>
        </Col>
      </Row>
      <div {...getTableProps()} className='table sticky'  style={{ backgroundColor : '#f2f2f2' }}>
        <div className='header'>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map((column : any) => (
                <div {...column.getHeaderProps(column.getSortByToggleProps({ title : column.fullName }))} className='th'>
                  {column.render('Header')}
                </div>))}
            </div>))}
        </div>
        {!data.length
        ? <div className='d-flex justify-content-center w-100'>
            <AppLoader />
          </div>
        : (
        <div {...getTableBodyProps()} className='body' onClick={onTableClick}>
          {page.map((row, index : number) => {
            prepareRow(row);
            let id = 0;
            let sr = {};
            return (
              <div {...row.getRowProps()} className='tr' onClick={(e) : void => {
                row.allCells.forEach((cell : any) : void => {
                  if (cell.row.original) { sr = cell.row.original; }
                  if (cell.row.original.id) { id = cell.row.original.id; }
                });

                if (multipleRowSelect && selectedFlatRows.length > 1 && !(e.ctrlKey || e.metaKey)) {
                  toggleAllRowsSelected(false);
                }

                multipleRowSelect
                ? !(e.ctrlKey || e.metaKey) && !noSelectRows && page.map(row => row.isSelected && row.toggleRowSelected())
                : !noSelectRows && page.map(row => row.isSelected && row.toggleRowSelected());
                !noSelectRows && row.toggleRowSelected();
                // TODO : bug related to this line:
                //row.isSelected && callback && callback();
                !row.isSelected && callback && callback({ id : id, selectedRow : sr, rowIndex : index, event : e }) && dispatch(setSelectedRows(selectedFlatRows));
                setID(id);
                setSelectedRow(row);
                setRowIndex(rowIndex);
              }}>
              {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className={`td ${(row.isSelected) && 'rowSelected'}`}>
                  {cell.render('Cell')}
                </div>);})}
              </div>);})}
        </div>)}
      </div>
      <Row className={`d-flex align-items-center justify-content-${isAreasSum ? 'between' : 'end'}`}>
      {hasRoles(toggleSumBarAccessRoles, roles) && isAreasSum && (
        <Col className='d-flex justify-content-start' lg={isSmall ? 12 : 8} md={12} sm={12}>
          <ToggleSumBar data={data} />
        </Col>)}
        {!noPagination && (
          <div className='d-flex align-items-center justify-content-end pagination mx-4'>
            <div className='mx-1'>
              <button className='py-2' onClick={handleGoToFirstPageClick} disabled={!canPreviousPage}
                style={{ backgroundColor : '#ffffff', border : '0' }}>
                {'«'}
              </button>
              <button className='py-2' onClick={handleGoToPreviousPageClick} disabled={!canPreviousPage}
                style={{ backgroundColor : '#ffffff', border : '0' }}>
                {'◄'}
              </button>
            </div>
            <div className='mx-1'>
              <select
                className='py-2 px-1 mx-2'
                onChange={handleChangePageSizeClick}
                style={{ backgroundColor : '#ffffff', border : '1px solid #dedede', height : '40px' }}
                value={pageSize}
              >
              {generateArrayAmountOfRows().map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} {t('commons:labels.of-rows')}
                </option>))}
              </select>
              <span className='mx-2'>
                {t('commons:labels.page')}
                <strong className='px-1' style={{ fontWeight : 'initial' }}>
                  <span>{pageIndex + 1}</span> {t('commons:labels.from-2')} {pageOptions.length}
                </strong>
              </span>
            </div>
            <div className='mx-1'>
              <button className='py-2' onClick={handleNextPageClick} disabled={!canNextPage}
                style={{ backgroundColor : '#ffffff', border : '0' }}>
                {'►'}
              </button>
              <button className='py-2' onClick={handleLastPageClick} disabled={!canNextPage}
                style={{ backgroundColor : '#ffffff', border : '0' }}>
                {'»'}
              </button>
            </div>
          </div>
        )}
      </Row>
      {selectedFlatRows.length === 1 && isAppActionsComp && selectedPlace && (
      <Row className='d-flex justify-content-center my-2 w-100'>
        <AppActions selectedPlace={selectedPlace} />
      </Row>)}
    </div>
  );
};

export default Table;
