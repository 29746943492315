import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

type alignType = 'end' | 'between'

export type TableHeaderProps = {
    align ?: alignType;
}

const TableHeader : React.FC<TableHeaderProps> = ({ children, align = 'end' }) => {
 return (
  <Col>
   <Row className={`d-flex align-items-center justify-content-${align} pr-2 my-1`}>
    {children}
   </Row>
  </Col>
 );
};

export default TableHeader;