export const USER_ROLES = [
  'ADM',
  'DAR',
  'DKO',
  'DKS',
  'DMA',
  'DMA-ADM',
  'DNI',
  'DNK',
  'DPI',
  'DPP',
  'DSP',
  'DSP-ADM',
  'DYR',
  'SEK',
  'SKA'
];
