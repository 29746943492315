import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminITService, { FetchClientsInInvestmentsProps, IEmployee, IEmployeeInput, IEmployeeLogs, IEmployeeLogsFetch } from '../services/adminITService';
import { IContractCustomer } from '../graphql/contractCustomers';
import { successToastNotify } from '../components/commons/Toast/Toast';
import i18n from '../i18n';

interface AdminITState {
  clientsInInvestments : IContractCustomer[];
  clientsInInvestmentsTotal : number;
  employeeLogs : IEmployeeLogs[];
  employeeLogsTotal : number;
  employeeList : IEmployee[];
  tradersInitials : { username : string }[];
}

const initialState = {
  clientsInInvestments : [],
  clientsInInvestmentsTotal : 0,
  employeeLogs : [],
  employeeList : [],
  tradersInitials : [],
  employeeLogsTotal : 0
} as AdminITState;

export const fetchClientsInInvestments = createAsyncThunk(
  'adminIT/fetchClientsInInvestments',
  async (variables : FetchClientsInInvestmentsProps) => {
    return AdminITService.getClientsInInvestments(variables);
  }
);

export const generateClientDataInInvestment = createAsyncThunk(
  'adminIT/generateClientDataInInvestment',
  async () => {
    return AdminITService.fetchClientDataReport();
  }
);

export const fetchEmployeeLogs = createAsyncThunk(
  'adminIT/fetchEmployeeLogs',
  async (variables : IEmployeeLogsFetch) => {
    return AdminITService.fetchEmployeeLogs(variables);
  }
);

export const getAllUsers = createAsyncThunk('employee/getAllUsers', async () => {
  return AdminITService.getAllUsers();
});

export const addEmployee = createAsyncThunk('employee/addUser', async (user : IEmployeeInput) => {
  return AdminITService.addUser(user);
});

export const editEmployee = createAsyncThunk('employee/editUser', async ({ user, userID } : { user : IEmployeeInput; userID : string }) => {
  return AdminITService.editUser(user, userID);
});

export const deleteEmployee = createAsyncThunk('employee/deleteUser', async (userID : string) => {
  return AdminITService.deleteUser(userID);
});

export const fetchTraderInitials = createAsyncThunk('employee/fetchTraderInitials', async () => {
  return AdminITService.fetchTraderInitials();
});


const adminITSlice = createSlice({
  name : 'adminIT',
  initialState,
  reducers : {},
  extraReducers : {
    [fetchClientsInInvestments.fulfilled.toString()] : (state, action : PayloadAction<any>) : void => {
      state.clientsInInvestmentsTotal = action.payload.paginatedContractCustomers.total;
      state.clientsInInvestments = action.payload.paginatedContractCustomers.contractCustomers;
    },
    [fetchEmployeeLogs.fulfilled.toString()] : (state, action : PayloadAction<any>) : void => {
      state.employeeLogs = action.payload.paginatedChanges.changes;
      state.employeeLogsTotal = action.payload.paginatedChanges.total;
    },
    [getAllUsers.fulfilled.toString()] : (state, action) : void  => {
      state.employeeList = action.payload.users;
    },
    [addEmployee.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:addUser')));    
      state.employeeList = [...state.employeeList, action.payload.saveUser];
    },
    [editEmployee.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:editUser')));
      const updatedEmployee = action.payload.updateUser;
      const updatedEmployeeIndex = state.employeeList.findIndex(employee => employee.id === updatedEmployee.id);
      state.employeeList[updatedEmployeeIndex] = updatedEmployee;
    },
    [deleteEmployee.fulfilled.toString()] : (state, action) : void => {
      successToastNotify(String(i18n.t('toast:removeUser')));
      const deletedEmployeeIndex = action.meta.arg;
      const filteredEmployees = state.employeeList.filter(employee => employee.id !== deletedEmployeeIndex);
      state.employeeList = filteredEmployees;
    },
    [fetchTraderInitials.fulfilled.toString()] : (state, action : PayloadAction<any>) : void => {
      state.tradersInitials = action.payload.users;
    }
  }
});

export default adminITSlice.reducer;
