import { gql } from 'graphql-request';
import GraphqlService from './graphqlService';

export interface ICurrentInterestedCustomer {
  id : number;
  contracts ?: {
    id : number;
  }[];
}

export interface ICurrentContractCustomer {
  id : number;
  contractID : number;
  customerCode : string;
}

export interface IEmployeeLogs {
  date : string;
  opID : string;
  author : string;
}
export interface IEmployee {
  firstName : string;
  lastName : string;
  email : string;
  phone : string;
  id : string;
  roles : Array<string[]>;
  username : string;
  password : string;
};

export interface IEmployeeInput {
  firstName : string;
  lastName : string;
  email : string;
  phone : string;
  roles : Array<string[]>;
  username : string;
  password ?: string;
};

export interface FetchClientsInInvestmentsProps {
  perPage ?: number;
  page ?: number;
  isBlueList ?: boolean;
  isToughCustomer ?: boolean;
}

export interface IEmployeeLogsFetch {
  startDate : string;
  endDate : string;
  page : string;
  perPage : string;
}

const getClientsInInvestments = (variables : FetchClientsInInvestmentsProps) : Promise<any> => {
  const FETCH_CLIENTS_IN_INVESTMENTS = gql`
  query(
    $perPage: Float
    $page: Float
    $isBlueList: Boolean
    $isToughCustomer: Boolean
  ) {
    paginatedContractCustomers(
      perPage: $perPage
      page: $page
      isBlueList: $isBlueList
      isToughCustomer: $isToughCustomer
    ) {
      total
      contractCustomers {
        id
        lastName
        secondName
        firstName
        sex
        email
        phone1
        phone2
        businessName
        contractID
        isToughCustomer
        isBlueList
        customerCode
        pesel
        regon
        brokerName
        placeShares
        businessName
        customerInvestmentCode
        maritalStatus
        contract {
          contractDealDate
          numberOfOwners
          place {
            placeCode
          }
        }
        marketingConsents {
          isInfoDutyFulfilled
          infoDutyForm
          infoDutyFormDate
          isAgreementPhone
          agreementPhoneDate
          isAgreementPhoneCancelled
          agreementPhoneCancellationDate
          isAgreementEmail
          agreementEmailDate
          isAgreementEmailCancelled
          agreementEmailCancellationDate
          isMSWiAAgreement
        }
      }
    }
  }
  `;

  return GraphqlService.send(FETCH_CLIENTS_IN_INVESTMENTS, variables);
};

const fetchClientDataReport = () : Promise<any> => {
  const FETCH_CLIENT_DATA_REPORT = gql`
    query {
      contractCustomers {
        id
        uuid
      }
    }
  `;
  return GraphqlService.send(FETCH_CLIENT_DATA_REPORT, {});
};

const fetchEmployeeLogs = (variables : IEmployeeLogsFetch) : Promise<any> => {
  const FETCH_USER_LOGS = gql`
    query($startDate: String, $endDate: String, $perPage : Float, $page: Float) {
      paginatedChanges(endDate: $endDate, startDate: $startDate, perPage:$perPage, page : $page) {
        total
        changes {
          author
          date
          opID
          content
        }
      }
    }
  `;
  return GraphqlService.send(FETCH_USER_LOGS, variables);
};

const getAllUsers = () : Promise<any> => {
  const FETCH_ALL_USERS = gql`
  query {
      users {
        id
        email
        lastName
        firstName
        phone
        roles
        username
      }
    }
  `;
  return GraphqlService.send(FETCH_ALL_USERS, {});
};

const addUser = (user : IEmployeeInput) : Promise<any> => {
  const SAVE_USER = gql`
   mutation saveUser($user: UserInput!) {
       saveUser(user : $user) {
          lastName
          firstName
          email
          phone
          roles
          username
          id
       }
   }
  `;
  return GraphqlService.send(SAVE_USER, { user });
};

const editUser = (user : IEmployeeInput, userID : string) : Promise<any> => {
  const EDIT_USER = gql`
  mutation editUser($user: UserInput!, $userID: String!) {
      updateUser(user:$user, userID:$userID) {
        lastName
        firstName
        email
        phone
        roles
        username
        id
      }
    }
  `;
  return GraphqlService.send(EDIT_USER, { user, userID });
};

const deleteUser = (userID : string) : Promise<any> => {
  const DELETE_USER = gql`
  mutation du($userID: String!){
    deleteUser(userID: $userID)
  }
  `;
  return GraphqlService.send(DELETE_USER, { userID });
};

const fetchTraderInitials = () : Promise<any> => {
  const FETCH_TRADER_INITIALS = gql`
    query {
      users {
        username
      }
    }
  `;
  return GraphqlService.send(FETCH_TRADER_INITIALS, {});
};

const mutations = {
  getClientsInInvestments,
  fetchClientDataReport,
  fetchEmployeeLogs,
  getAllUsers,
  addUser,
  editUser,
  deleteUser,
  fetchTraderInitials
};
export default mutations;
