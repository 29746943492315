import React, { FunctionComponent } from 'react';
import { Col, InputGroup } from 'react-bootstrap';

interface IIKInputGroupProps {
  alignTop ?: boolean;
  children : JSX.Element;
  flexColumn ? : 'flex-column';
  id : string;
  name ? : string;
  padding ? : string;
  reference ? : string;
}

const IKInputGroup : FunctionComponent <IIKInputGroupProps> = (
  props : IIKInputGroupProps
) => {

  const { alignTop, children, flexColumn, id, name, padding, reference } = props;

  return (
    <InputGroup size="sm" as={Col} className={`d-flex ${flexColumn} align-items-${alignTop ? 'top' : 'center'} ${padding}`}
      id={`group-${id}`} name={name} ref={reference}>
      {children}
    </InputGroup>
  );
};

export default IKInputGroup;
